import { frontendURL } from '../../../../helper/URLHelper';

const campaignsMessages = accountId => ({
  parentNav: 'historic',
  routes: [
    'historic_home',
    'historic_home_all',
    'status_schedule_historic',
    'visibility_historic',
    'status_schedule_visibility_historic',
  ],
  menuItems: [
    // {
    //   icon: 'calendar-clock',
    //   label: 'ALL_HISTORIES',
    //   key: 'historic',
    //   toState: frontendURL(`accounts/${accountId}/historic`),
    //   toolTip: 'schedules',
    //   toStateName: 'schedules_home',
    // },
    {
      icon: 'book-contacts',
      label: 'ALL_CONTACTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/contacts`),
      toStateName: 'contacts_dashboard',
    },
  ],
});

export default campaignsMessages;
