import { frontendURL } from '../../../../helper/URLHelper';

const campaignsMessages = accountId => ({
  parentNav: 'schedules',
  routes: ['schedules', 'schedules_home', 'professional_schedules_index'],
  menuItems: [
    {
      icon: 'calendar-clock',
      label: 'SCHEDULES',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/schedules`),
      toStateName: 'schedules_home',
    },
    {
      icon: 'person',
      label: 'SCHEDULES_PROFESSIONAL',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/schedules/professional`),
      toStateName: 'professional_schedules_index',
    },
  ],
});

export default campaignsMessages;
