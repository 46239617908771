<template>
  <div class="w-full flex flex-row bg-white dark:bg-slate-900">
    <div class="flex flex-col h-full w-full">
      <header-schedule
        :search-query="searchQuery"
        :header-title="pageTitle"
        :title-button="$t('SCHEDULE.BUTTON_TEXT')"
        :show-select="true"
        :select-options="professionals"
        :selected="professional"
        this-selected-contact-id=""
        @on-open-add-popup="toggleSelectPopup"
        @on-input-search="onInputSearch"
        @on-select="getSchedule"
      />
      <div class="flex px-3 items-center">
        <div>
          <input
            id="expand_all"
            v-model="expandAll"
            type="checkbox"
            :value="true"
            class="m-0"
          />
          <label for="expand_all">
            {{ $t('SCHEDULE.FILTER.EXPANDALL') }}
          </label>
        </div>
        <div>
          <input
            id="AVAILABLE"
            v-model="filterStatus"
            type="checkbox"
            value="AVAILABLE"
            class="m-0"
          />
          <label for="AVAILABLE"> {{ $t('SCHEDULE.STATUS.AVAILABLE') }} </label>
          <input
            id="SCHEDULED"
            v-model="filterStatus"
            type="checkbox"
            value="SCHEDULED"
            class="m-0"
          />
          <label for="SCHEDULED"> {{ $t('SCHEDULE.STATUS.SCHEDULED') }} </label>
          <input
            id="CONFIRMED"
            v-model="filterStatus"
            type="checkbox"
            value="CONFIRMED"
            class="m-0"
          />
          <label for="CONFIRMED"> {{ $t('SCHEDULE.STATUS.CONFIRMED') }} </label>
        </div>
        <multiselect-contacts :is-micro="true" @update="onUpdateSelect" />
        <!-- <woot-button
          v-tooltip.top-end="$t('FILTER.CUSTOM_VIEWS.ADD.SAVE_BUTTON')"
          size="tiny"
          variant="smooth"
          color-scheme="secondary"
          icon="save"
          @click="openFilters"
        /> -->
      </div>
      <calendar
        :events="formattedEvents"
        :professional="professional"
        :title="$t('SCHEDULE.AVAILABILITY')"
        :events-original="records"
        :expand-all="expandAll"
        @dates-render="handleDatesRender"
      />
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="toggleSelectPopup">
      <generate-schedule
        :professional-selected="professional"
        @on-close="toggleSelectPopup"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderSchedule from './components/Header';
import GenerateSchedule from './components/GenerateSchedule.vue';
import Calendar from 'dashboard/components/widgets/calendar/Calendar.vue';
import isAdmin from 'dashboard/mixins/isAdmin.js';
import dateMixin from 'dashboard/mixins/dateMixin';
import scheduleMixin from 'dashboard/mixins/scheduleMixin';
import MultiselectContacts from 'dashboard/components/widgets/forms/MultiselectContacts.vue';

export default {
  components: {
    HeaderSchedule,
    GenerateSchedule,
    Calendar,
    MultiselectContacts,
  },
  mixins: [isAdmin, dateMixin, scheduleMixin],
  data() {
    return {
      // dataFilter: [],
      searchQuery: '',
      showAddPopup: false,
      titleCalendar: this.$t('SCHEDULE.AVAILABILITY'),
      professional: {},
      date_interval_start: '',
      date_interval_end: '',
      expandAll: false,
      filterStatus: ['AVAILABLE', 'SCHEDULED', 'CONFIRMED'],
      contactsFilter: {
        id: '',
        name: this.$t('CALENDAR.SCHEDULE.CONTACT.SELECT_PLACEHOLDER_FILTER'),
        phone_number: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      currentUser: 'getCurrentUser',
      records: 'schedules/getAllSchedules',
      professionals: 'professionals/getAllProfessionals',
      // contactList: 'contacts/getContacts',
    }),
    dataFilter() {
      // console.log('this.contactsFilter ', this.contactsFilter);
      const filterForContact =
        this.contactsFilter && this.contactsFilter.id !== '';
      const allStatus = this.filterStatus.length === 3;
      if (allStatus && !filterForContact) return this.records;
      return this.records.filter(item => {
        if (this.filterStatus.includes(item.status)) {
          if (filterForContact && item.contact) {
            if (this.contactsFilter.phone_number === item.contact.phone_number)
              return item;
            return false;
          }
          return true;
        }
        return false;
      });
    },
    formattedEvents() {
      return this.formattedData(this.dataFilter);
    },
    pageTitle() {
      return this.$t('SCHEDULE.HEADER');
    },
    titleAddShedule() {
      return this.$t('SCHEDULE.ADD.TITLE');
    },
  },
  watch: {
    // filterStatus() {
    //   this.getSchedule(
    //     this.professional,
    //     this.filterStatus,
    //     this.contactsFilter.phone_number
    //   );
    // },
    contactsFilter() {
      this.getSchedule(
        this.professional,
        this.filterStatus,
        this.contactsFilter?.phone_number
      );
    },
  },
  async mounted() {
    await this.$store.dispatch('professionals/get');
    this.setProfessional();
    // this.$store.dispatch('contacts/getAll');
    this.$store.dispatch('contacts/get');
  },
  methods: {
    onUpdateSelect(selectContact) {
      // console.log('filter select contacto', selectContact);
      this.contactsFilter = selectContact;
    },
    formatContactLabel(contact) {
      const phone = contact.phone_number !== null ? contact.phone_number : '';
      return `${contact.name} ${phone}`;
    },
    customContactLabel(option) {
      const phone = option.phone_number !== null ? option.phone_number : '';
      return `${option.name} ${phone}`;
    },
    setProfessional() {
      if (this.professionals.length > 0) {
        let index = this.professionals.findIndex(
          professional => professional.user_id === this.currentUser.id
        );
        if (this.currentUser.role === 'administrator' && index === -1) {
          index = 0;
        }
        this.getSchedule(this.professionals[index]);
      }
    },
    getFilterData(status, contacts) {
      if (status.length === 0) return [];
      let result = this.records.filter(item => status.includes(item.status));
      if (!contacts || !contacts.phone_number) return result;
      result = result.filter(
        item => item.contact.phone_number === contacts.phone_number
      );
      return result;
    },
    getSchedule(professional) {
      // , status, contacts
      if (professional?.id) {
        this.professional = professional;
        // if (status.length === 0) {
        //   return [];
        // }
        this.$store.dispatch('schedules/getFilter', {
          professional_id: professional.id,
          date_interval_start: this.date_interval_start,
          date_interval_end: this.date_interval_end,
          // ...(status ? { status: status } : undefined),
        });
      }
    },
    handleDatesRender(info) {
      this.date_interval_start = info.startStr;
      this.date_interval_end = info.endStr;
      this.getSchedule(this.professional);
    },
    toggleSelectPopup(professional) {
      if (professional) {
        this.professional = professional;
      }
      this.showAddPopup = !this.showAddPopup;
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
    },
    formattedData(data) {
      const dados = data.map(item => ({
        id: item.id,
        title: this.formattedTitle(item),
        start: new Date(item.schedule_date_time),
        end: item.schedule_date_time_end
          ? new Date(item.schedule_date_time_end)
          : new Date(item.schedule_date_time),
        origin: item,
        color: this.formattedColor(item.status),
        textColor: this.formattedTextColor(item.status),
      }));
      return dados;
    },
  },
};
</script>

<style lang="scss">
.mb-0-important {
  margin-bottom: 0px !important;
}
.multiselect-micro-line {
  min-height: 30px !important;
  height: 30px !important;
  .multiselect__select {
    min-height: 30px;
    height: 30px;
    padding-top: 5px;
  }
  .multiselect__tags {
    min-height: 30px;
    height: 30px;
    padding: 0px 30px 0 4px;
    border-width: 0 0 1px 0;
    border-radius: 4px;
    .multiselect__input {
      height: 30px;
    }
    .multiselect__single {
      padding: 4px;
    }
    .multiselect__placeholder {
      padding-top: 4px;
    }
  }
}
</style>
