import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import ProfessionalAPI from '../../api/professional';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { PROFESSIONAL_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getProfessional: _state => campaignType => {
    return _state.records.filter(
      record => record.campaign_type === campaignType
    );
  },
  getAllProfessionals: _state => {
    // console.log('ola getter all', _state);
    return _state.records;
  },
};

export const actions = {
  get: async function getProfessionals({ commit }) {
    commit(types.SET_PROFESSIONALS_UI_FLAG, { isFetching: true });
    try {
      const response = await ProfessionalAPI.get();
      // console.log('response ', response);
      commit(types.SET_PROFESSIONALS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_PROFESSIONALS_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createProfessional({ commit }, professionalObj) {
    // console.log('professionalObj ', professionalObj);
    commit(types.SET_PROFESSIONALS_UI_FLAG, { isCreating: true });
    try {
      const response = await ProfessionalAPI.create(professionalObj);
      // console.log('response created ', response);
      commit(types.ADD_PROFESSIONAL, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_PROFESSIONALS_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_PROFESSIONALS_UI_FLAG, { isUpdating: true });
    try {
      const response = await ProfessionalAPI.update(id, updateObj);
      AnalyticsHelper.track(PROFESSIONAL_EVENTS.UPDATE_PROFESSIONAL);
      commit(types.EDIT_PROFESSIONAL, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_PROFESSIONALS_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_PROFESSIONALS_UI_FLAG, { isDeleting: true });
    try {
      await ProfessionalAPI.delete(id);
      AnalyticsHelper.track(PROFESSIONAL_EVENTS.DELETE_CAMPAIGN);
      commit(types.DELETE_PROFESSIONAL, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_PROFESSIONALS_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_PROFESSIONALS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_PROFESSIONAL]: MutationHelpers.create,
  [types.SET_PROFESSIONALS]: MutationHelpers.set,
  [types.EDIT_PROFESSIONAL]: MutationHelpers.update,
  [types.DELETE_PROFESSIONAL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
