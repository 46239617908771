<template>
  <div class="flex gap-4">
    <div v-if="query" class="box-content border-slate-200 radius w-2/4">
      <div class="border-b-2 border-slate-200">
        {{ $t('AUDIT_LOGS.DETAIL.QUERY.LABEL') }}
      </div>
      <property-viewer :object="query" :level="0" />
    </div>
    <div v-if="response" class="box-content border-red-200 radius w-2/4">
      <div class="border-b-2 border-slate-200">
        {{ $t('AUDIT_LOGS.DETAIL.RESPONSE') }}
      </div>
      <property-viewer :object="response" :level="0" />
    </div>
  </div>
</template>

<script>
import PropertyViewer from './PropertyViewer.vue';
// import { diff } from 'deep-object-diff';

export default {
  name: 'ResponseQueryBox',
  components: {
    PropertyViewer,
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    response: {
      type: Object,
      required: true,
    },
  },
  // computed: {
  //   changes() {
  //     return this.compareObjects(this.objectOld, this.objectNew);
  //   },
  // },
  // methods: {
  //   compareObjects(oldObj, newObj) {
  //     return diff(oldObj, newObj);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.box-content {
  @apply border-2 px-4 py-2 bg-white;
}
.comparer {
  display: flex;
  justify-content: space-between;
}
.object-column {
  width: 45%;
}
</style>
