export const validExistFile = async urlFile => {
  // let result = await fetch(urlFile, { method: 'HEAD' }).then(res => true).catch(err => false)
  // return result
  try {
    const response = await fetch(urlFile, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
};
