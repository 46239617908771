<template>
  <div class="">
    <div class="font-medium">{{ title }}</div>
    <figcaption class="flex items-center">
      <img
        class="rounded-full w-9 h-9"
        src="/avatar-de-perfil.png"
        alt="profile picture"
      />
      <div
        class="space-y-0.5 font-base dark:text-white text-left rtl:text-right ms-3"
      >
        <router-link
          v-if="link"
          :to="link"
          class="text-woot-700 text-base capitalize font-semibold dark:text-woot-400"
        >
          {{ name }}
        </router-link>
        <div
          v-else
          class="text-woot-700 capitalize text-base font-semibold dark:text-woot-400"
        >
          {{ name }}
        </div>
        <div
          v-tooltip="tooltipDescription"
          class="flex text-sm text-gray-500 dark:text-gray-400"
        >
          <FluentIcon
            v-if="iconInformation"
            :icon="iconInformation"
            icon-size="14"
          />
          {{ information }}
        </div>
      </div>
    </figcaption>
  </div>
</template>

<script>
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
export default {
  components: {
    FluentIcon,
  },
  props: {
    tooltipDescription: {
      type: String,
      default: null,
    },
    iconInformation: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    information: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
nav a {
  color: inherit;
}
</style>
