<template>
  <div class="-mt-px text-sm">
    <button
      v-tooltip.top="
        disabled
          ? {
              content: $t('CONTACT_PANEL.SIDEBAR_SECTIONS.DISABLED'),
              delay: { show: 500, hide: 0 },
              hideOnClick: true,
            }
          : undefined
      "
      class="flex items-center select-none w-full bg-slate-50 dark:bg-slate-800 border border-l-0 border-r-0 border-solid m-0 border-slate-100 dark:border-slate-700/50 cursor-grab justify-between py-2 px-4 drag-handle"
      :class="{ disabled: disabled }"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <div class="flex justify-between mb-0.5">
        <emoji-or-icon class="inline-block w-5" :icon="icon" :emoji="emoji" />
        <h5
          class="text-slate-800 text-sm dark:text-slate-100 mb-0 py-0 pr-2 pl-0"
        >
          {{ title }}
        </h5>
      </div>
      <div class="flex flex-row">
        <slot name="button" />
        <div class="flex justify-end w-3 text-woot-500">
          <fluent-icon v-if="isOpen" size="24" icon="subtract" type="solid" />
          <fluent-icon v-else size="24" icon="add" type="solid" />
        </div>
      </div>
    </button>
    <div
      v-if="disabled ? false : isOpen"
      class="bg-white dark:bg-slate-900"
      :class="{
        'p-0': compact,
        'p-4': !compact,
        'nb-max-height': isContactSchedule,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import EmojiOrIcon from 'shared/components/EmojiOrIcon.vue';

export default {
  components: {
    EmojiOrIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    emoji: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    codeSettings: {
      type: String,
      default: '',
    },
  },
  computed: {
    isContactSchedule() {
      return this.codeSettings === 'contact_schedules';
    },
  },
};
</script>
<style scoped>
.disabled {
  background-color: gray;
  cursor: not-allowed;
}
.nb-max-height {
  max-height: 400px;
  overflow-y: auto;
}
</style>
