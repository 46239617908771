import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
// import ProfessionalAPI from '../../api/professional';
import ScheduleAPI from '../../api/schedules';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { SCHEDULE_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getSchedule: _state => campaignType => {
    return _state.records.filter(
      record => record.campaign_type === campaignType
    );
  },
  getAllSchedules: _state => {
    // console.log('ola getter all', _state);
    return _state.records;
  },
};

export const actions = {
  get: async function getSchedules({ commit }) {
    commit(types.SET_SCHEDULES_UI_FLAG, { isFetching: true });
    try {
      const response = await ScheduleAPI.get();
      // console.log('response ', response);
      commit(types.SET_SCHEDULES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isFetching: false });
    }
  },
  getFilter: async function getSchedulesFilter({ commit }, params) {
    commit(types.SET_SCHEDULES_UI_FLAG, { isFetching: true });
    try {
      const response = await ScheduleAPI.getFilter(params);
      // console.log('response ', response);
      commit(types.SET_SCHEDULES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createSchedule({ commit }, dataSchedule) {
    // console.log('professionalObj ', professionalObj);
    commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: true });
    try {
      // const response = await ScheduleAPI.create(dataSchedule);
      await ScheduleAPI.create(dataSchedule);
      // commit(types.ADD_SCHEDULE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: false });
    }
  },
  generate: async function createSchedule({ commit }, dataSchedule) {
    // console.log('professionalObj ', professionalObj);
    commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: true });
    try {
      const query = await ScheduleAPI.createSchedules(dataSchedule);
      if (query.data) {
        const response = await ScheduleAPI.getFilter({
          professional_id: dataSchedule.professional_id,
        });
        commit(types.SET_SCHEDULES, response.data);
      }
      // commit(types.GENERATE_SCHEDULE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_SCHEDULES_UI_FLAG, { isUpdating: true });
    try {
      const response = await ScheduleAPI.update(id, updateObj);
      // await ScheduleAPI.update(id, updateObj);
      AnalyticsHelper.track(SCHEDULE_EVENTS.UPDATE_PROFESSIONAL);
      commit(types.EDIT_SCHEDULE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_SCHEDULES_UI_FLAG, { isDeleting: true });
    try {
      await ScheduleAPI.delete(id);
      AnalyticsHelper.track(SCHEDULE_EVENTS.DELETE_CAMPAIGN);
      // commit(types.DELETE_SCHEDULE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isDeleting: false });
    }
  },
  createSocket: async function createSchedule({ commit }, dataSchedule) {
    commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: true });
    try {
      // console.log('response created ', response);
      commit(types.ADD_SCHEDULE, dataSchedule);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isCreating: false });
    }
  },
  updateSocket: async ({ commit }, data) => {
    commit(types.SET_SCHEDULES_UI_FLAG, { isUpdating: true });
    try {
      AnalyticsHelper.track(SCHEDULE_EVENTS.UPDATE_PROFESSIONAL);
      commit(types.EDIT_SCHEDULE, data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isUpdating: false });
    }
  },
  deleteSocket: async ({ commit }, data) => {
    commit(types.SET_SCHEDULES_UI_FLAG, { isDeleting: true });
    try {
      AnalyticsHelper.track(SCHEDULE_EVENTS.DELETE_CAMPAIGN);
      commit(types.DELETE_SCHEDULE, data.id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHEDULES_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_SCHEDULES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_SCHEDULE]: MutationHelpers.create,
  [types.GENERATE_SCHEDULE]: MutationHelpers.updateList,
  [types.SET_SCHEDULES]: MutationHelpers.set,
  [types.EDIT_SCHEDULE]: MutationHelpers.update,
  [types.DELETE_SCHEDULE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
