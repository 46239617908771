import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import historicTypes from '../../api/historicTypes';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { HISTORIC_TYPES_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  get(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getHistoricTypes({ commit }) {
    commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isFetching: true });
    try {
      const response = await historicTypes.get();
      // const sortedLabels = response.data.payload.sort((a, b) =>
      //   a.title.localeCompare(b.title)
      // );
      commit(types.SET_LABELS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createHistoricTypes({ commit }, cannedObj) {
    commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isCreating: true });
    try {
      const response = await historicTypes.create(cannedObj);
      AnalyticsHelper.track(HISTORIC_TYPES_EVENTS.CREATE);
      commit(types.ADD_LABEL, response.data);
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      throw new Error(errorMessage);
    } finally {
      commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateHistoricTypes({ commit }, { id, ...updateObj }) {
    commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isUpdating: true });
    try {
      const response = await historicTypes.update(id, updateObj);
      AnalyticsHelper.track(HISTORIC_TYPES_EVENTS.UPDATE);
      commit(types.EDIT_LABEL, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteHistoricTypes({ commit }, id) {
    commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isDeleting: true });
    try {
      await historicTypes.delete(id);
      AnalyticsHelper.track(HISTORIC_TYPES_EVENTS.DELETED);
      commit(types.DELETE_LABEL, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_HISTORIC_TYPES_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_HISTORIC_TYPES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_LABELS]: MutationHelpers.set,
  [types.ADD_LABEL]: MutationHelpers.create,
  [types.EDIT_LABEL]: MutationHelpers.update,
  [types.DELETE_LABEL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
