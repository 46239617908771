<template>
  <div class="mx-0 flex flex-wrap">
    <templates-picker
      v-if="!selectedWaTemplate"
      :inbox-id="inboxId"
      :message-templates="messageTemplates"
      :is-gupshup="isGupshup"
      @onSelect="pickTemplate"
    />
    <template-parser
      v-else
      :template="selectedWaTemplate"
      :vars-doc="varsDoc"
      @resetTemplate="onResetTemplate"
      @sendMessage="onSendMessage"
    />
  </div>
</template>

<script>
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
export default {
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    messageTemplates: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
    varsDoc: {
      type: Array,
      default: null,
    },
    isGupshup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedWaTemplate: null,
    };
  },
  methods: {
    pickTemplate(template) {
      this.$emit('pickTemplate', true);
      this.selectedWaTemplate = template;
    },
    onResetTemplate() {
      this.$emit('pickTemplate', false);
      this.selectedWaTemplate = null;
    },
    onSendMessage(message) {
      this.$emit('on-send', message);
    },
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<style></style>
