<template>
  <div>
    <div class="flex font-medium" :class="{ 'flex-col': flexCol }">
      <b class="text-xs">{{ description }}:</b>
      <div class="pl-2">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    flexCol: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
