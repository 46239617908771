<template>
  <div>
    <settings-section
      :title="$t('API_CONTROL.BEHAVIOR.GENERAL.TITLE')"
      :sub-title="$t('API_CONTROL.BEHAVIOR.GENERAL.SUB_TITLE')"
    >
      <label class="w-[75%] settings-item">
        <div class="enter-to-send--checkbox">
          <input id="rejectCall" v-model="rejectCall" type="checkbox" />
          <label for="rejectCall">
            {{ $t('API_CONTROL.BEHAVIOR.GENERAL.REJECT_CALL') }}
          </label>
        </div>
      </label>
      <div v-if="rejectCall" class="ml-4 pl-2">
        <woot-input
          v-model="rejectCallMessage"
          :label="$t('API_CONTROL.BEHAVIOR.GENERAL.REJECT_CALL_MESSAGE')"
          type="text"
          class="w-full"
        />
      </div>
      <div class="w-[75%]">
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input id="ignoreGroup" v-model="ignoreGroup" type="checkbox" />
            <label for="ignoreGroup">
              {{ $t('API_CONTROL.BEHAVIOR.GENERAL.IGNORE_GROUPS') }}
            </label>
          </div>
        </label>
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input id="alwaysOnline" v-model="alwaysOnline" type="checkbox" />
            <label for="alwaysOnline">
              {{ $t('API_CONTROL.BEHAVIOR.GENERAL.ALWAYS_ONLINE') }}
            </label>
          </div>
        </label>
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input
              id="markMessageRead"
              v-model="markMessageRead"
              type="checkbox"
            />
            <label for="markMessageRead">
              {{ $t('API_CONTROL.BEHAVIOR.GENERAL.MARK_MESSAGE_READ') }}
            </label>
          </div>
        </label>
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input
              id="markStatusSeen"
              v-model="markStatusSeen"
              type="checkbox"
            />
            <label for="markStatusSeen">
              {{ $t('API_CONTROL.BEHAVIOR.GENERAL.MARK_STATUS_SEEN') }}
            </label>
          </div>
        </label>
        <div>
          <woot-button
            size="medium"
            :is-loading="uiFlags.isIntegrationFetch"
            @click="sendData"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.UPDATE') }}
          </woot-button>
        </div>
      </div>
    </settings-section>
    <settings-section
      :title="$t('API_CONTROL.BEHAVIOR.CHAT.TITLE')"
      :sub-title="$t('API_CONTROL.BEHAVIOR.CHAT.SUB_TITLE')"
    >
      <div class="w-full">
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input id="signMsg" v-model="signMsg" type="checkbox" />
            <label for="signMsg">
              {{ $t('API_CONTROL.BEHAVIOR.CHAT.SIGN_MSG') }}
            </label>
          </div>
        </label>
        <div v-if="signMsg" class="ml-4 pl-2">
          <woot-input
            v-model="signDelimiter"
            :label="$t('API_CONTROL.BEHAVIOR.CHAT.SIGN_DELIMITER')"
            type="text"
            class="w-full"
          />
        </div>
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input
              id="reopenConversation"
              v-model="reopenConversation"
              type="checkbox"
            />
            <label for="reopenConversation">
              {{ $t('API_CONTROL.BEHAVIOR.CHAT.REOPEN_CONVERSATION') }}
            </label>
          </div>
        </label>
        <label class="settings-item">
          <div class="enter-to-send--checkbox">
            <input
              id="conversationPending"
              v-model="conversationPending"
              type="checkbox"
            />
            <label for="conversationPending">
              {{ $t('API_CONTROL.BEHAVIOR.CHAT.CONVERSATION_PENDING') }}
            </label>
          </div>
        </label>
        <label for="toggle-business-hours" class="toggle-input-wrap">
          <input
            v-model="enabledApi"
            type="checkbox"
            name="toggle-business-hours"
            @change="onChangeEnabled"
          />
          {{ $t('API_CONTROL.BEHAVIOR.ENABLED.LABEL') }}
        </label>
        <div>
          <woot-button
            size="medium"
            :is-loading="uiFlags.isIntegrationFetch"
            @click="sendDataWoot"
          >
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.UPDATE') }}
          </woot-button>
        </div>
      </div>
    </settings-section>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('API_CONTROL.BEHAVIOR.ENABLED.CONFIRM.TITLE')"
      :description="$t('API_CONTROL.BEHAVIOR.ENABLED.CONFIRM.MESSAGE')"
      :confirm-label="
        $t('API_CONTROL.BEHAVIOR.ENABLED.CONFIRM.CONFIRMATION_LABEL')
      "
      :cancel-label="$t('API_CONTROL.BEHAVIOR.ENABLED.CONFIRM.CANCEL_LABEL')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import inboxApiMixin from 'shared/mixins/InboxApiMixin';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    SettingsSection,
  },
  mixins: [inboxApiMixin, alertMixin],
  props: {
    apiInstance: {
      type: Object,
      default: () => ({}),
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    apiSettings: {
      type: Object,
      default: () => ({}),
    },
    apiWoot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rejectCall: this.apiSettings ? this.apiSettings.reject_call : true,
      ignoreGroup: this.apiSettings ? this.apiSettings.groups_ignore : true,
      alwaysOnline: this.apiSettings ? this.apiSettings.always_online : false,
      markMessageRead: this.apiSettings
        ? this.apiSettings.read_messages
        : false,
      markStatusSeen: this.apiSettings ? this.apiSettings.read_status : false,
      rejectCallMessage: this.apiSettings ? this.apiSettings.msg_call : '',

      signMsg: this.apiWoot ? this.apiWoot.sign_msg : true,
      signDelimiter: this.apiWoot ? this.apiWoot.sign_delimiter : '',
      reopenConversation: this.apiWoot
        ? this.apiWoot.reopen_conversation
        : true,
      conversationPending: this.apiWoot
        ? this.apiWoot.conversation_pending
        : false,
      enabledApi: this.apiWoot ? this.apiWoot.enabled : true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'apiEvolution/uiFlags',
    }),
  },
  watch: {
    apiSettings() {
      this.setApiSettings();
    },
    apiWoot() {
      this.setApiWoot();
    },
  },
  beforeMount() {
    this.setApiSettings();
    this.setApiWoot();
  },
  // mounted() {
  //   this.$store.dispatch('apiEvolution/getWoot', {
  //     instance: this.apiInstance,
  //   });
  // },
  methods: {
    setApiSettings() {
      if (this.apiSettings) {
        this.rejectCall = this.apiSettings.reject_call;
        this.ignoreGroup = this.apiSettings.groups_ignore;
        this.alwaysOnline = this.apiSettings.always_online;
        this.markMessageRead = this.apiSettings.read_messages;
        this.markStatusSeen = this.apiSettings.read_status;
        this.rejectCallMessage = this.apiSettings.msg_call;
      }
    },
    setApiWoot() {
      if (this.apiWoot) {
        this.signMsg = this.apiWoot.sign_msg;
        this.signDelimiter = this.apiWoot.sign_delimiter;
        this.reopenConversation = this.apiWoot.reopen_conversation;
        this.conversationPending = this.apiWoot.conversation_pending;
        this.enabledApi = this.apiWoot.enabled;
      }
    },
    async onChangeEnabled() {
      if (!this.enabledApi) {
        const ok = await this.$refs.confirmDialog.showConfirmation();
        if (!ok) this.enabledApi = true;
      }
    },
    async sendData() {
      const settings = {
        ...this.apiSettings,
        reject_call: this.rejectCall,
        msg_call: this.rejectCallMessage,
        groups_ignore: this.ignoreGroup,
        always_online: this.alwaysOnline,
        read_messages: this.markMessageRead,
        read_status: this.markStatusSeen,
      };
      try {
        await this.$store.dispatch('apiEvolution/setSettings', {
          instance: this.apiInstance,
          settings,
        });
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
    validConnectWoot() {
      let tempApiwoot = this.apiWoot;
      if (!this.apiWoot.account_id && this.apiWoot.account_id === '')
        tempApiwoot.account_id = String(this.inbox.account_id);
      if (!this.apiWoot.token && this.apiWoot.token === '')
        tempApiwoot.token = this.apiInstance.apikey;
      if (!this.apiWoot.url && this.apiWoot.url === '')
        tempApiwoot.url = this.apiInstance.serverUrl;
      return tempApiwoot;
    },
    async sendDataWoot() {
      const validApiWoot = this.validConnectWoot();
      const settings = {
        ...validApiWoot,
        enabled: this.enabledApi,
        sign_msg: this.signMsg,
        sign_delimiter: this.signDelimiter,
        reopen_conversation: this.reopenConversation,
        conversation_pending: this.conversationPending,
      };
      try {
        await this.$store.dispatch('apiEvolution/setWoot', {
          instance: this.apiInstance,
          settings,
        });
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.max-assignment-container {
  padding-top: var(--space-slab);
  padding-bottom: var(--space-slab);
}
</style>
