<template>
  <div :class="classContent">
    <div class="flex items-center mb-1">
      <h4 class="text-block-title flex items-center m-0 w-full error">
        <div v-if="isAttributeTypeCheckbox" class="checkbox-wrap">
          <input
            v-model="editedValue"
            class="checkbox mb-0 mr-1"
            type="checkbox"
            :title="disabled && $t('FORMS.MULTISELECT.NO_MANAGE')"
            :disabled="disabled"
            @change="onUpdate"
          />
        </div>
        <div class="flex items-center justify-between w-full">
          <span
            class="attribute-name"
            :class="{ error: $v.editedValue.$error }"
          >
            {{ label }}
          </span>
        </div>
      </h4>
    </div>
    <!-- <div v-if="isAttributeTypeCheckbox">
      <span
        v-if="$v.editedValue.$error"
        class="text-red-400 dark:text-red-500 text-sm block font-normal -mt-px w-full"
      >
        {{ $t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED') }}
      </span>
    </div> -->
    <div v-if="notAttributeTypeCheckboxAndList">
      <div>
        <div class="input-group small">
          <input
            ref="inputfield"
            v-model="editedValue"
            :type="inputType"
            class="input-group-field"
            :placeholder="placeholder"
            autofocus="true"
            :disabled="disabled"
            :class="{ error: $v.editedValue.$error }"
            :title="disabled && $t('FORMS.MULTISELECT.NO_MANAGE')"
            @blur="e => setInput(e.target.value)"
            @keyup.enter="onUpdate"
          />
          <!-- <div class="input-group-button">
            <woot-button size="small" icon="checkmark" @click="onUpdate" />
          </div> -->
        </div>
        <span v-if="shouldShowErrorMessage" class="message-error">
          {{ errorMessage }}
        </span>
      </div>
    </div>
    <div v-if="isAttributeTypeList">
      <div class="w-full">
        <multiselect
          v-model="selectedItem"
          :options="listOptions"
          track-by="id"
          label="name"
          :multiple="false"
          :hide-selected="true"
          selected-label
          :title="disabled && $t('FORMS.MULTISELECT.NO_MANAGE')"
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          :placeholder="
            disabled
              ? $t('FORMS.MULTISELECT.NO_MANAGE')
              : $t('FORMS.MULTISELECT.SELECT')
          "
          :disabled="disabled"
        >
          <template slot="noResult">
            {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
          </template>
          <template slot="noOptions">
            {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
          </template>
        </multiselect>
        <span v-if="$v.editedValue.$error" class="message-error">
          {{ $t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED') }}
        </span>
        <!-- @select="e => setSelectOption(e)"
            @blur="e => bluerSelectOption(e)" -->
      </div>
    </div>
    <!-- <div v-if="isAttributeTypeList">
      <multiselect-dropdown
        :options="listOptions"
        :selected-item="selectedItem"
        :has-thumbnail="false"
        :multiselector-placeholder="
          $t('CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.PLACEHOLDER')
        "
        :no-search-result="
          $t('CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.NO_RESULT')
        "
        :input-placeholder="
          $t(
            'CUSTOM_ATTRIBUTES.FORM.ATTRIBUTE_TYPE.LIST.SEARCH_INPUT_PLACEHOLDER'
          )
        "
        @click="onUpdateListValue"
      />
    </div> -->
    <div v-if="isAttributeTypeMultiselect">
      <div class="w-full">
        <multiselect
          v-model="selectedOptions"
          :options="listOptions"
          track-by="id"
          label="name"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :hide-selected="true"
          selected-label
          :title="disabled ? $t('FORMS.MULTISELECT.NO_MANAGE') : null"
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
          :placeholder="
            disabled
              ? $t('FORMS.MULTISELECT.NO_MANAGE')
              : $t('FORMS.MULTISELECT.SELECT')
          "
          :disabled="disabled"
        >
          <template slot="noResult">
            {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
          </template>
          <template slot="noOptions">
            {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
          </template>
        </multiselect>
        <span v-if="$v.editedValue.$error" class="message-error">
          {{ $t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED') }}
        </span>
        <!-- <span v-if="$v.selectedAgents.$error" class="message">
            {{ $t('INBOX_MGMT.ADD.AGENTS.VALIDATION_ERROR') }}
        </span> -->
        <!-- @select="e => setSelectOption(e)"
            @blur="e => bluerSelectOption(e)" -->
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
// import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import { required, url } from 'vuelidate/lib/validators';
import { isValidURL } from '../helper/URLHelper';
import { BUS_EVENTS } from 'shared/constants/busEvents';
const DATE_FORMAT = 'yyyy-MM-dd';
export default {
  components: {
    // MultiselectDropdown,
  },
  props: {
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: false },
    canManage: { type: Boolean, default: true },
    values: { type: Array, default: () => [] },
    value: { type: [String, Number, Boolean, Array], default: '' },
    attributeKey: { type: String, required: true },
    contactId: { type: Number, default: null },
    attributeType: { type: String, default: 'text' },
    isEdit: { type: Boolean, default: false },
    classContent: { type: String, default: 'py-2 px-3' },
    activeValidForm: { type: Boolean, default: false },
    // editedValue: { type: Object, default: {} },
    // showActions: { type: Boolean, default: false },
  },
  data() {
    return {
      editedValue: null,
      selectedOptions: [],
      selectedItem: null,
    };
  },
  computed: {
    disabled() {
      if (this.isEdit && !this.canManage) return true;
      return false;
    },
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
    },
    formattedValue() {
      return this.isAttributeTypeDate
        ? format(this.value ? new Date(this.value) : new Date(), DATE_FORMAT)
        : this.value;
    },
    // formattedValueInit() {
    //   if (this.isAttributeTypeDate) {
    //     if (this.isEdit) {
    //       return format(
    //         this.value ? new Date(this.value) : new Date(),
    //         DATE_FORMAT
    //       );
    //     }
    //     return '';
    //   }
    //   return this.value;
    // },
    listOptions() {
      return this.values.map((value, index) => ({
        id: index + 1,
        name: value,
      }));
    },
    isAttributeTypeCheckbox() {
      return this.attributeType === 'checkbox';
    },
    isAttributeTypeList() {
      return this.attributeType === 'list';
    },
    isAttributeTypeMultiselect() {
      return this.attributeType === 'options';
    },
    isAttributeTypeLink() {
      return this.attributeType === 'link';
    },
    isAttributeTypeDate() {
      return this.attributeType === 'date';
    },
    urlValue() {
      return isValidURL(this.value) ? this.value : '---';
    },
    hrefURL() {
      return isValidURL(this.value) ? this.value : '';
    },
    notAttributeTypeCheckboxAndList() {
      return (
        !this.isAttributeTypeCheckbox &&
        !this.isAttributeTypeList &&
        !this.isAttributeTypeMultiselect
      );
    },
    inputType() {
      return this.isAttributeTypeLink ? 'url' : this.attributeType;
    },
    shouldShowErrorMessage() {
      return this.$v.editedValue.$error;
    },
    errorMessage() {
      if (this.$v.editedValue.url) {
        return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.INVALID_URL');
      }
      return this.$t('CUSTOM_ATTRIBUTES.VALIDATIONS.REQUIRED');
    },
  },
  watch: {
    value() {
      this.editedValue = this.formattedValue;
    },
    selectedOptions() {
      if (!this.isAttributeTypeMultiselect && this.selectedOptions.length < 0)
        return;
      this.editedValue = this.selectedOptions.map(item => item.name);
      this.onUpdate();
    },
    selectedItem() {
      this.onUpdateListValue(this.selectedItem);
    },
    activeValidForm() {
      // if (this.activeValidForm) this.$v.$touch();
      this.$v.$touch();
    },
  },

  validations() {
    if (this.isAttributeTypeLink) {
      return {
        editedValue: { required, url },
      };
    }
    const valid = {
      editedValue: { ...(this.required ? { required } : {}) },
    };
    return valid;
  },
  mounted() {
    this.editedValue = this.formattedValue; // formattedValueInit;
    bus.$on(BUS_EVENTS.FOCUS_CUSTOM_ATTRIBUTE, this.onFocusAttribute);
    this.setValuesMultiselect();
    this.setSelectedItem();
  },
  methods: {
    setSelectedItem() {
      // console.log('this.editedValue ', this.editedValue);
      if (this.isAttributeTypeList && this.value !== '') {
        const id = this.values.indexOf(this.editedValue) + 1;
        this.selectedItem = { id, name: this.editedValue };
      }
    },
    setValuesMultiselect() {
      if (this.isAttributeTypeMultiselect) {
        let result = this.value.map(item => {
          const id = this.values.findIndex(value => value === item);
          if (id > -1) return { id: id + 1, name: item };
          return null;
        });
        this.selectedOptions = result;
      }
    },
    setInput(value) {
      this.editedValue = value;
      this.$v.editedValue.$touch();
      this.onUpdate();
    },
    onFocusAttribute(focusAttributeKey) {
      if (this.attributeKey === focusAttributeKey) {
        this.onEdit();
      }
    },
    focusInput() {
      if (this.$refs.inputfield) {
        this.$refs.inputfield.focus();
      }
    },
    onEdit() {
      this.$nextTick(() => {
        this.focusInput();
      });
    },
    onUpdateListValue(value) {
      if (value) {
        this.editedValue = value.name;
        this.onUpdate();
      }
    },
    onUpdate() {
      const updatedValue =
        this.attributeType === 'date'
          ? parseISO(this.editedValue)
          : this.editedValue;
      // this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('update', this.attributeKey, updatedValue);
    },
  },
};
</script>
<style lang="scss">
.message-error {
  @apply text-red-400 dark:text-red-500 text-sm block font-normal -mt-2 w-full;
}
</style>
