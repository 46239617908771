var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 overflow-auto p-4 flex justify-between flex-col"},[_c('div',[_c('div',[(!_vm.uiFlags.fetchingList && !_vm.records.length)?_c('p',{staticClass:"flex h-full items-center flex-col justify-center"},[_vm._v("\n        "+_vm._s(_vm.$t('AUDIT_LOGS.LIST.404'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.uiFlags.fetchingList)?_c('woot-loading-state',{attrs:{"message":_vm.$t('AUDIT_LOGS.LOADING')}}):_vm._e(),_vm._v(" "),(!_vm.uiFlags.fetchingList && _vm.records.length)?_c('table',{staticClass:"woot-table w-full"},[_vm._m(0),_vm._v(" "),_c('thead',_vm._l((_vm.$t('AUDIT_LOGS.LIST.TABLE_HEADER')),function(thHeader){return _c('th',{key:thHeader},[_vm._v("\n            "+_vm._s(thHeader)+"\n          ")])}),0),_vm._v(" "),_c('tbody',_vm._l((_vm.records),function(auditLogItem){return _c('tr',{key:auditLogItem.id},[_c('td',{staticClass:"whitespace-nowrap break-all"},[_c('div',{staticClass:"flex"},[(auditLogItem.action.startsWith('error'))?_c('div',{staticClass:"mr-3",attrs:{"title":_vm.$t('AUDIT_LOGS.API.ERROR_QUERY')}},[_c('fluent-icon',{staticClass:"text-red-500 mt-3",attrs:{"icon":"error-circle","size":"14"}})],1):_vm._e(),_vm._v(" "),_c('div',[_c('h6',{staticClass:"text-description sub-block-title overflow-hidden whitespace-nowrap text-ellipsis"},[_vm._v("\n                    "+_vm._s(_vm.generateLogText(auditLogItem))+"\n                  ")]),_vm._v(" "),_c('router-link',{staticClass:"button clear small link view-details--button",attrs:{"to":{
                      name: 'auditlogs_detail',
                      params: { id: auditLogItem.id },
                    }}},[_vm._v("\n                    "+_vm._s(_vm.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS'))+"\n                  ")])],1)])]),_vm._v(" "),_c('td',{staticClass:"whitespace-nowrap break-all"},[_vm._v("\n              "+_vm._s(_vm.messageTimestamp(
                  auditLogItem.created_at,
                  'MMM dd, yyyy hh:mm a'
                ))+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"w-[8.75rem]"},[_vm._v("\n              "+_vm._s(auditLogItem.remote_address)+"\n            ")])])}),0)]):_vm._e()],1)]),_vm._v(" "),_c('table-footer',{staticClass:"dark:bg-slate-900",attrs:{"current-page":Number(_vm.meta.currentPage),"total-count":_vm.meta.totalEntries,"page-size":_vm.meta.perPage},on:{"page-change":_vm.onPageChange}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticClass:"w-[60%]"}),_vm._v(" "),_c('col'),_vm._v(" "),_c('col')])
}]

export { render, staticRenderFns }