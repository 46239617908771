<template>
  <div class="h-auto overflow-auto flex flex-col w-full p-4 mt-2">
    <div class="flex justify-between mt-5">
      <div class="font-medium">{{ title }}</div>
      <div>
        <woot-button
          v-tooltip="titleBtnEdit"
          title="titleBtnEdit"
          icon="calendar-clock"
          variant="smooth"
          size="small"
          :disabled="isStatusConfirm"
          color-scheme="success"
          @click="toggleEditPopup"
        >
          {{ $t('CALENDAR.INFORMATION.BUTTON_SCHEDULE_STATUS.AVAILABLE') }}
        </woot-button>
        <woot-button
          v-if="!isStatusAvailable"
          v-tooltip="$t('CALENDAR.INFORMATION.BUTTON_HISTORIC')"
          title="$t('CALENDAR.INFORMATION.BUTTON_HISTORIC')"
          icon="calendar-clock"
          variant="smooth"
          size="small"
          color-scheme="info"
          @click="goHistoric"
        />
        <woot-button
          v-tooltip="$t('CALENDAR.INFORMATION.BUTTON_DELETE')"
          title="$t('CALENDAR.INFORMATION.BUTTON_DELETE')"
          icon="delete"
          variant="smooth"
          size="small"
          color-scheme="alert"
          @click="toggleDeleteModal"
        />
      </div>
    </div>
    <div class="flex gap-4">
      <info-row
        :value="parseData(dataEvent.schedule_date_time)"
        icon="calendar-clock"
        emoji="📅"
        emphasis
      />
      <info-row
        :value="
          getIntervalTime(
            dataEvent.schedule_date_time,
            dataEvent.schedule_date_time_end
          )
        "
        icon="calendar-clock"
        emoji="📅"
        emphasis
      />
      <info-row
        :value="
          $t(
            'SCHEDULE.MARKING_TYPE.' +
              (dataEvent.marking_selected || dataEvent.marking_type)
          )
        "
        icon="calendar-clock"
        emoji="📅"
        emphasis
      />
    </div>
    <div class="flex">
      <div v-if="dataEvent.professional" class="w-[50%]">
        <card-profile
          :title="$t('CALENDAR.INFORMATION.PROFESSIONAL.TITLE')"
          :name="dataEvent.professional.name"
          :information="dataEvent.professional.email"
        />
      </div>
      <div v-if="dataEvent.contact" class="w-px bg-slate-100 mx-2">&nbsp;</div>
      <div v-if="dataEvent.contact" class="w-[50%]">
        <card-profile
          :title="$t('CALENDAR.INFORMATION.CONTACT.TITLE')"
          :name="dataEvent.contact.name"
          :information="
            hideNumPhone
              ? $t('CONTACT_PANEL.NOT_SEE_PHONE_NUMBER')
              : dataEvent.contact.phone_number
          "
          :link="`/app/accounts/${$route.params.accountId}/contacts/${dataEvent.contact.id}`"
          :tooltip-description="
            hideNumPhone ? $t('CONTACT_PANEL.NOT_SEE_PHONE_NUMBER') : ''
          "
          :icon-information="hideNumPhone ? 'info' : ''"
        />
      </div>
    </div>
    <div class="form-observation">
      {{ $t('CALENDAR.INFORMATION.OBSERVATION.TITLE') }}
    </div>
    <div
      class="flex flex-col mb-2 p-4 border border-solid border-slate-75 dark:border-slate-700 overflow-hidden rounded-md flex-grow shadow-sm bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100"
      :style="{
        'padding-top': '0px',
        'pointer-events': dataEvent.status === 'CONFIRMED' ? 'none' : 'all',
      }"
    >
      <woot-message-editor
        v-model="observation"
        class="input--note"
        :placeholder="$t('CALENDAR.SCHEDULE.OBSERVATION.PLACEHOLDER')"
        :enable-suggestions="false"
      />
    </div>
    <div
      v-if="!isStatusAvailable"
      class="div-form justify-end py-2 px-0 w-full"
    >
      <woot-button variant="clear" @click="toggleCancel">
        {{ $t('CALENDAR.INFORMATION.BUTTON_CANCEL') }}
      </woot-button>
      <woot-button :disabled="isStatusConfirm" @click="onConfirm">
        {{ $t('CALENDAR.INFORMATION.BUTTON_CONFIRM') }}
      </woot-button>
    </div>
    <woot-delete-modal
      v-if="showCancelModal"
      :show.sync="showCancelModal"
      :on-close="toggleCancel"
      :on-confirm="confirmCancel"
      :title="$t('CALENDAR.INFORMATION.CANCEL_DIALOG.TITLE')"
      :message="$t('CALENDAR.INFORMATION.CANCEL_DIALOG.MESSAGE')"
      :message-value="titleValueCancelModal"
      :confirm-text="$t('CALENDAR.INFORMATION.CANCEL_DIALOG.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
    <woot-modal :show.sync="showEditModal" :on-close="toggleEditPopup">
      <edit-schedule-event
        :title="titleEditScheduleModal"
        :event="dataEvent"
        @on-close="toggleEditPopup"
        @on-closes="onCloseEdit"
      />
    </woot-modal>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="
        $t('SCHEDULE.CONFIRM_DELETE') +
        ' ' +
        parseData(dataEvent.schedule_date_time) +
        ' ' +
        getIntervalTime(
          dataEvent.schedule_date_time,
          dataEvent.schedule_date_time_end
        )
      "
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditScheduleEvent from './EditScheduleEvent.vue';
import dateMixin from 'dashboard/mixins/dateMixin';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import CardProfile from '../card/CardProfile.vue';
import InfoRow from '../../ui/text/InfoRow.vue';

export default {
  components: {
    InfoRow,
    EditScheduleEvent,
    CardProfile,
    WootMessageEditor,
  },
  mixins: [alertMixin, dateMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    dataEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showCancelModal: false,
      btnIcon: null,
      showEditModal: false,
      showDeleteModal: false,
      showAddPopup: false,
      observation: this.dataEvent.observation ? this.dataEvent.observation : '',
    };
  },
  computed: {
    ...mapGetters({
      // currentRole: 'getCurrentRole',
      accountId: 'getCurrentAccountId',
      hideNumPhone: 'getHideNumContactPhone',
      // isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    // hideNumPhone() {
    //   const isAdministrator = this.currentRole === 'administrator';
    //   return (
    //     !isAdministrator &&
    //     this.isFeatureEnabledonAccount(this.accountId, 'hide_phone_number')
    //   );
    // },
    titleValueCancelModal() {
      return `${this.dataEvent.contact.name} - ${this.parseData(
        this.dataEvent.schedule_date_time
      )} ${this.getIntervalTime(
        this.dataEvent.schedule_date_time,
        this.dataEvent.schedule_date_time_end
      )}`;
    },
    titleEditScheduleModal() {
      if (this.isStatusSchedule)
        return this.$t('CALENDAR.INFORMATION.TITLE_SCHEDULE_EDIT');
      return this.$t('CALENDAR.SCHEDULE.TITLE');
    },
    titleBtnEdit() {
      return this.$t(
        'CALENDAR.INFORMATION.BUTTON_SCHEDULE_STATUS.' + this.dataEvent.status
      );
    },
    iconStatus() {
      if (this.dataEvent.status === 'AVAILABLE') return '🔵';
      return '⚫';
    },
    isStatusAvailable() {
      return !!(this.dataEvent.status === 'AVAILABLE');
    },
    isStatusSchedule() {
      return !!(this.dataEvent.status === 'SCHEDULED');
    },
    isStatusConfirm() {
      return !!(this.dataEvent.status === 'CONFIRMED');
    },
  },
  mounted() {
    const focusedElement = document.activeElement;
    if (focusedElement) {
      focusedElement.blur();
    }
  },
  methods: {
    goHistoric() {
      this.$router.push({
        name: 'historic_home',
        params: { contactId: this.dataEvent.contact.id },
      });
    },
    async onConfirm() {
      let dataSend = {
        ...this.dataEvent,
        status: 'CONFIRMED',
        observation: this.observation,
      };
      try {
        await this.$store.dispatch('schedules/update', dataSend);
        this.showAlert(this.$t('SCHEDULE.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    async onCancel() {
      let dataSend = {
        ...this.dataEvent,
        status: 'AVAILABLE',
        contact_id: null,
        observation: null,
        marking_selected: null,
        changing: true,
      };
      try {
        await this.$store.dispatch('schedules/update', dataSend);
        this.showAlert(this.$t('SCHEDULE.ADD.API.CANCEL_SCHEDULE_MESSAGE'));
        this.toggleCancel();
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
        this.toggleCancel();
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    confirmCancel() {
      this.onCancel();
    },
    confirmDeletion() {
      this.deleteEvent(this.dataEvent);
      this.closeDelete();
      this.$emit('on-close');
    },
    async deleteEvent({ id }) {
      try {
        await this.$store.dispatch('schedules/delete', id);
        this.showAlert(this.$t('CALENDAR.API.DELETE.SUCCESS_MESSAGE'));
      } catch (error) {
        if (error.message === 'Error: Request failed with status code 401') {
          this.showAlert(this.$t('CALENDAR.API.DELETE.UNAUTHORIZED_MESSAGE'));
        } else {
          this.showAlert(
            error.message
              ? error.message
              : this.$t('CALENDAR.API.DELETE.ERROR_MESSAGE')
          );
        }
      }
    },
    toggleCancel() {
      this.showCancelModal = !this.showCancelModal;
    },
    closeDelete() {
      this.showDeleteModal = false;
    },
    toggleEditPopup() {
      this.showEditModal = !this.showEditModal;
    },
    onCloseEdit() {
      this.toggleEditPopup();
      this.$emit('on-close');
    },
    parseData(date) {
      if (!date) return '';
      return this.getDateDay(date, "EEEE,' 'dd'/'MM'/'yyyy");
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
  },
};
</script>
<style>
.textarea-like {
  height: 90px;
  padding: 6px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow-y: auto;
  resize: vertical;
}
</style>
