/* global axios */
import { DataManager } from '../helper/CacheHelper/DataManager';
import { LocalStorageManager } from '../helper/CacheHelper/LocalStorageManager';
import ApiClient from './ApiClient';

const useLocalStorage = window.globalConfig.USE_LOCALSTORAGE || false;
class CacheEnabledApiClient extends ApiClient {
  constructor(resource, options = {}) {
    super(resource, options);
    this.dataManager = new DataManager(this.accountIdFromRoute);
    this.storageManager = new LocalStorageManager(
      this.accountIdFromRoute,
      this.cacheModelName
    );
    this.useLocalStorage = useLocalStorage;
    this.cacheAccount = null;
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    throw new Error('cacheModelName is not defined');
  }

  get accountModelData() {
    return `${this.accountIdFromRoute}_${this.cacheModelName}`;
  }

  get(cache = false) {
    if (cache) {
      if (!this.useLocalStorage) return this.getFromCache();
      return this.getFromLocal();
    }

    return this.getFromNetwork();
  }

  getFromNetwork() {
    return axios.get(this.url);
  }

  // eslint-disable-next-line class-methods-use-this
  extractDataFromResponse(response) {
    return response.data.payload;
  }

  // eslint-disable-next-line class-methods-use-this
  marshallData(dataToParse) {
    return { data: { payload: dataToParse } };
  }

  async setDataLocal(dataNetwork = undefined) {
    const data = dataNetwork || (await axios.get(this.url));
    await this.storageManager.setCacheKeys(this.cacheAccount);
    await this.storageManager.set(data);
    return data;
  }

  async getFromLocal() {
    const { data: dataCache } = await axios.get(
      `/api/v1/accounts/${this.accountIdFromRoute}/cache_keys`
    );
    this.cacheAccount = dataCache;
    const cacheKeyFromApi = dataCache.cache_keys[this.cacheModelName];
    const isCacheValid = await this.validateCacheKey(cacheKeyFromApi);

    if (isCacheValid) {
      let nameData = this.accountModelData;
      let dataLocal = localStorage.getItem(nameData);
      if (!dataLocal || dataLocal === 'undefined') return this.setDataLocal();
      dataLocal = JSON.parse(dataLocal);
      return { data: dataLocal };
    }
    return this.setDataLocal();
  }

  async getFromCache() {
    try {
      // IDB is not supported in Firefox private mode: https://bugzilla.mozilla.org/show_bug.cgi?id=781982
      await this.dataManager.initDb();
    } catch {
      return this.getFromNetwork();
    }

    const { data } = await axios.get(
      `/api/v1/accounts/${this.accountIdFromRoute}/cache_keys`
    );
    const cacheKeyFromApi = data.cache_keys[this.cacheModelName];
    const isCacheValid = await this.validateCacheKey(cacheKeyFromApi);

    let localData = [];
    if (isCacheValid) {
      localData = await this.dataManager.get({
        modelName: this.cacheModelName,
      });
    }

    if (localData.length === 0) {
      return this.refetchAndCommit(cacheKeyFromApi);
    }

    return this.marshallData(localData);
  }

  async refetchAndCommit(newKey = null) {
    const response = await this.getFromNetwork();

    if (this.useLocalStorage) {
      this.setDataLocal(response);
      return response;
    }
    try {
      await this.dataManager.initDb();

      this.dataManager.replace({
        modelName: this.cacheModelName,
        data: this.extractDataFromResponse(response),
      });

      await this.dataManager.setCacheKeys({
        [this.cacheModelName]: newKey,
      });
    } catch {
      // Ignore error
    }

    return response;
  }

  async validateCacheKey(cacheKeyFromApi) {
    if (!this.useLocalStorage) {
      if (!this.dataManager.db) await this.dataManager.initDb();

      const cachekey = await this.dataManager.getCacheKey(this.cacheModelName);
      return cacheKeyFromApi === cachekey;
    }

    const cachekey = this.storageManager.getCacheKey();
    return cachekey === cacheKeyFromApi;
  }
}

export default CacheEnabledApiClient;
