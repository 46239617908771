<template>
  <div>
    <div class="flex flex-col">
      <label class="font-medium text-black-800 dark:text-slate-50">
        {{ title }}
      </label>
      <div class="ml-1 flex items-center mb-0.5">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    value: { type: String, required: true },
  },
};
</script>
