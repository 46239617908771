<template>
  <div style="">
    <div class="div-form">
      <woot-input
        v-model="title"
        :label="$t('TYPES.HISTORIC.FORM.TITLE.LABEL')"
        type="text"
        class="w-full"
        :placeholder="$t('TYPES.HISTORIC.FORM.TITLE.PLACEHOLDER')"
      />
      <woot-button
        class="mt-6"
        color-scheme="primary"
        :is-disabled="uiFlags.isCreating"
        @click="sendType"
      >
        {{ $t('TYPES.HISTORIC.FORM.BUTTON_SAVE') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      title: '',
      template: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'historicTypes/getUIFlags',
    }),
  },
  methods: {
    async sendType() {
      try {
        let dataSend = {
          title: this.title,
          ...(this.template !== '' && { template: this.template }),
          active: true,
        };
        await this.$store.dispatch('historicTypes/create', dataSend);
        this.showAlert(this.$t('TYPES.HISTORIC.API.SUCCESS_MESSAGE'));
        this.$emit('close');
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('TYPES.HISTORIC.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
