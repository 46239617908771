<template>
  <woot-modal :show.sync="show" :on-close="onClose">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header :header-title="pageTitle" />

      <form class="flex w-full" @submit.prevent="addAttributes">
        <div class="w-full">
          <label v-if="!isEdit" :class="{ error: $v.attributeModel.$error }">
            {{ $t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.LABEL') }}
            <select v-model="attributeModel">
              <option v-for="model in models" :key="model.id" :value="model.id">
                {{ model.option }}
              </option>
            </select>
            <span v-if="$v.attributeModel.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.MODEL.ERROR') }}
            </span>
          </label>
          <div class="div-form">
            <woot-input
              v-model="displayName"
              :label="$t('ATTRIBUTES_MGMT.ADD.FORM.NAME.LABEL')"
              type="text"
              class="w-full"
              :class="{ error: $v.displayName.$error }"
              :error="
                $v.displayName.$error
                  ? $t('ATTRIBUTES_MGMT.ADD.FORM.NAME.ERROR')
                  : ''
              "
              :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              @input="onDisplayNameChange"
              @blur="$v.displayName.$touch"
            />
            <woot-input
              v-model="attributeKey"
              :label="$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.LABEL')"
              type="text"
              class="w-full"
              :readonly="isEdit"
              :class="{ error: $v.attributeKey.$error }"
              :error="$v.attributeKey.$error ? keyErrorMessage : ''"
              :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.PLACEHOLDER')"
              @blur="$v.attributeKey.$touch"
            />
          </div>
          <woot-input
            v-model="fillingTip"
            :label="$t('ATTRIBUTES_MGMT.ADD.FORM.FILLING_TIP.LABEL')"
            type="text"
            :class="{ error: $v.fillingTip.$error }"
            :error="
              $v.fillingTip.$error
                ? $t('ATTRIBUTES_MGMT.ADD.FORM.FILLING_TIP.ERROR')
                : ''
            "
            :placeholder="
              $t('ATTRIBUTES_MGMT.ADD.FORM.FILLING_TIP.PLACEHOLDER')
            "
            @blur="$v.fillingTip.$touch"
          />
          <label :class="{ error: $v.description.$error }">
            {{ $t('ATTRIBUTES_MGMT.ADD.FORM.DESC.LABEL') }}
            <textarea
              v-model="description"
              rows="3"
              type="text"
              :placeholder="$t('ATTRIBUTES_MGMT.ADD.FORM.DESC.PLACEHOLDER')"
              @blur="$v.description.$touch"
            />
            <span v-if="$v.description.$error" class="message">
              {{ $t('ATTRIBUTES_MGMT.ADD.FORM.DESC.ERROR') }}
            </span>
          </label>
          <div class="div-form">
            <!-- <div class="flex flex-col items-center">
              <label class="whitespace-nowrap mx-2">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.REQUIRED.LABEL') }}
              </label>
              <span class="mt-2">
                <woot-switch v-model="attributeRequired" />
              </span>
            </div> -->
            <div class="w-full">
              <label :class="{ error: $v.attributeWhoManage.$error }">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.WO_CAN_MANAGE.LABEL') }}
                <select v-model="attributeWhoManage">
                  <option
                    v-for="type in whoManages"
                    :key="type.id"
                    :value="type.id"
                  >
                    {{
                      $t(
                        'ATTRIBUTES_MGMT.OPTIONS.WHO_MANAGE.' +
                          type.option.toUpperCase()
                      )
                    }}
                  </option>
                </select>
                <span v-if="$v.attributeWhoManage.$error" class="message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.WO_CAN_MANAGE.ERROR') }}
                </span>
              </label>
            </div>
            <div class="w-full">
              <label :class="{ error: $v.attributeType.$error }">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LABEL') }}
                <select v-model="attributeType" :disabled="isEdit">
                  <option v-for="type in types" :key="type.id" :value="type.id">
                    {{ type.option }}
                  </option>
                </select>
                <span v-if="$v.attributeType.$error" class="message">
                  {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.ERROR') }}
                </span>
              </label>
            </div>
          </div>
          <div class="w-full">
            <div v-if="isAttributeTypeList" class="multiselect--wrap">
              <label>
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.LABEL') }}
              </label>
              <multiselect
                ref="tagInput"
                v-model="values"
                :placeholder="
                  $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.PLACEHOLDER')
                "
                label="name"
                track-by="name"
                :class="{ invalid: isMultiselectInvalid }"
                :options="options"
                :multiple="true"
                :taggable="true"
                @close="onTouch"
                @tag="addTagValue"
              >
                <template slot="noResult">
                  {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
                </template>
                <template slot="noOptions">
                  {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
                </template>
              </multiselect>
              <label v-show="isMultiselectInvalid" class="error-message">
                {{ $t('ATTRIBUTES_MGMT.ADD.FORM.TYPE.LIST.ERROR') }}
              </label>
            </div>
          </div>
          <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
            <woot-submit-button
              :disabled="isButtonDisabled"
              :button-text="
                isEdit
                  ? $t('ATTRIBUTES_MGMT.EDIT.UPDATE_BUTTON_TEXT')
                  : $t('ATTRIBUTES_MGMT.ADD.SUBMIT')
              "
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('ATTRIBUTES_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { convertToAttributeSlug } from 'dashboard/helper/commons.js';
import {
  ATTRIBUTE_MODELS,
  ATTRIBUTE_TYPES,
  ATTRIBUTE_WHO_MANAGE,
} from './constants';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    selectedAttribute: {
      type: Object,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    modelDefault: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      attributeRequired: false,
      displayName: '',
      fillingTip: '',
      description: '',
      attributeModel: this.modelDefault,
      attributeType: 0,
      attributeKey: '',
      attributeWhoManage: 'user',
      models: ATTRIBUTE_MODELS,
      types: ATTRIBUTE_TYPES,
      whoManages: ATTRIBUTE_WHO_MANAGE,
      values: [],
      valuesWhoManage: [],
      options: [],
      show: true,
      isTouched: false,
    };
  },

  computed: {
    ...mapGetters({
      uiFlags: 'getUIFlags',
    }),
    isEdit() {
      return !!(this.selectedAttribute && this.selectedAttribute.id);
    },
    setAttributeListValue() {
      if (!this.isEdit) return [];
      return this.selectedAttribute.attribute_values.map(values => ({
        name: values,
      }));
    },
    pageTitle() {
      if (!this.isEdit) return this.$t('ATTRIBUTES_MGMT.ADD.TITLE');
      return `${this.$t('ATTRIBUTES_MGMT.EDIT.TITLE')} - ${
        this.selectedAttribute.attribute_display_name
      }`;
    },
    isMultiselectInvalid() {
      return this.isTouched && this.values.length === 0;
    },
    isTagInputInvalid() {
      return this.isAttributeTypeList && this.values.length === 0;
    },
    attributeListValues() {
      return this.values.map(item => item.name);
    },
    attributeManageValues() {
      return this.valuesWhoManage.map(item => item.name);
    },
    isButtonDisabled() {
      return (
        this.$v.displayName.$invalid ||
        this.$v.description.$invalid ||
        this.uiFlags.isCreating ||
        this.isTagInputInvalid
      );
    },
    keyErrorMessage() {
      if (!this.$v.attributeKey.isKey) {
        return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.IN_VALID');
      }
      return this.$t('ATTRIBUTES_MGMT.ADD.FORM.KEY.ERROR');
    },
    isAttributeTypeList() {
      return this.attributeType === 6 || this.attributeType === 8;
    },
    selectedAttributeType() {
      return this.types.find(
        item =>
          item.option.toLowerCase() ===
          this.selectedAttribute.attribute_display_type
      ).id;
    },
    selectedWhoManage() {
      return this.whoManages.find(
        item =>
          item.option.toLowerCase() ===
          this.selectedAttribute.attribute_who_can_manage
      ).id;
    },
  },

  validations: {
    displayName: {
      required,
      minLength: minLength(1),
    },
    fillingTip: {},
    description: {
      required,
    },
    attributeModel: {
      required,
    },
    attributeType: {
      required,
    },
    attributeKey: {
      required,
      isKey(value) {
        return !(value.indexOf(' ') >= 0);
      },
    },
    attributeWhoManage: {},
  },
  mounted() {
    // console.log('mounted ', this.selectedAttribute);
    if (this.isEdit) this.setDataAttributes();
  },
  methods: {
    setDataAttributes() {
      if (this.isEdit) {
        const {
          // id,
          attribute_display_name,
          // attribute_display_type,
          attribute_description,
          attribute_key,
          // attribute_model,
          // attribute_values,
          // default_value,
          attribute_required,
          attribute_filling_tip,
        } = this.selectedAttribute;
        this.attributeRequired = attribute_required;
        this.displayName = attribute_display_name;
        this.fillingTip = attribute_filling_tip;
        this.description = attribute_description;
        this.attributeKey = attribute_key;
        this.attributeType = this.selectedAttributeType;
        this.attributeWhoManage = this.selectedWhoManage;
        // this.values = attribute_values;
        this.values = this.setAttributeListValue;
        // this.attributeModel = 0,
        // this.attributeType = 0,
        // this.models = ATTRIBUTE_MODELS,
        // this.types = ATTRIBUTE_TYPES,
        // this.whoManages = ATTRIBUTE_WHO_MANAGE,
        // this.values = [],
        // this.valuesWhoManage = [],
        // this.options = [],
      }
    },
    addTagValue(tagValue) {
      const tag = {
        name: tagValue,
      };
      this.values.push(tag);
      this.$refs.tagInput.$el.focus();
    },
    onTouch() {
      this.isTouched = true;
    },
    onDisplayNameChange() {
      if (!this.isEdit)
        this.attributeKey = convertToAttributeSlug(this.displayName);
    },
    async addAttributes() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let dataSend = {
        attribute_display_name: this.displayName,
        attribute_description: this.description,
        attribute_values: this.attributeListValues,
        attribute_required: this.attributeRequired,
        attribute_filling_tip: this.fillingTip,
        attribute_who_can_manage: this.attributeWhoManage,
      };
      try {
        if (this.isEdit) {
          dataSend.id = this.selectedAttribute.id;
          await this.$store.dispatch('attributes/update', dataSend);
          this.alertMessage = this.$t(
            'ATTRIBUTES_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
        } else {
          dataSend.attribute_model = this.attributeModel;
          dataSend.attribute_display_type = this.attributeType;
          dataSend.attribute_key = this.attributeKey;
          await this.$store.dispatch('attributes/create', dataSend);
          this.alertMessage = this.$t(
            'ATTRIBUTES_MGMT.ADD.API.SUCCESS_MESSAGE'
          );
        }
        this.onClose();
      } catch (error) {
        const errorMessage = error?.message;
        this.alertMessage =
          errorMessage || this.$t('ATTRIBUTES_MGMT.ADD.API.ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.div-form {
  @apply flex flex-row gap-2;
}
.key-value {
  padding: 0 var(--space-small) var(--space-small) 0;
  font-family: monospace;
}
.multiselect--wrap {
  margin-bottom: var(--space-normal);
  .error-message {
    color: var(--r-400);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
  }
  .invalid {
    ::v-deep {
      .multiselect__tags {
        border: 1px solid var(--r-400);
      }
    }
  }
}
::v-deep {
  .multiselect {
    margin-bottom: 0;
  }
  .multiselect__content-wrapper {
    display: none;
  }
  .multiselect--active .multiselect__tags {
    border-radius: var(--border-radius-normal);
  }
}
</style>
