<template>
  <div class="w-full">
    <label>
      {{ label }}
      <input
        :id="id"
        :value="val"
        :placeholder="placeholder"
        type="time"
        name="hora"
        class="my-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md custom-input"
        @change="handleChange"
      />
      <span v-if="errorForm" class="editor-warning__message">
        {{ errorMessage }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'form-time-id',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Date,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dayDefault: '2024-01-01',
      val: '',
    };
  },
  watch: {
    value() {
      if (this.value && this.value.toLocaleTimeString()) {
        this.val = this.value.toLocaleTimeString().substring(0, 5);
      } else {
        this.val = '';
      }
    },
  },
  methods: {
    handleChange(value) {
      if (value && value.target.value && value.target.value.length > 4) {
        const tempValue = new Date(`${this.dayDefault}T${value.target.value}`); // .toISOString()
        this.$emit('change', tempValue);
      }
    },
  },
};
</script>

<style></style>
