<template>
  <section
    class="campaign-table-wrap bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="selectProfessional.name"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
    <woot-confirm-modal
      ref="confirmDialog"
      :title="toggleModalTitle"
      :description="toggleModalDescription"
      :confirm-label="$t('PROFESSIONAL.TOGGLE.CONFIRMATION_LABEL')"
      :cancel-label="$t('PROFESSIONAL.TOGGLE.CANCEL_LABEL')"
    />
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    VeTable,
  },
  mixins: [clickaway, timeMixin, rtlMixin, alertMixin],
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    status: { type: String, default: null },
    meta: { type: Object, default: null },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
      showDeleteModal: false,
      selectProfessional: null,
      toggleModalTitle: this.$t('PROFESSIONAL.TOGGLE.ACTIVATION_TITLE'),
      toggleModalDescription: this.$t(
        'PROFESSIONAL.TOGGLE.ACTIVATION_DESCRIPTION'
      ),
    };
  },
  computed: {
    tableData() {
      let data = this.dataList;
      if (data && data.length > 0) {
        if (this.meta) {
          data = data.slice(
            (this.meta.currentPage - 1) * this.meta.perPage,
            (this.meta.currentPage - 1) * this.meta.perPage + this.meta.perPage
          );
        }
        data = data.map(item => this.formatData(item));
        return data;
      }
      return [];
    },
    columns() {
      return [
        {
          field: 'name',
          key: 'name',
          // sortBy: this.sortConfig.campaign_start || '',
          title: this.$t('PROFESSIONAL.ADD.FORM.NAME.LABEL'),
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'email',
          key: 'campaign_end',
          // sortBy: this.sortConfig.campaign_end || '',
          title: this.$t('PROFESSIONAL.LIST.EMAIL'),
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'phone_number_1',
          key: 'time',
          // sortBy: this.sortConfig.initial_send_time || '',
          title: this.$t('PROFESSIONAL.LIST.PHONE_NUMBER'),
          align: this.time ? 'right' : 'left',
          width: 70,
        },
        {
          field: 'address',
          key: 'address',
          // sortBy: this.sortConfig.initial_send_time || '',
          title: this.$t('PROFESSIONAL.ADD.FORM.ADDRESS'),
          align: this.time ? 'right' : 'left',
          width: 70,
        },
        {
          field: 'active',
          key: 'active',
          // sortBy: this.sortConfig.initial_send_time || '',
          title: this.$t('PROFESSIONAL.LIST.ACTIVE'),
          align: this.time ? 'right' : 'left',
          width: 30,
          renderBodyCell: ({ row }) => (
            <div>
              {/* <span class="text-sm whitespace-nowrap mx-2">
                {this.$t('PROFESSIONAL.ADD.FORM.ACTIVE')}
              </span> */}
              <span>
                <woot-switch
                  value={row.active}
                  function={() => this.toggleActive(row)}
                />
              </span>
            </div>
          ),
        },
        {
          field: 'phone_number_2',
          key: 'phone_number_2',
          // sortBy: this.sortConfig.initial_send_time || '',
          title: '',
          align: 'right',
          width: 40,
          renderBodyCell: ({ row }) => (
            <div class="flex gap-2">
              <woot-button
                v-tooltip={this.$t('PROFESSIONAL.EDIT_BTN')}
                title={this.$t('PROFESSIONAL.EDIT_BTN')}
                icon="edit"
                size="small"
                onClick={() => this.editProfessional(row)}
              />
              <woot-button
                v-tooltip={this.$t('PROFESSIONAL.DELETE')}
                title={this.$t('PROFESSIONAL.DELETE')}
                icon="delete"
                variant="smooth"
                size="small"
                color-scheme="alert"
                // disabled="uiFlags.isDeleting"
                onClick={() => this.toggleDeleteModal(row)}
              />
            </div>
          ),
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    async toggleActive(professional) {
      let dataSend = { ...professional };
      dataSend.active = !dataSend.active;
      try {
        this.toggleModalTitle = professional.active
          ? this.$t('PROFESSIONAL.TOGGLE.DEACTIVATION_TITLE')
          : this.$t('PROFESSIONAL.TOGGLE.ACTIVATION_TITLE');
        this.toggleModalDescription = professional.active
          ? this.$t('PROFESSIONAL.TOGGLE.DEACTIVATION_DESCRIPTION', {
              professionalName: professional.name,
            })
          : this.$t('PROFESSIONAL.TOGGLE.ACTIVATION_DESCRIPTION', {
              professionalName: professional.name,
            });
        const ok = await this.$refs.confirmDialog.showConfirmation();
        if (ok) {
          await this.$store.dispatch('professionals/update', dataSend);
          const message = professional.active
            ? this.$t('PROFESSIONAL.TOGGLE.DEACTIVATION_SUCCESFUL')
            : this.$t('PROFESSIONAL.TOGGLE.ACTIVATION_SUCCESFUL');
          this.showAlert(message);
        }
      } catch (error) {
        this.showAlert(this.$t('PROFESSIONAL.EDIT.API.ERROR_MESSAGE'));
      }
    },
    async deleteProfessional({ id }) {
      try {
        await this.$store.dispatch('professionals/delete', id);
        this.showAlert(this.$t('DELETE_PROFESSIONAL.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('DELETE_PROFESSIONAL.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
    confirmDeletion() {
      this.deleteProfessional(this.selectProfessional);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      // this.showConversationModal = false;
      // this.showEditModal = false;
    },
    toggleDeleteModal(professional) {
      this.selectProfessional = professional;
      this.showDeleteModal = !this.showDeleteModal;
    },
    editProfessional(professional) {
      this.$emit('on-edit', professional);
    },
    formatData(itemList) {
      const {
        // address_street: street,
        // address_number: streetNumb,
        // address_city: city,
        active,
      } = itemList;
      const address = this.getAddess(itemList); // `${street || ''} ${streetNumb || ''} ${city || ''}`;
      const descriptionActive = active
        ? this.$t('PROFESSIONAL.LIST.ACTIVE')
        : this.$t('PROFESSIONAL.LIST.INACTIVE');
      return {
        ...itemList,
        address,
        descriptionActive,
      };
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
    getAddess(professional) {
      let address = '';
      if (professional) {
        address = professional.address_street || '';
        address += ' ';
        if (professional.address_number)
          address += professional.address_number + ', ';

        address += professional.address_city || '';
      }
      return address;
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }
  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    @apply -top-1;
  }
  #span-status > .span-status {
    padding: 0.25rem;
    border-radius: 4px;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
</style>
