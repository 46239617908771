// import { getPartner } from 'shared/store/globalConfig';
export default {
  computed: {
    partner() {
      const domain = window.location.host;
      const partner = domain.split('.')[0];
      if (partner === 'chat') return null;
      // if (partner !== 'ditel') return null;
      return partner;
    },
    // partner: getPartner(),

    logo() {
      if (this.partner) {
        if (window.globalConfig[`${this.partner}_LOGO`])
          return window.globalConfig[`${this.partner}_LOGO`];
      }
      return this.globalConfig.logo;
    },
    installationName() {
      if (this.partner) {
        if (window.globalConfig[`${this.partner}_NAME`])
          return window.globalConfig[`${this.partner}_NAME`];
      }
      return this.globalConfig.installationName;
    },
  },
  methods: {
    // eslint-disable-next-line default-param-last
    useInstallationName(str = '', installationName) {
      return str
        .replace(/Chatwoot/g, installationName)
        .replace(/Nubla Services/g, installationName)
        .replace(/Nubla Atendimento/g, installationName);
    },
  },
};
