<template>
  <div v-show="isVisible" class="modal-overlay" @click="closeModal">
    <div class="modal-content">
      <img :src="src" :alt="alt" class="modal-image rounded w-[700px]" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageModal',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scope>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
}
</style>
