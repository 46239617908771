/* global axios */
import ApiClient from './ApiClient';

class HISTORIESAPI extends ApiClient {
  constructor() {
    super('contact_historics', { accountScoped: true });
  }

  getFilter(params) {
    return axios.get(`${this.url}/`, { params });
  }

  importFiles(historicId, files) {
    const formData = new FormData();
    files.map(file => {
      formData.append('attachments[]', file.file);
      return file;
    });
    formData.append('contact_historic_id', historicId);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new HISTORIESAPI();
