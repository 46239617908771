import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CampaignsMessagesAPI from '../../api/campaignsMessages';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CAMPAIGNS_MESSAGES_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isFetchingContacts: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCampaign: _state => campaignId => {
    const list = _state.records;
    const index = list.findIndex(item => item.id === Number(campaignId));
    const result = list[index];
    return index > -1 ? result : {};
  },
  getAllCampaigns: _state => {
    return _state.records;
  },
  getContacts: _state => campaignId => {
    const index = _state.records.findIndex(record => record.id === +campaignId);
    return index > -1 ? _state.records[index].contacts : [];
  },
  getInboxes: _state => campaignId => {
    const index = _state.records.findIndex(record => record.id === +campaignId);
    if (index === -1 || !_state.records[index].inboxesInfo) return [];
    return _state.records[index].inboxesInfo;
  },
};

export const actions = {
  get: async function getCampaignMsgContacts({ commit }) {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignsMessagesAPI.get();
      commit(types.SET_CAMPAIGNS_MESSAGES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCampaign({ commit }, { campaign, file }) {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isCreating: true });
    try {
      const response = await CampaignsMessagesAPI.create(campaign);
      commit(types.ADD_CAMPAIGN_MESSAGES, response.data);
      if (file) {
        await CampaignsMessagesAPI.importContacts(response.data.id, file);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isUpdating: true });
    try {
      const response = await CampaignsMessagesAPI.update(id, updateObj);
      AnalyticsHelper.track(CAMPAIGNS_MESSAGES_EVENTS.UPDATE_CAMPAIGN_MESSAGES);
      commit(types.EDIT_CAMPAIGN_MESSAGES, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isDeleting: true });
    try {
      await CampaignsMessagesAPI.delete(id);
      AnalyticsHelper.track(CAMPAIGNS_MESSAGES_EVENTS.DELETE_CAMPAIGN_MESSAGES);
      commit(types.DELETE_CAMPAIGN_MESSAGES, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, { isDeleting: false });
    }
  },
  getInboxes: async ({ commit }, campaignMsgId) => {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, {
      isFetchingContacts: true,
    });
    try {
      const response = await CampaignsMessagesAPI.getInboxes(campaignMsgId);
      commit(types.SET_CAMPAIGN_MSG_INBOXES, {
        id: +campaignMsgId,
        inboxesInfo: response.data,
      });
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, {
        isFetchingContacts: false,
      });
    } catch (error) {
      // *
    }
  },
  getContacts: async ({ commit }, campaignMsgId) => {
    commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, {
      isFetchingContacts: true,
    });
    try {
      const response = await CampaignsMessagesAPI.getContacts(campaignMsgId);
      commit(types.SET_CAMPAIGN_MSG_CONTACTS, {
        id: +campaignMsgId,
        contacts: response.data,
      });
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, {
        isFetchingContacts: false,
      });
    } catch (error) {
      commit(types.SET_CAMPAIGN_MESSAGES_UI_FLAG, {
        isFetchingContacts: false,
      });
      // commit(types.SET_CAMPAIGN_MSG_CONTACTS_UI_FLAG, {
      //   isFetching: false,
      // });
      // Ignore error
    }
  },
  import: async ({ commit }, file) => {
    commit(types.SET_CAMPAIGN_MSG_IMPORT_CONTACT_UI_FLAG, { isCreating: true });
    try {
      await CampaignsMessagesAPI.importContacts(file);
      commit(types.SET_CAMPAIGN_MSG_IMPORT_CONTACT_UI_FLAG, {
        isCreating: false,
      });
    } catch (error) {
      commit(types.SET_CAMPAIGN_MSG_IMPORT_CONTACT_UI_FLAG, {
        isCreating: false,
      });
      if (error.response?.data?.message) {
        throw new ExceptionWithMessage(error.response.data.message);
      }
    }
  },
};

export const mutations = {
  [types.SET_CAMPAIGN_MESSAGES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_CAMPAIGN_MESSAGES]: MutationHelpers.create,
  [types.SET_CAMPAIGNS_MESSAGES]: MutationHelpers.set,
  [types.EDIT_CAMPAIGN_MESSAGES]: MutationHelpers.update,
  [types.DELETE_CAMPAIGN_MESSAGES]: MutationHelpers.destroy,
  [types.SET_CAMPAIGN_MSG_CONTACTS]: MutationHelpers.updateAttributes,
  [types.SET_CAMPAIGN_MSG_INBOXES]: MutationHelpers.updateAttributes,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
