<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="$t('INBOX_MGMT.ADD.AUTH.TITLE')" />
    <div class="h-auto overflow-auto flex px-6 pt-2 pb-4">
      <channel-item
        v-for="channel in channelList"
        :key="channel.key"
        :channel="channel"
        :enabled-features="enabledFeatures"
        @channel-item-click="initChannelAuth"
      />
    </div>
  </div>
</template>

<script>
import ChannelItem from 'dashboard/components/widgets/ChannelItem';
import { mapGetters } from 'vuex';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
export default {
  components: {
    ChannelItem,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      enabledFeatures: {
        campaigns_messages: true,
      },
    };
  },
  computed: {
    channelList() {
      const { apiChannelName, apiChannelThumbnail } = this.globalConfig;
      return [
        { key: 'whatsapp', name: 'WhatsApp' },
        {
          key: 'api',
          name: apiChannelName || 'API',
          thumbnail: apiChannelThumbnail,
        },
      ];
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      globalConfig: 'globalConfig/get',
    }),
  },
  methods: {
    initChannelAuth(channel) {
      this.$emit('channel-item-click', channel);
    },
  },
};
</script>

<style></style>
