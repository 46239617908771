<template>
  <div class="w-full">
    <textarea
      v-model="processedString"
      rows="4"
      readonly
      class="template-input"
    />
    <div v-if="variables" class="template__variables-container">
      <p v-if="varsDoc" class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_AVAILABLE_DOC') }}
      </p>
      <div class="flex">
        <span v-for="varItem in varsDoc" :key="varItem" class="variable-doc">
          {{ varItem }}
        </span>
      </div>
      <!-- 
      <div class="flex">
        <button
          v-for="varItem in varsDoc"
          :key="varItem"
          class="button secondary"
          @click="onCopy"
        >
          {{ varItem }}
        </button>
      </div> -->
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <p v-if="$v.$dirty && $v.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>
    <footer>
      <woot-button variant="smooth" @click="$emit('resetTemplate')">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button type="button" @click="sendMessage">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.' + textCustomSubmit) }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};
import { requiredIf } from 'vuelidate/lib/validators';
export default {
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    valueParams: {
      type: Object,
      default: null,
    },
    textCustomSubmit: {
      type: String,
      default: 'SEND_MESSAGE_LABEL',
    },
    varsDoc: {
      type: Array,
      default: null,
    },
  },
  validations: {
    processedParams: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
    },
  },
  data() {
    return {
      processedParams: {},
    };
  },
  computed: {
    variables() {
      const variables = this.templateString.match(/{{([^}]+)}}/g);
      return variables;
    },
    templateString() {
      return this.template.components.find(
        component => component.type === 'BODY'
      ).text;
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
  },
  mounted() {
    // console.log('template', this.template);
    // console.log('valueParams', this.valueParams);
    // if (this.valueParams) this.processedParams = { ...this.valueParams };
    // console.log('processedParams', this.processedParams);
    // console.log('template parser ', this.varsDoc);
    this.generateVariables();
  },
  methods: {
    sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const payload = {
        message: this.processedString,
        templateParams: {
          name: this.template.name,
          category: this.template.category,
          language: this.template.language,
          namespace: this.template.namespace,
          processed_params:
            this.template.processedParams || this.processedParams,
        },
      };
      // console.log('sendMessage valueParams', this.valueParams);
      this.$emit('sendMessage', payload);
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateVariables() {
      if (this.valueParams) {
        this.processedParams = { ...this.valueParams };
      } else {
        const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
        if (!matchedVariables) return;

        const variables = matchedVariables.map(i => this.processVariable(i));
        this.processedParams = variables.reduce((acc, variable) => {
          acc[variable] = '';
          return acc;
        }, {});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 mx-1 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}
.variable-doc {
  @apply bg-slate-75 mx-1 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
}
footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}
.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
.template-input {
  @apply bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100;
}
</style>
