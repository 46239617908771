import { frontendURL } from '../../../../helper/URLHelper';

const campaignsMessages = accountId => ({
  parentNav: 'campaigns_messages',
  routes: [
    'campaigns_messages',
    'inbox_campaigns_messages',
    'label_campaigns_messages',
    'status_campaigns_messages',
    'campaigns_messages_details',
  ],
  menuItems: [
    {
      icon: 'megaphone',
      label: 'ALL_CAMPAIGNS_MSG',
      key: 'campaigns_messages',
      toState: frontendURL(`accounts/${accountId}/campaigns_messages`),
      toolTip: 'Campaigns from all subscribed inboxes',
      toStateName: 'campaigns_messages',
    },
  ],
});

export default campaignsMessages;
