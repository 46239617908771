<template>
  <div
    v-if="!provider"
    class="wizard-body small-12 medium-9 columns height-auto"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.API_PROVIDER.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.API_PROVIDER.DESCRIPTION')"
    />
    <div class="row channel-list">
      <channel-selector
        v-for="apiProvider in apiProviderList"
        :key="apiProvider.key"
        :title="apiProvider.title"
        :src="apiProvider.src"
        @click="() => onClick(apiProvider.key)"
      />
    </div>
  </div>
  <evolution-index v-else-if="provider === 'evo'" />
  <webhook-api v-else-if="provider === 'webhook_provider'" />
</template>
<script>
import { mapGetters } from 'vuex';
import WebhookApi from './apiChannels/WebhookApi.vue';
import EvolutionIndex from './apiChannels/IndexEvoApi.vue';
import ChannelSelector from 'dashboard/components/ChannelSelector.vue';
import PageHeader from '../../SettingsSubPageHeader.vue';

export default {
  components: {
    ChannelSelector,
    EvolutionIndex,
    WebhookApi,
    PageHeader,
  },
  data() {
    return { provider: '' };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    apiProviderList() {
      return [
        {
          title: this.$t('INBOX_MGMT.API_PROVIDER.EVO_API'),
          // isEnabled: !!this.globalConfig.azureAppId,
          isEnabled: true,
          key: 'evo',
          src: '/assets/images/dashboard/channels/nubla-evo.png',
        },
        {
          title: this.$t('INBOX_MGMT.API_PROVIDER.WEBHOOK_PROVIDER'),
          isEnabled: true,
          key: 'webhook_provider',
          src: '/assets/images/dashboard/channels/api.png',
        },
      ].filter(provider => provider.isEnabled);
    },
  },
  methods: {
    onClick(provider) {
      this.provider = provider;
    },
  },
};
</script>

<style scoped>
.channel-list {
  margin-top: var(--space-medium);
}
</style>
