<template>
  <transition-group name="wizard-items" tag="div" class="wizard-box w-full">
    <div
      v-for="(item, index) in steps"
      :key="item.step"
      class="item"
      :class="{ active: isActive(item), over: isOver(item) }"
    >
      <div class="flex items-center">
        <h3
          class="overflow-hidden whitespace-nowrap text-ellipsis leading-tight"
        >
          {{ item.title }}
        </h3>
        <span
          v-if="isOver(item)"
          class="text-green-500 dark:text-green-500 ml-1"
        >
          <fluent-icon icon="checkmark" />
        </span>
      </div>
      <span class="step">
        {{ index + 1 }}
      </span>
      <p class="text-slate-600 dark:text-slate-300 text-sm m-0 pl-6">
        {{ item.description }}
      </p>
      <bar-progress
        v-if="isActive(item) && !finish"
        :indeterminate="true"
        classes="mx-24"
      />
    </div>
  </transition-group>
</template>

<script>
import BarProgress from 'dashboard/components/widgets/chart/BarProgress.vue';
export default {
  components: {
    BarProgress,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    activeStep() {
      return this.step;
    },
    finish() {
      return this.step >= this.steps.length;
    },
  },
  methods: {
    isActive(step) {
      return this.steps.indexOf(step) === this.step;
    },
    isOver(step) {
      return this.steps.indexOf(step) < this.step;
    },
  },
};
</script>
<style>
.mx-24 {
  margin: 0 24px;
}
</style>
