<template>
  <div :class="{ 'div-form': !isMicro, 'content-micro': isMicro }">
    <div :class="{ 'mt-0 multiselect-wrap--medium w-full': !isMicro }">
      <!-- :class="{ error: $v.contact.$error }" -->
      <label v-if="!isMicro && !hideLabel" class="multiselect__label">
        {{ $t('CALENDAR.SCHEDULE.CONTACT.LABEL') }}
      </label>
      <multiselect
        id="infinite-list"
        v-model="contactsFilter"
        track-by="id"
        class="mb-0-important"
        :class="{ 'multiselect-micro-line': isMicro }"
        selected-label
        :placeholder="$t('CALENDAR.SCHEDULE.CONTACT.PLACEHOLDER')"
        :select-label="$t('CALENDAR.SCHEDULE.CONTACT.SELECT_PLACEHOLDER')"
        :deselect-label="$t('CALENDAR.SCHEDULE.CONTACT.REMOVE')"
        :max-height="320"
        label="name"
        :options="recordContacts"
        :allow-empty="true"
        :custom-label="customContactLabel"
        :internal-search="false"
        :preserve-search="true"
        @search-change="contactSearch2"
      >
        <template slot="option" slot-scope="{ option }">
          <div>
            {{ formatContactLabel(option) }}
          </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          {{
            option && option.name !== undefined
              ? formatContactLabel(option)
              : ''
          }}
        </template>
        <template slot="noResult">
          {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
        </template>
        <template slot="noOptions">
          {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
        </template>
        <template slot="afterList">
          <li role="option" class="multiselect__element">
            <span
              v-if="recordContacts.length < meta.count"
              id="load-more-contacts"
              class="multiselect__option multiselect__option--highlight add-new-contact-btn"
              @click="handleAddContacts2"
            >
              {{ $t('CALENDAR.SCHEDULE.CONTACT.ADD') }}
            </span>
            <span
              v-else
              id="load-more-contacts_else"
              class="multiselect__option multiselect__option--highlight add-new-contact-btn"
              @click="showDialogNewContact"
            >
              {{ $t('CALENDAR.SCHEDULE.CONTACT.NEW') }}
            </span>
          </li>
        </template>
      </multiselect>
      <create-contact
        :show="showCreateModal"
        @cancel="onToggleCreate"
        @contact-created="onContactCreated"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact.vue';
export default {
  components: {
    CreateContact,
  },
  props: {
    isMicro: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      contactsFilter: {
        id: '',
        name: this.$t('CALENDAR.SCHEDULE.CONTACT.SELECT_PLACEHOLDER_FILTER'),
        phone_number: '',
      },
      // contactList: [],
      valInitSearch: '',
      valueQuery: '',
      pageSelectContact: 1,
      loadingContacts: false,
      // metaSelectContact: {},
      itemPerPage: 2,
      searchQuery: '',
      showAddPopup: false,
      timeoutId: {},
      showCreateModal: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      meta: 'contacts/getMeta',
      recordContacts: 'contacts/getContacts',
      hideNumPhone: 'getHideNumContactPhone',
      // currentRole: 'getCurrentRole',
      // accountId: 'getCurrentAccountId',
      // isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    // hideNumPhone() {
    //   const isAdministrator = this.currentRole === 'administrator';
    //   return (
    //     !isAdministrator &&
    //     this.isFeatureEnabledonAccount(this.accountId, 'hide_phone_number')
    //   );
    // },
  },
  watch: {
    recordContacts() {
      if (this.contact?.id) {
        this.contactsFilter = this.contact;
      }
      this.contactList = this.recordContacts;
    },
    contactsFilter() {
      this.$emit('update', this.contactsFilter);
    },
  },
  async mounted() {
    if (this.contact?.id) {
      this.$emit('update', this.contactsFilter);
    }
    this.$store.dispatch('contacts/get');
  },
  methods: {
    include(element, include) {
      if (!element || !include) return false;
      return (
        element.toLocaleLowerCase().indexOf(include.toLocaleLowerCase()) > -1
      );
    },
    customContactLabel(option) {
      const phone = option.phone_number !== null ? option.phone_number : '';
      return `${option.name} ${phone}`;
    },
    onContactCreated(createdContact) {
      this.$emit('update', createdContact);
      this.showCreateModal = false;
    },
    contactSearch2(search) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.pageSelectContact = 1;
        this.fetchContacts(this.pageSelectContact, search);
      }, 1000);
    },
    handleAddContacts2() {
      this.pageSelectContact += 1;
      const requestParams = {
        search: this.valueQuery === '' ? null : this.valueQuery,
        page: this.pageSelectContact,
        list: this.contactList,
      };
      this.$store.dispatch('contacts/getUpdateList', requestParams);
    },
    showDialogNewContact() {
      this.showCreateModal = true;
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
    formatContactLabel(contact) {
      if (this.hideNumPhone) return contact.name;
      const phone = contact.phone_number !== null ? contact.phone_number : '';
      return `${contact.name} ${phone}`;
    },
    fetchContacts(page, querySearch) {
      const requestParams = {
        page,
      };
      if (!querySearch) {
        this.$store.dispatch('contacts/get', requestParams);
      } else {
        this.$store.dispatch('contacts/search', {
          search: querySearch,
          ...requestParams,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.div-form {
  @apply flex flex-row gap-2;
}
.content-micro {
  width: 300px;
}
.mb-0-important {
  margin-bottom: 0px !important;
}
.multiselect-micro-line {
  min-height: 30px !important;
  height: 30px !important;
  .multiselect__select {
    min-height: 30px;
    height: 30px;
    padding-top: 5px;
  }
  .multiselect__tags {
    min-height: 30px;
    height: 30px;
    padding: 0px 30px 0 4px;
    border-width: 0 0 1px 0;
    border-radius: 4px;
    .multiselect__input {
      height: 30px;
    }
    .multiselect__single {
      padding: 4px;
    }
    .multiselect__placeholder {
      padding-top: 4px;
    }
  }
}
</style>
