<template>
  <div class="px-4 py-2 dark:text-slate-100" style="overflow-y: scroll">
    <FullCalendar
      ref="calendar"
      :options="calendarOptions"
      @eventClick="handleEventClick"
      @datesRender="handleDatesRender"
    />
    <woot-modal :show.sync="showEditModal" :on-close="toggleSelectPopup">
      <handle-event-modal
        :title="eventCalendar.title"
        :data-event="editedEvent"
        @on-close="toggleSelectPopup"
      />
    </woot-modal>
    <woot-modal :show.sync="showNewModal" :on-close="toggleNewPopup">
      <new-schedule-event
        :date="eventDate"
        :data-string="eventDateString"
        :professional="professional"
        @on-close="toggleNewPopup"
      />
    </woot-modal>
  </div>
</template>

<script>
// import { ref } from 'vue';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import handleEventModal from './HandleEventModal.vue';
import NewScheduleEvent from './NewScheduleEvent.vue';

export default {
  components: {
    FullCalendar,
    handleEventModal,
    NewScheduleEvent,
  },
  props: {
    title: { type: String, default: 'Calendar' },
    professional: {
      type: Object,
      default: () => ({}),
    },
    events: {
      type: Array,
      default: () => [],
    },
    eventsOriginal: {
      type: Array,
      default: () => [],
    },
    expandAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        locales: [ptBrLocale],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek', // Adicione os botões de visualização desejados
        },
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: [],
        editable: false,
        selectable: false,
        dayMaxEventRows: true, // for all non-TimeGrid views
        views: {
          dayGridMonth: {
            // titleFormat: { year: 'numeric', month: 'long' },
            dayMaxEventRows: this.expandAll ? 40 : 4, // adjust to 6 only for timeGridWeek/timeGridDay
            titleFormat: this.customTitleFormat,
          },
          timeGrid: {
            dayMaxEventRows: 5, // adjust to 6 only for timeGridWeek/timeGridDay
          },
          dayGrid: {
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
          },
          week: {
            // options apply to dayGridWeek and timeGridWeek views
          },
          day: {
            // options apply to dayGridDay and timeGridDay views
          },
        },
        // fixedWeekCount: false, default: 6, false: 4~6
        contentHeight: 'auto',
        // contentHeight: window.screen.height - 130,
        // select: this.handleDateSelection, // method select interval
        // eventDrop: this.handleEventDrop, // method move schedule
        displayEventTime: true, // show time event
        datesSet: this.handleDatesRender,
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          hour12: false, // Formato de 24 horas
        },
        eventDisplay: 'block', // Configuração para permitir personalização do estilo do evento
        moreLinkClick: this.handleMoreLinkClick,
      },
      showEditModal: false,
      showNewModal: false,
      editedEvent: {
        id: null,
        title: '',
        start: null,
      },
      eventCalendar: {},
      eventDate: null,
      eventDateString: '',
      fcPopover: null,
      left: 50,
      top: 50,
      dragging: false,
      mouseX: 0,
      mouseY: 0,
      posX: 0,
      posY: 0,
      isShowDetailWindows: false,
    };
  },
  watch: {
    expandAll() {
      this.calendarOptions.views.dayGridMonth.dayMaxEventRows = this.expandAll
        ? 40
        : 4;
    },
    events() {
      this.calendarOptions.events = this.events;
    },
    eventCalendar() {
      const eventId = this.eventsOriginal.findIndex(
        item => item.id === +this.eventCalendar.id
      );
      this.editedEvent = this.eventsOriginal[eventId];
    },
  },
  mounted() {},
  methods: {
    handleMoreLinkClick(info) {
      const moreModalHeight = 282;
      const moreModalWidth = 259;
      this.left = info.jsEvent.clientX - moreModalWidth;
      this.top = info.jsEvent.clientY - moreModalHeight;
      setTimeout(() => {
        this.fcPopover = document.querySelector('.fc-popover');
        if (this.fcPopover) {
          this.fcPopover.onmousedown = this.startDrag;
        }
      }, 100);
    },
    startDrag(e) {
      this.dragging = true;
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      this.posX = this.left;
      this.posY = this.top;

      document.addEventListener('mousemove', this.drag);
      document.addEventListener('mouseup', this.drop);
    },
    drag(e) {
      if (this.dragging) {
        const diffX = e.clientX - this.mouseX;
        const diffY = e.clientY - this.mouseY;
        this.left = this.posX + diffX;
        this.top = this.posY + diffY;
      }
      this.fcPopover.setAttribute(
        'style',
        `left: ${this.left}px; top: ${this.top}px`
      );
    },
    drop() {
      this.dragging = false;
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.drop);
    },
    toggleNewPopup() {
      this.showNewModal = !this.showNewModal;
    },
    toggleSelectPopup() {
      this.showEditModal = !this.showEditModal;
    },
    handleEventClick(info) {
      const eventSelect = { ...info.event.toPlainObject() };
      this.eventCalendar = eventSelect;
      setTimeout(() => {
        if (this.fcPopover) {
          this.fcPopover.remove();
        }
      }, 0);
      this.showEditModal = true;
    },
    updateEvent() {
      this.$refs.calendar
        .getApi()
        .getEventById(this.editedEvent.id)
        .setProp('title', this.editedEvent.title);

      this.showEditModal = false;
    },
    handleDateClick(info) {
      // const start = info.start;
      // const end = info.end;
      this.eventDate = info.date;
      this.eventDateString = info.dateStr;
      this.showNewModal = true;

      // this.eventCalendar
      // console.log('date click! ' + arg.dateStr); arg
    },
    // handleDateSelection(info) {
    //   const start = info.start;
    //   const end = info.end;
    //   console.log('click new Event ', start, end);

    //   this.eventCalendar
    //   // this.editedEvent

    //   // console.log('handleDateSelection date click! ' + start + '', end);

    //   const newEvent = {
    //     title: 'New Schedule',
    //     start,
    //     end,
    //   };

    //   this.calendarOptions.events.push(newEvent);

    //   // this.$refs.calendar.getApi().unselect();
    // },
    customTitleFormat(date) {
      const monthIndex = date.date.month;
      const locale = date.localeCodes[0];
      const year = date.date.year;

      const monthName = new Intl.DateTimeFormat(locale, {
        month: 'long',
      }).format(new Date(year, monthIndex, 1));

      const formattedMonth =
        monthName.charAt(0).toUpperCase() + monthName.slice(1);

      const separator = locale === 'pt-br' ? ' de ' : ' ';

      const formattedString = formattedMonth + separator + year;
      return formattedString;
    },
    // handleEventDrop(info) {
    // Atualize o evento movido no backend se necessário
    // console.log('Evento movido:', info.event);
    // },
    handleDatesRender(info) {
      // Emit the dates-render event to the parent component
      this.$emit('dates-render', info);
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 4px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}
.fc-event-main .fc-event-main-frame .fc-event-time {
  min-width: 35px;
}

.fc-event-main .fc-event-main-frame .fc-event-title-container .fc-event-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-bottom: 0em;
}

.fc-timegrid-event .fc-event-main {
  padding: 1px 1px 0px;
  line-height: 1;
}
#fc-dom-1 {
  @apply dark:text-slate-100;
}
.fc-event-main-frame {
  @apply dark:text-slate-100;
}
.fc-popover {
  @apply dark:bg-slate-900;
}

.fc-list-event-time:hover {
  @apply dark:text-slate-900;
}
.fc-list-event-title:hover {
  @apply dark:text-slate-900;
}

.fc .fc-list-event {
  @apply dark:text-slate-900;
}

.fc-list-event-time:hover {
  @apply dark:text-slate-900;
}
.fc-list-event-title:hover {
  @apply dark:text-slate-900;
}
</style>
