<template>
  <div class="relative items-center p-4 bg-white dark:bg-slate-900">
    <div class="text-left rtl:text-right">
      <div class="mt-2 w-full">
        <div class="mb-3">
          <contact-info-row
            :value="campaignMsg.description"
            icon="contact-identify"
            emoji="🪪"
            :title="$t('CAMPAIGN_MSG_DETAILS.TITLE')"
          />
          <contact-info-row
            :value="getDate(campaignMsg.campaign_start)"
            icon="calendar-clock"
            emoji="📅"
            :title="$t('CAMPAIGN_MSG_DETAILS.SCHEDULED_AT')"
          />
          <contact-info-row
            :value="getDate(campaignMsg.campaign_end)"
            icon="calendar-clock"
            emoji="📅"
            :title="$t('CAMPAIGN_MSG_DETAILS.SCHEDULED_END')"
          />
          <contact-info-row
            :value="getTimeSend"
            icon="dual-screen-clock"
            emoji="⌚"
            :title="$t('CAMPAIGN_MSG_DETAILS.TIME_SEND')"
          />
          <contact-info-row
            :value="getIntervalSend"
            icon="send-clock"
            emoji="⌚"
            :title="$t('CAMPAIGN_MSG_DETAILS.INTERVAL_SEND')"
          />
        </div>
      </div>
      <div class="flex items-center w-full mt-2 gap-2">
        <woot-button
          v-tooltip="$t('CAMPAIGN_MSG_DETAILS.ACTION.EDIT_MESSAGE')"
          title="$t('CAMPAIGN_MSG_DETAILS.ACTION.EDIT_MESSAGE')"
          icon="chat"
          size="small"
          @click="toggleConversationModal"
        />
        <woot-button
          v-tooltip="$t('CAMPAIGN_MSG_DETAILS.ACTION.EDIT')"
          title="$t('CAMPAIGN_MSG_DETAILS.ACTION.EDIT')"
          icon="edit"
          variant="smooth"
          size="small"
          @click="toggleEditModal"
        />
        <woot-button
          v-if="isAdmin"
          v-tooltip="$t('CAMPAIGN_MSG_DETAILS.ACTION.DELETE')"
          title="$t('CAMPAIGN_MSG_DETAILS.ACTION.DELETE')"
          icon="delete"
          variant="smooth"
          size="small"
          color-scheme="alert"
          :disabled="uiFlags.isDeleting"
          @click="toggleDeleteModal"
        />
      </div>
      <woot-modal :show.sync="showEditModal" :on-close="toggleEditModal">
        <add-campaign :campaign-msg="campaignMsg" @on-close="toggleEditModal" />
      </woot-modal>
      <!-- <edit-campaign
        v-if="showEditModal"
        :show="showEditModal"
        :contact="contact"
        @cancel="toggleEditModal"
      /> -->
      <!--
      <new-conversation
        v-if="contact.id"
        :show="showConversationModal"
        :contact="contact"
        @cancel="toggleConversationModal"
      />
      <contact-merge-modal
        v-if="showMergeModal"
        :primary-contact="contact"
        :show="showMergeModal"
        @close="toggleMergeModal"
      /> -->
    </div>
    <woot-delete-modal
      v-if="showDeleteModal"
      :show.sync="showDeleteModal"
      :on-close="closeDelete"
      :on-confirm="confirmDeletion"
      :title="$t('DELETE_CONTACT.CONFIRM.TITLE')"
      :message="$t('DELETE_CONTACT.CONFIRM.MESSAGE')"
      :message-value="confirmDeleteMessage"
      :confirm-text="$t('DELETE_CONTACT.CONFIRM.YES')"
      :reject-text="$t('DELETE_CONTACT.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import timeMixin from 'dashboard/mixins/time';
import ContactInfoRow from '../../conversation/contact/ContactInfoRow.vue';
import AddCampaign from './AddCampaign.vue';
import alertMixin from 'shared/mixins/alertMixin';
import adminMixin from '../../../../mixins/isAdmin';
import { mapGetters } from 'vuex';
import { getCountryFlag } from 'dashboard/helper/flag';

export default {
  components: {
    ContactInfoRow,
    AddCampaign,
  },
  mixins: [alertMixin, adminMixin, clickaway, timeMixin],
  props: {
    campaignMsg: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showEditModal: false,
      showConversationModal: false,
      showMergeModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'contacts/getUIFlags' }),
    getTimeSend() {
      return `${this.getTime(this.campaignMsg.initial_send_time)} 
      - 
      ${this.getTime(this.campaignMsg.final_send_time)}`;
    },
    getIntervalSend() {
      return `${this.campaignMsg.minimum_interval}S - ${this.campaignMsg.maximum_interval}S`;
    },
    additionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    location() {
      const {
        country = '',
        city = '',
        country_code: countryCode,
      } = this.additionalAttributes;
      const cityAndCountry = [city, country].filter(item => !!item).join(', ');

      if (!cityAndCountry) {
        return '';
      }
      return this.findCountryFlag(countryCode, cityAndCountry);
    },
    socialProfiles() {
      const {
        social_profiles: socialProfiles,
        screen_name: twitterScreenName,
      } = this.additionalAttributes;

      return { twitter: twitterScreenName, ...(socialProfiles || {}) };
    },
    // Delete Modal
    confirmDeleteMessage() {
      return ` ${this.campaignMsg.description}?`;
    },
  },
  methods: {
    getDate(date) {
      const dateTemp = new Date(date);
      return dateTemp.toLocaleDateString() + ' ' + this.getTime(date);
    },
    getTime(date) {
      let dateTemp = new Date(date);
      const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
      };
      if (dateTemp.getUTCFullYear() < 2023) {
        const currentData = new Date();
        const horary = dateTemp.toISOString().split('T')[1];
        const newDate = `${currentData.toISOString().slice(0, 10)}T${horary}`;
        const validDate = new Date(newDate);
        if (validDate instanceof Date) {
          dateTemp = validDate;
        }
      }
      return dateTemp.toLocaleTimeString(undefined, optionsTime);
    },
    toggleMergeModal() {
      this.showMergeModal = !this.showMergeModal;
    },
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    onPanelToggle() {
      this.$emit('toggle-panel');
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    confirmDeletion() {
      this.deleteContact(this.campaignMsg);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteModal = false;
      this.showConversationModal = false;
      this.showEditModal = false;
    },
    findCountryFlag(countryCode, cityAndCountry) {
      try {
        const countryFlag = countryCode ? getCountryFlag(countryCode) : '🌎';
        return `${cityAndCountry} ${countryFlag}`;
      } catch (error) {
        return '';
      }
    },
    async deleteContact({ id }) {
      try {
        await this.$store.dispatch('campaignsMessages/delete', id);
        // this.$emit('panel-close');
        this.showAlert(this.$t('CAMPAIGN_MSG.DELETE.API.SUCCESS_MESSAGE'));

        // if (isAConversationRoute(this.$route.name)) {
        //   this.$router.push({
        //     name: getConversationDashboardRoute(this.$route.name),
        //   });
        // } else if (this.$route.name !== 'contacts_dashboard') {
        //   this.$router.push({
        //     name: 'contacts_dashboard',
        //   });
        // }
        this.$router.push({
          name: 'campaigns_messages',
        });
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('CAMPAIGN_MSG.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
    openMergeModal() {
      this.toggleMergeModal();
    },
  },
};
</script>
