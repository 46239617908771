<template>
  <div class="flex flex-row gap-4 pl-2">
    <div class="w-[76%]">
      <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
        />
      </woot-tabs>

      <div class="w-full">
        <p
          v-if="!uiFlags.isFetching && !attributes.length"
          class="mt-12 flex items-center justify-center"
        >
          {{ $t('ATTRIBUTES_MGMT.LIST.EMPTY_RESULT.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ATTRIBUTES_MGMT.LOADING')"
        />
        <table
          v-if="!uiFlags.isFetching && attributes.length"
          class="w-full mt-2 table-fixed woot-table"
        >
          <thead>
            <th
              v-for="(tableHeader, index) in $t(
                'ATTRIBUTES_MGMT.LIST.TABLE_HEADER'
              )"
              :key="tableHeader"
              class="pl-0 max-w-[6.25rem] min-w-[2rem]"
              :class="index === 1 && 'w-4/12'"
            >
              {{ tableHeader }}
            </th>
            <th class="pl-0 max-w-[4.25rem] min-w-[2rem]">&nbsp;</th>
          </thead>
          <tbody>
            <tr v-for="attribute in attributes" :key="attribute.attribute_key">
              <td
                class="pl-0 max-w-[6.25rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{ attribute.attribute_display_name }}
              </td>
              <td
                class="w-full pl-0 max-w-[20rem] min-w-[7.25rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{ attribute.attribute_description }}
              </td>
              <td
                class="pl-0 max-w-[4.25rem] min-w-[2rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{ attribute.attribute_display_type }}
              </td>
              <!-- <td
                class="pl-0 max-w-[4rem] min-w-[4rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{
                  attribute.attribute_required
                    ? $t('ATTRIBUTES_MGMT.LIST.REQUIRED.TRUE')
                    : $t('ATTRIBUTES_MGMT.LIST.REQUIRED.FALSE')
                }}
              </td> -->
              <td
                class="pl-0 max-w-[6.25rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{ attribute.attribute_key }}
              </td>
              <td
                class="pl-0 max-w-[6.25rem] min-w-[5rem] overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{
                  $t(
                    'ATTRIBUTES_MGMT.OPTIONS.WHO_MANAGE.' +
                      attribute.attribute_who_can_manage.toUpperCase()
                  )
                }}
              </td>
              <td class="button-wrapper">
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.EDIT')"
                  variant="smooth"
                  size="tiny"
                  color-scheme="secondary"
                  class-names="grey-btn"
                  icon="edit"
                  @click="openEditPopup(attribute)"
                />
                <woot-button
                  v-tooltip.top="$t('ATTRIBUTES_MGMT.LIST.BUTTONS.DELETE')"
                  variant="smooth"
                  color-scheme="alert"
                  size="tiny"
                  icon="dismiss-circle"
                  class-names="grey-btn"
                  @click="openDelete(attribute)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="w-[24%]">
      <span v-dompurify-html="$t('ATTRIBUTES_MGMT.SIDEBAR_TXT')" />
    </div>
    <!-- <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-attribute
        :selected-attribute="selectedAttribute"
        :is-updating="uiFlags.isUpdating"
        @on-close="hideEditPopup"
      />
    </woot-modal> -->
    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <add-attribute
        :on-close="hideEditPopup"
        :selected-attribute="selectedAttribute"
        :is-updating="uiFlags.isUpdating"
      />
    </woot-modal>
    <woot-confirm-delete-modal
      v-if="showDeletePopup"
      :show.sync="showDeletePopup"
      :title="confirmDeleteTitle"
      :message="$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
      :confirm-value="selectedAttribute.attribute_display_name"
      :confirm-place-holder-text="confirmPlaceHolderText"
      @on-confirm="confirmDeletion"
      @on-close="closeDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
// import EditAttribute from './EditAttribute.vue';
import { ATTRIBUTE_MODELS } from './constants';
import AddAttribute from './AddAttribute.vue';

export default {
  components: {
    // EditAttribute,
    AddAttribute,
  },
  mixins: [alertMixin],
  data() {
    return {
      selectedTabIndex: 0,
      showEditPopup: false,
      showDeletePopup: false,
      selectedAttribute: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'attributes/getUIFlags',
    }),
    attributes() {
      // const attributeModel = this.selectedTabIndex
      //   ? 'contact_attribute'
      //   : 'conversation_attribute';
      const attributeModel = ATTRIBUTE_MODELS[this.selectedTabIndex].code;

      return this.$store.getters['attributes/getAttributesByModel'](
        attributeModel
      );
    },
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONVERSATION'),
        },
        {
          key: 1,
          name: this.$t('ATTRIBUTES_MGMT.TABS.CONTACT'),
        },
        {
          key: 2,
          name: this.$t('ATTRIBUTES_MGMT.TABS.PROFESSIONAL'),
        },
      ];
    },
    deleteConfirmText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.YES')} ${
        this.selectedAttribute.attribute_display_name
      }`;
    },
    deleteRejectText() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.NO');
    },
    confirmDeleteTitle() {
      return this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.TITLE', {
        attributeName: this.selectedAttribute.attribute_display_name,
      });
    },
    confirmPlaceHolderText() {
      return `${this.$t('ATTRIBUTES_MGMT.DELETE.CONFIRM.PLACE_HOLDER', {
        attributeName: this.selectedAttribute.attribute_display_name,
      })}`;
    },
  },
  mounted() {
    this.fetchAttributes(this.selectedTabIndex);
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
      this.fetchAttributes(index);
      this.$emit('change-tab', index);
    },
    fetchAttributes(index) {
      this.$store.dispatch('attributes/get', index);
    },
    async deleteAttributes({ id }) {
      try {
        await this.$store.dispatch('attributes/delete', id);
        this.showAlert(this.$t('ATTRIBUTES_MGMT.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('ATTRIBUTES_MGMT.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    openEditPopup(response) {
      this.showEditPopup = true;
      this.selectedAttribute = response;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    confirmDeletion() {
      this.deleteAttributes(this.selectedAttribute);
      this.closeDelete();
    },
    openDelete(value) {
      this.showDeletePopup = true;
      this.selectedAttribute = value;
    },
    closeDelete() {
      this.showDeletePopup = false;
      this.selectedAttribute = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute-key {
  font-family: monospace;
}

::v-deep {
  .tabs--container {
    .tabs {
      @apply p-0;
    }
  }
  .tabs-title a {
    font-weight: var(--font-weight-medium);
    padding-top: 0;
  }
}
</style>
