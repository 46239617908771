<template>
  <div
    class="w-full bg-slate-100 rounded-full h-2.5 mb-2 dark:bg-gray-700"
    :class="classes"
  >
    <div
      v-if="!indeterminate"
      class="bg-woot-500 h-2.5 rounded-full dark:bg-blue-500"
      :style="{ width: progressBarWidth + '%' }"
    />
    <div v-else class="h-2.5 w-full bg-woot-100 overflow-hidden rounded-full">
      <div class="progress w-full h-full bg-woot-500 left-right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeLeft: { type: Number, default: 0 },
    totalTime: { type: Number, default: 0 },
    indeterminate: { type: Boolean, default: false },
    classes: { type: String, default: '' },
  },
  computed: {
    progressBarWidth() {
      return (this.timeLeft / this.totalTime) * 100;
    },
  },
};
</script>

<style>
.progress {
  animation: progress 1s infinite linear;
}

.left-right {
  transform-origin: 0% 50%;
}
@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
</style>
