<template>
  <div
    class="flex justify-between flex-col h-full m-0 flex-1 bg-white dark:bg-slate-900"
  >
    <div v-if="!uiFlags.isFetching && campaign">
      <settings-header
        button-route="new"
        :header-title="campaign.description"
        show-back-button
        :back-button-label="$t('CAMPAIGN_MSG_DETAILS.BACK_BUTTON')"
        :back-url="backUrl"
        :show-new-button="false"
      />
    </div>
    <div
      v-if="uiFlags.isFetchingContacts && !campaign"
      class="text-center p-4 text-base h-full"
    >
      <spinner size="" />
      <span>{{ $t('CAMPAIGN_MSG_DETAILS.LOADING') }}</span>
    </div>
    <div
      v-else-if="campaign && campaign.id"
      class="overflow-hidden flex-1 min-w-0"
    >
      <div class="flex flex-wrap ml-auto mr-auto max-w-full h-full">
        <campaign-info-panel
          :show-close-button="false"
          :show-avatar="false"
          :campaign-msg="campaign"
        />
        <div class="w-[75%] h-full">
          <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
            <woot-tabs-item
              v-for="tab in tabs"
              :key="tab.key"
              :name="tab.name"
              :show-badge="false"
            />
          </woot-tabs>
          <div
            class="bg-slate-25 dark:bg-slate-800 h-[calc(100%-40px)] p-4 overflow-auto"
          >
            <div v-if="selectedTabIndex === 0">
              <table-campaign-contacts
                :data-contacts="getListContacts ? getListContacts : []"
              />
            </div>
            <!-- <contact-notes
              v-if="selectedTabIndex === 0"
              :contact-id="Number(contactId)"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else-if="contact.id" class="overflow-hidden flex-1 min-w-0">
      <div class="flex flex-wrap ml-auto mr-auto max-w-full h-full">
        <campaign-info-panel
          :show-close-button="false"
          :show-avatar="false"
          :campaign-msg="campaign"
        />
        <div class="w-[75%] h-full">
          <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
            <woot-tabs-item
              v-for="tab in tabs"
              :key="tab.key"
              :name="tab.name"
              :show-badge="false"
            />
          </woot-tabs>
          <div
            class="bg-slate-25 dark:bg-slate-800 h-[calc(100%-40px)] p-4 overflow-auto"
          >
            <contact-notes
              v-if="selectedTabIndex === 0"
              :contact-id="Number(contactId)"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CampaignInfoPanel from '../components/CampaignInfoPanel.vue';
import TableCampaignContacts from '../components/TableCampaignContacts.vue';
// import ContactNotes from 'dashboard/modules/notes/NotesOnContactPage';
import Spinner from 'shared/components/Spinner';
import SettingsHeader from '../../settings/SettingsHeader.vue';

export default {
  components: {
    CampaignInfoPanel,
    TableCampaignContacts,
    // ContactNotes,
    Spinner,
    SettingsHeader,
  },
  props: {
    campaignMsgId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaignsMessages/getUIFlags',
    }),
    getListContacts() {
      return this.$store.getters['campaignsMessages/getContacts'](
        this.campaignMsgId
      );
    },
    getListInboxes() {
      return this.$store.getters['campaignsMessages/getInboxes'](
        this.campaignMsgId
      );
    },
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('CAMPAIGN_MSG_DETAILS.LIST.CONTACTS'),
        },
      ];
    },
    campaign() {
      // const campaign =
      return this.$store.getters['campaignsMessages/getCampaign'](
        this.campaignMsgId
      );
      // return campaign;
    },
    backUrl() {
      return `/app/accounts/${this.$route.params.accountId}/campaigns_messages`;
    },
  },
  mounted() {
    this.$store.dispatch('campaignsMessages/get');
    this.$store.dispatch('campaignsMessages/getContacts', this.campaignMsgId);
    this.$store.dispatch('campaignsMessages/getInboxes', this.campaignMsgId);
  },
  methods: {
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
  },
};
</script>
