<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="title" />
    <woot-tabs :index="selectedTabIndex" @change="onClickTabChange">
      <woot-tabs-item
        v-for="tab in tabs"
        :key="tab.key"
        :name="tab.name"
        :show-badge="false"
      />
    </woot-tabs>
    <form
      class="flex flex-col w-full pt-1"
      style="padding-bottom: 1rem; padding-top: 0.5rem"
      @submit.prevent="sendProfessional"
    >
      <div v-if="selectedTabIndex === 0" class="w-full">
        <div class="div-form">
          <woot-input
            v-model="name"
            :label="$t('PROFESSIONAL.ADD.FORM.NAME.LABEL')"
            type="text"
            class="w-full"
            :class="{ error: $v.name.$error }"
            :error="
              $v.name.$error ? $t('PROFESSIONAL.ADD.FORM.NAME.ERROR') : ''
            "
            :placeholder="$t('PROFESSIONAL.ADD.FORM.NAME.PLACEHOLDER')"
            @blur="$v.name.$touch"
          />
        </div>
        <div class="div-form items-center">
          <div
            class="mt-1 multiselect-wrap--medium w-full"
            :class="{ error: $v.userId.$error }"
          >
            <label class="multiselect__label">
              {{ $t('PROFESSIONAL.ADD.FORM.USER.LABEL') }}
            </label>
            <multiselect
              v-model="user"
              track-by="id"
              label="name"
              class="select-min"
              :placeholder="$t('PROFESSIONAL.ADD.FORM.USER.PLACEHOLDER')"
              selected-label
              :select-label="
                $t('PROFESSIONAL.ADD.FORM.USER.SELECT_PLACEHOLDER')
              "
              :deselect-label="$t('PROFESSIONAL.ADD.FORM.USER.REMOVE')"
              :max-height="160"
              :options="agentList"
              :allow-empty="true"
              :class="{ error: $v.userId.$error }"
            >
              <!-- :custom-label="countryNameWithCode"
              :option-height="104" -->
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
          </div>

          <woot-input
            v-model="email"
            :label="$t('PROFESSIONAL.ADD.FORM.EMAIL.LABEL')"
            type="text"
            class="w-full"
            :class="{ error: $v.email.$error }"
            :error="msgErrorEmail"
            :placeholder="$t('PROFESSIONAL.ADD.FORM.EMAIL.PLACEHOLDER')"
            @blur="$v.email.$touch"
          />
          <!-- 
            <div>
            <span class="text-sm whitespace-nowrap mx-2">
              {{ $t('PROFESSIONAL.ADD.FORM.ACTIVE') }}
            </span>
            <span>
              <woot-switch v-model="active" />
            </span>
          </div> 
          -->
        </div>
        <div class="div-form">
          <div class="w-full">
            <label
              :class="{
                error: isPhoneNumberNotValid,
              }"
            >
              {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
              <woot-phone-input
                v-model="phoneNumber"
                :value="phoneNumber"
                :error="isPhoneNumberNotValid"
                :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
                @input="onPhoneNumberInputChange"
                @blur="$v.phoneNumber.$touch"
                @setCode="setPhoneCode"
              />
              <span v-if="isPhoneNumberNotValid" class="message">
                {{ phoneNumberError }}
              </span>
            </label>
            <!-- <div
              v-if="isPhoneNumberNotValid || !phoneNumber"
              class="callout small warning text-sm dark:bg-yellow-200/20 dark:text-yellow-400"
            >
              {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.HELP') }}
            </div> -->
          </div>
          <div class="w-full">
            <label
              :class="{
                error: isPhoneNumberNotValid2,
              }"
            >
              {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }} 2
              <woot-phone-input
                v-model="phoneNumber2"
                :value="phoneNumber2"
                :error="isPhoneNumberNotValid2"
                :placeholder="$t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')"
                @input="onPhoneNumberInputChange2"
                @blur="$v.phoneNumber2.$touch"
                @setCode="setPhoneCode"
              />
              <span v-if="isPhoneNumberNotValid2" class="message">
                {{ phoneNumberError }}
              </span>
            </label>
          </div>
        </div>
        <div class="div-form">
          <woot-input
            v-model="addressZipCode"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_ZIP_CODE.LABEL')"
            type="text"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_ZIP_CODE.PLACEHOLDER')
            "
          />
          <woot-input
            v-model="addressState"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_ESTATE.LABEL')"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_ESTATE.PLACEHOLDER')
            "
            type="text"
          />
          <woot-input
            v-model="addressCity"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_CITY.LABEL')"
            type="text"
            :placeholder="$t('PROFESSIONAL.ADD.FORM.ADDRESS_CITY.PLACEHOLDER')"
          />
        </div>
        <div class="div-form">
          <woot-input
            v-model="addressNeighborhood"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_NEIGHBORHOOD.LABEL')"
            type="text"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_NEIGHBORHOOD.PLACEHOLDER')
            "
          />
          <woot-input
            v-model="addressStreet"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_STREET.LABEL')"
            type="text"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_STREET.PLACEHOLDER')
            "
          />
          <woot-input
            v-model="addressNumber"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_NUMBER.LABEL')"
            type="text"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_NUMBER.PLACEHOLDER')
            "
          />
        </div>
        <div class="div-form">
          <woot-input
            v-model="addressComplement"
            :label="$t('PROFESSIONAL.ADD.FORM.ADDRESS_COMPLEMENT.LABEL')"
            type="text"
            class="w-full"
            :placeholder="
              $t('PROFESSIONAL.ADD.FORM.ADDRESS_COMPLEMENT.PLACEHOLDER')
            "
          />
          <!-- <woot-input
            v-model="observation"
            :label="$t('PROFESSIONAL.ADD.FORM.OBSERVATION.LABEL')"
            type="text"
            class="w-full"
            :placeholder="$t('PROFESSIONAL.ADD.FORM.OBSERVATION.PLACEHOLDER')"
          /> -->
        </div>
        <div class="div-form">
          <woot-input
            v-model="observation"
            :label="$t('PROFESSIONAL.ADD.FORM.OBSERVATION.LABEL')"
            type="text"
            class="w-full"
            :placeholder="$t('PROFESSIONAL.ADD.FORM.OBSERVATION.PLACEHOLDER')"
          />
        </div>
      </div>
      <div v-if="selectedTabIndex === 1" class="w-full">
        <custom-attributes-form
          attribute-type="professional_attribute"
          attribute-class="conversation--attribute"
          :is-edit="isEditing"
          :custom-attributes="customAttributes"
          :active-valid-form="activeValidFormAttribute"
          :user="currentUser"
          @update="onUpdate"
          @formValid="formAttributeValidated"
        />
      </div>
      <div class="div-form justify-end py-2 px-0 w-full">
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('PROFESSIONAL.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
        <woot-button :is-loading="uiFlags.isCreating">
          {{ textBtnSend }}
        </woot-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, email as emailValidator } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { isPhoneNumberValid } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';
// import attributeMixin from 'dashboard/mixins/attributeMixin';
import CustomAttributesForm from 'dashboard/components/CustomAttributesForm.vue';

export default {
  components: {
    CustomAttributesForm,
  },
  mixins: [alertMixin], //  , attributeMixin
  props: {
    title: {
      type: String,
      default: '',
    },
    professional: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedTabIndex: 0,
      userId: '',
      name: '',
      email: '',
      activeDialCode: '',
      phoneNumber: '',
      activeDialCode2: '',
      phoneNumber2: '',
      addressZipCode: '',
      addressState: '',
      addressCity: '',
      addressNeighborhood: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      observation: '',
      active: true,
      user: {},
      attributeType: 'professional_attribute',
      customAttributes: {},
      isFormAttributeValid: false,
      activeValidFormAttribute: false,
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      email: {
        emailValidator,
      },
      phoneNumber: {
        required,
      },
      phoneNumber2: {},
      // phone1: {
      //   required,
      // },
      user: {
        required,
      },
      userId: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'professionals/getUIFlags',
      agentList: 'agents/getAgents',
      accountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
    },
    tabs() {
      return [
        {
          key: 0,
          name: this.$t('PROFESSIONAL.ADD.TABS.GENERAL'),
        },
        {
          key: 1,
          name: this.$t('PROFESSIONAL.ADD.TABS.ATTRIBUTES'),
        },
      ];
    },
    msgErrorEmail() {
      if (this.$v.email.$error) {
        return this.email !== ''
          ? this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR')
          : this.$t('PROFESSIONAL.ADD.FORM.EMAIL.ERROR');
      }
      return '';
    },
    phoneNumberError() {
      if (this.activeDialCode === '') {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DIAL_CODE_ERROR');
      }
      if (!isPhoneNumberValid(this.phoneNumber, this.activeDialCode)) {
        return this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR');
      }
      return '';
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.phoneNumber);
    },
    parsePhoneNumber2() {
      return parsePhoneNumber(this.phoneNumber2);
    },
    isPhoneNumberNotValid() {
      if (this.$v.phoneNumber.$error) return true;
      if (this.phoneNumber !== '') {
        if (
          this.isEditing &&
          this.activeDialCode === '' &&
          this.phoneNumber.includes('+')
        ) {
          return false;
        }
        return (
          !isPhoneNumberValid(this.phoneNumber, this.activeDialCode) ||
          (this.phoneNumber !== '' ? this.activeDialCode === '' : false)
        );
      }
      return false;
    },
    isPhoneNumberNotValid2() {
      if (this.phoneNumber2 !== '' && this.phoneNumber2 !== null) {
        if (
          this.isEditing &&
          this.activeDialCode2 === '' &&
          this.phoneNumber2.includes('+')
        ) {
          return false;
        }
        return (
          !isPhoneNumberValid(this.phoneNumber2, this.activeDialCode2) ||
          (this.phoneNumber2 !== '' ? this.activeDialCode2 === '' : false)
        );
      }
      return false;
    },
    isEditing() {
      return !!(this.professional && this.professional.id);
    },
    textBtnSend() {
      const label = this.isEditing
        ? 'PROFESSIONAL.EDIT.UPDATE_BUTTON_TEXT'
        : 'PROFESSIONAL.ADD.CREATE_BUTTON_TEXT';
      return this.$t(label);
    },
  },
  watch: {
    user() {
      if (this.user && this.user.id) {
        this.userId = this.user.id;
        this.email = this.user.email;
      }
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
    this.setProfessional();
  },
  methods: {
    formAttributeValidated(isValid) {
      // console.log('isValid ', isValid);
      this.isFormAttributeValid = isValid;
    },
    onClickTabChange(index) {
      this.selectedTabIndex = index;
    },
    onUpdate(data) {
      this.customAttributes = data;
    },
    setPhoneCode(code) {
      if (this.phoneNumber !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.phoneNumber.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    setPhoneCode2(code) {
      if (this.phoneNumber2 !== '' && this.parsePhoneNumber2) {
        const dialCode2 = this.parsePhoneNumber2.countryCallingCode;
        if (dialCode2 === code) {
          return;
        }
        this.activeDialCode = `+${dialCode2}`;
        const newPhoneNumber = this.phoneNumber2.replace(
          `+${dialCode2}`,
          `${code}`
        );
        this.phoneNumber = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    onPhoneNumberInputChange2(value, code) {
      this.activeDialCode2 = code;
    },
    setProfessional() {
      if (this.isEditing) {
        const {
          // id,
          // account_id,
          user_id,
          user,
          name,
          email,
          phone_number_1,
          phone_number_2,
          address_zip_code,
          address_state,
          address_city,
          address_neighborhood,
          address_street,
          address_number,
          address_complement,
          observation,
          active,
          custom_attributes,
        } = this.professional;

        this.user = user; // this.agentList.find(user => user.id === user_id);

        this.userId = user_id;
        this.name = name;
        this.email = email;
        this.phoneNumber = phone_number_1;
        this.phoneNumber2 = phone_number_2;
        this.addressZipCode = address_zip_code;
        this.addressState = address_state;
        this.addressCity = address_city;
        this.addressNeighborhood = address_neighborhood;
        this.addressStreet = address_street;
        this.addressNumber = address_number;
        this.addressComplement = address_complement;
        this.observation = observation;
        this.active = active;
        this.customAttributes = custom_attributes;
      }
    },
    onClose() {
      this.$emit('on-close');
    },
    getProfessionalDetails() {
      let professionalDetails = null;
      const {
        accountId: account_id,
        userId: user_id,
        name,
        email,
        activeDialCode,
        activeDialCode2,
        phoneNumber,
        phoneNumber2,
        addressZipCode: address_zip_code,
        addressState: address_state,
        addressCity: address_city,
        addressNeighborhood: address_neighborhood,
        addressStreet: address_street,
        addressNumber: address_number,
        addressComplement: address_complement,
        observation,
        active,
      } = this;
      const phone_number_1 = activeDialCode + phoneNumber;
      let phone_number_2 = activeDialCode2 + phoneNumber2;
      if (phoneNumber2 === null) {
        phone_number_2 = phoneNumber2;
      }
      professionalDetails = {
        account_id,
        user_id,
        name,
        email,
        phone_number_1,
        phone_number_2,
        address_zip_code,
        address_state,
        address_city,
        address_neighborhood,
        address_street,
        address_number,
        address_complement,
        observation,
        active,
        custom_attributes: this.customAttributes,
      };
      if (this.isEditing) {
        const { id } = this.professional;
        professionalDetails.id = id;
        // professionalDetails.account_id = account_id;
      }
      return professionalDetails;
    },
    async sendProfessional() {
      this.$v.$touch();
      // created for attribute required in custom attributes
      // this.activeValidFormAttribute = !this.activeValidFormAttribute;
      // console.log('this.isFormAttributeValid ', this.isFormAttributeValid);
      // if (!this.isFormAttributeValid) {
      //   this.showAlert(
      //     this.$t('PROFESSIONAL.ADD.FORM.ATTRIBUTES.FIELDS_NOT_FILLED')
      //   );
      //   this.activeValidFormAttribute = true;
      //   return;
      // }
      if (this.$v.$invalid) {
        return;
      }
      try {
        const professionalDetails = this.getProfessionalDetails();
        // console.log('data send professional ', professionalDetails);
        if (this.isEditing) {
          await this.$store.dispatch(
            'professionals/update',
            professionalDetails
          );
          this.showAlert(this.$t('PROFESSIONAL.EDIT.API.SUCCESS_MESSAGE'));
        } else {
          // console.log('professionalDetails ', professionalDetails);
          await this.$store.dispatch(
            'professionals/create',
            professionalDetails
          );
          this.showAlert(this.$t('PROFESSIONAL.ADD.API.SUCCESS_MESSAGE'));
        }
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('PROFESSIONAL.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>

<style lang="scss">
.div-form {
  @apply flex flex-row gap-2;
}

.multiselect-wrap--medium .multiselect__tags,
.multiselect-wrap--medium .multiselect__input,
.multiselect-wrap--medium .multiselect {
  height: 2.37rem !important;
  min-height: 2.375rem !important;
}
.select-min {
  height: 2.37rem !important;
  min-height: 2.375rem !important;
}
.select-min .multiselect__tags {
  min-height: 2.375rem;
}
.multiselect-wrap--medium .multiselect__select {
  min-height: 2.37rem;
}
.select-min .multiselect__select {
  min-height: 2.37rem;
}
</style>
