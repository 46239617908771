<template>
  <div
    class="flex justify-between flex-col h-full m-0 flex-1 bg-white dark:bg-slate-900"
  >
    <settings-header
      button-route="new"
      :header-title="title"
      show-back-button
      :back-button-label="''"
      :back-url="''"
      :show-new-button="false"
    >
      <thumbnail
        v-if="contact.thumbnail"
        :src="contact.thumbnail"
        :username="contact.name"
        size="32px"
        class="mr-2 rtl:mr-0 rtl:ml-2"
      />
    </settings-header>

    <div v-if="uiFlags.isFetching" class="text-center p-4 text-base h-full">
      <spinner size="" />
      <span>{{ $t('HISTORIC.LOADING') }}</span>
    </div>
    <div v-else-if="contact.id" class="overflow-hidden flex-1 min-w-0">
      <div class="flex flex-wrap ml-auto mr-auto max-w-full h-full">
        <!-- <div>
          <contact-info
            :show-close-button="false"
            :show-avatar="true"
            :contact="contact"
            close-icon-name="dismiss"
            @panel-close="() => {}"
            @toggle-panel="() => {}"
          />
        </div>
        <div class="w-[75%] h-full">
          <historic-content :contact-id="contactId" />
        </div> -->
        <div class="w-full h-full">
          <historic-content :contact-id="contactId" :histories="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import SettingsHeader from '../../settings/SettingsHeader.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
// import ContactInfo from 'dashboard/routes/dashboard/conversation/contact/ContactInfo.vue';
import HistoricContent from './components/historicContent.vue';
export default {
  components: {
    // ContactInfo,
    // ContactNotes,
    SettingsHeader,
    Spinner,
    Thumbnail,
    HistoricContent,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
    status: {
      type: String,
      default: '',
    },
    visibility: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      uiFlags: 'histories/getUIFlags',
      histories: 'histories/getHistories',
    }),
    isAllData() {
      return this.$route.fullPath && this.$route.fullPath.includes('all');
    },
    data() {
      let result = this.histories;
      // if (this.status !== '' || this.visibility !== '')
      //   result = this.getFilterData();
      if (this.status !== '') result = this.getFilterData();
      return result;
    },
    showEmptySearchResult() {
      const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
      return hasEmptyResults;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    // backUrl() {
    //   //return `/app/accounts/${this.$route.params.accountId}/schedules`;
    //   this.$router.back();
    // },
    title() {
      return `${this.$t('HISTORIC.TITLE')} ${
        this.contact && this.contact.name ? `- ${this.contact.name}` : ''
      }`;
    },
  },
  watch: {
    visibility() {
      if (this.visibility !== '') {
        this.$store.dispatch('histories/getFilter', {
          contact_id: this.contactId,
          visibility: this.visibility,
        });
      }
    },
    isAllData() {
      if (this.isAllData) {
        this.$store.dispatch('histories/getFilter', {
          contact_id: this.contactId,
        });
      }
    },
  },
  mounted() {
    this.fetchContactDetails();
    this.$store.dispatch('histories/getFilter', {
      contact_id: this.contactId,
    });
    this.$store.dispatch('agents/get');
  },
  methods: {
    fetchContactDetails() {
      const { contactId: id } = this;
      this.$store.dispatch('contacts/show', { id });
    },
    getFilterData() {
      const dataTemp = this.histories;
      const notSchedule = this.status && this.status === 'NOT_SCHEDULE';
      if (notSchedule) {
        return dataTemp.filter(item => {
          if (!item.schedule_id) return item;
          return null;
        });
      }
      return dataTemp.filter(item => {
        if (item.schedule && item.schedule.status === this.status) return item;
        // if (item.visibility === this.visibility) return item;
        return null;
      });
    },
  },
};
</script>
