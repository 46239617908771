<template>
  <div>
    <div
      v-for="(value, key) in object"
      :key="key"
      :style="{ marginLeft: level * 20 + 'px' }"
    >
      <span :class="getChangeClass(key, 'key')"> {{ key }}: </span>
      <span
        v-if="!isObject(value)"
        class="text-wrap"
        :class="getChangeClass(key, 'value')"
      >
        {{ value }}
      </span>
      <div v-if="isObject(value)">
        <property-viewer
          :object="value"
          :changes="filterChanges(key)"
          :type="type"
          :level="level + 1"
          :key-father="getKeyFather(key)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertyViewer',
  props: {
    object: {
      type: Object,
      required: true,
    },
    changes: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 0,
    },
    keyFather: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    notCompare() {
      return this.changes === null;
    },
  },
  mounted() {},
  methods: {
    getKeyFather(key) {
      return [...this.keyFather, key];
    },
    // getInfoHelp(key) {
    //   // a = [set, msg]
    //   console.log('key ', key)
    //   let dir = 'EVO_API'
    //   if (this.keyFather.length > 0) {
    //     this.keyFather.map( item =>  dir = `${dir}.${item}` )
    //     return this.$t(`${dir}.${key}`)
    //   }
    //   return this.$t(`${dir}.${key}`)
    // },
    // getIcon(value) {
    //   if (Array.isArray(value)) {
    //     return 'fa fa-list'; // Ícone para arrays
    //   } else if (typeof value === 'object') {
    //     return 'fa fa-folder'; // Ícone para objetos
    //   } else if (typeof value === 'string') {
    //     return 'fa fa-font'; // Ícone para strings
    //   } else if (typeof value === 'number') {
    //     return 'fa fa-hashtag'; // Ícone para números
    //   } else if (typeof value === 'boolean') {
    //     return 'fa fa-check-circle'; // Ícone para booleanos
    //   } else {
    //     return 'fa fa-question-circle'; // Ícone para tipos desconhecidos
    //   }
    // },
    isObject(value) {
      return (
        value !== null && typeof value === 'object' && !Array.isArray(value)
      );
    },
    // getChangeClass(key, type) {
    //   if (this.changes[key]) {
    //     if ( type === 'value' ) {
    //       return this.type === 'old' ? 'changed-old' : 'changed-new'
    //     } else {
    //       return 'changed'
    //     }

    //     // return type === 'value' ? (this.type === 'old' ? 'changed-old' : 'changed-new') : 'changed';
    //   }
    //   return '';
    // },
    getChangeClass(key, type) {
      if (this.notCompare) return 'changed';
      if (this.changes && this.changes[key]) {
        if (type === 'value') {
          return this.type === 'old' ? 'changed-old' : 'changed-new';
        }
        return 'changed';

        // return type === 'value' ? (this.type === 'old' ? 'changed-old' : 'changed-new') : 'changed';
      }
      return '';
    },
    filterChanges(key) {
      if (this.changes) {
        let filteredChanges = {};
        const path =
          this.level === 0 ? key : `${this.$props.changes.key}.${key}`;
        Object.entries(this.changes).forEach(([changeKey, changeValue]) => {
          if (changeKey.startsWith(`${path}`)) {
            filteredChanges = changeValue;
          }
        });
        return filteredChanges;
      }
      return null;
    },

    // filterChanges(key) {
    //   // if (this.level === 0) return this.changes
    //   // return this.changes
    //   let filteredChanges = {};
    //   const path = this.level === 0 ? key : `${this.$props.changes.key}.${key}`;

    //   // console.log('   ----------------------------------------------   ')
    //   // console.log('level, ', this.level)

    //   // console.log('filterChanges path ', path)
    //   for ( const changeKey in this.changes) {

    //     if (changeKey.startsWith(`${path}`)) {

    //       // filteredChanges[changeKey] = this.changes[changeKey];
    //       filteredChanges = {...this.changes[changeKey]};
    //     }
    //   }
    //   return filteredChanges;
    // }
  },
};
</script>

<style scoped>
.fa {
  margin-right: 5px;
}
.changed {
  font-weight: bold;
}
.changed-old {
  color: red;
}
.changed-new {
  color: green;
}
</style>
