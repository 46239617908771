<!-- Deprecated in favour of separate files for SMS and Whatsapp and also to implement new providers for each platform in the future-->
<template>
  <form class="row" @submit.prevent="createChannel()">
    <div class="medium-8 columns">
      <label :class="{ error: $v.channelName.$error }">
        {{ $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.LABEL') }}
        <input
          v-model.trim="channelName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.PLACEHOLDER')"
          @blur="$v.channelName.$touch"
        />
        <span v-if="$v.channelName.$error" class="message">{{
          $t('INBOX_MGMT.ADD.GUPSHUP.CHANNEL_NAME.ERROR')
        }}</span>
      </label>
    </div>

    <div v-if="!useMessagingService" class="medium-8 columns">
      <label :class="{ error: $v.phoneNumber.$error }">
        {{ $t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="message">{{
          $t('INBOX_MGMT.ADD.GUPSHUP.PHONE_NUMBER.ERROR')
        }}</span>
      </label>
    </div>

    <div class="medium-8 columns">
      <label :class="{ error: $v.srcName.$error }">
        {{ $t('INBOX_MGMT.ADD.GUPSHUP.SRC_NAME.LABEL') }}
        <input
          v-model.trim="srcName"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.SRC_NAME.PLACEHOLDER')"
          @blur="$v.srcName.$touch"
        />
        <span v-if="$v.srcName.$error" class="message">{{
          $t('INBOX_MGMT.ADD.GUPSHUP.SRC_NAME.ERROR')
        }}</span>
      </label>
    </div>

    <div class="medium-8 columns">
      <label :class="{ error: $v.apiKey.$error }">
        {{ $t('INBOX_MGMT.ADD.GUPSHUP.API_KEY.LABEL') }}
        <input
          v-model.trim="apiKey"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.GUPSHUP.API_KEY.PLACEHOLDER')"
          @blur="$v.apiKey.$touch"
        />
        <span v-if="$v.apiKey.$error" class="message">{{
          $t('INBOX_MGMT.ADD.GUPSHUP.API_KEY.ERROR')
        }}</span>
      </label>
    </div>

    <div class="medium-12 columns">
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.GUPSHUP.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';

export default {
  mixins: [alertMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      srcName: '',
      apiKey: '',
      channelName: '',
      phoneNumber: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations() {
    if (this.phoneNumber) {
      return {
        channelName: { required },
        phoneNumber: { required, isPhoneE164OrEmpty },
        apiKey: { required },
        srcName: { required },
      };
    }
    return {
      channelName: { required },
      phoneNumber: {},
      apiKey: { required },
      srcName: { required },
    };
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const gupshupChannel = await this.$store.dispatch(
          'inboxes/createGupshupChannel',
          {
            gupshup_channel: {
              name: this.channelName,
              src_name: this.srcName,
              api_key: this.apiKey,
              phone_number: `+${this.phoneNumber.replace(/\D/g, '')}`,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: gupshupChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.GUPSHUP.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.messagingServiceHelptext {
  margin-top: -10px;
  margin-bottom: 15px;

  .checkbox {
    margin: 0px 4px;
  }
}
</style>
