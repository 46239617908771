<template>
  <div class="w-full flex flex-row">
    <!-- <div
    class="flex flex-1 h-full justify-between flex-col m-0 bg-slate-25 dark:bg-slate-900"
  > -->
    <!-- <keep-alive v-if="keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else /> -->
    <router-view />
    <!-- <div class="flex flex-col h-full" :class="wrapClass">
      <header-schedule
        :search-query="searchQuery"
        :header-title="pageTitle"
        this-selected-contact-id=""
        @on-open-add-popup="toggleSelectPopup"
      />
      <keep-alive v-if="keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign :channel="selectedChanel" @on-close="hideAddPopup" />
    </woot-modal>
    <woot-modal :show.sync="showAddSelectPopup" :on-close="toggleSelectPopup">
      <add-select-campaign @channel-item-click="onSelectChanel" />
    </woot-modal> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import HeaderSchedule from './Header';
import alertMixin from 'shared/mixins/alertMixin';
// import AddCampaign from './components/AddCampaign';
// import CampaignsTable from './components/Table';
// import TableFooter from 'dashboard/components/widgets/TableFooter';
// import AddSelectCampaign from './components/AddSelectCampaign';
// const DEFAULT_PAGE = 1;

export default {
  components: {
    // HeaderSchedule,
    // AddCampaign,
    // CampaignsTable,
    // AddSelectCampaign,
    // TableFooter,
  },
  mixins: [alertMixin],
  props: {
    // label: { type: String, default: '' },
    status: { type: String, default: '' },
    // segmentsId: {
    //   type: [String, Number],
    //   default: 0,
    // },
    headerTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    showNewButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // searchQuery: '',
      showCreateModal: false,
      showImportModal: false,
      selectedContactId: '',
      sortConfig: { last_activity_at: 'desc' },
      showFiltersModal: false,
      segmentsQuery: {},
      showAddSegmentsModal: false,
      showDeleteSegmentsModal: false,
      appliedFilter: [],
      showAddPopup: false,
      showAddSelectPopup: false,
      selectedChanel: '',
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      records: 'campaignsMessages/getAllCampaigns',
    }),
    meta() {
      const total = this.campaigns.length;
      return {
        currentPage: this.currentPage,
        perPage: 10,
        count: total,
      };
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    pageTitle() {
      return this.$t(this.headerTitle);
    },
    // campaigns() {
    //   if (this.searchQuery !== '') {
    //     const search = this.searchQuery;
    //     return this.records.filter(
    //       campaign => campaign.description.indexOf(search) > -1
    //     );
    //   }
    //   return this.records;
    // },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    wrapClass() {
      return this.showContactViewPane ? 'w-[75%]' : 'w-full';
    },
  },
  mounted() {
    this.$store.dispatch('campaignsMessages/get');
  },
  methods: {
    onPageChange(page) {
      this.selectedContactId = '';
      this.currentPage = page;
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    toggleSelectPopup() {
      this.showAddSelectPopup = !this.showAddSelectPopup;
    },
    onSelectChanel(channel) {
      this.showAddSelectPopup = false;
      this.selectedChanel = channel;
      this.openAddPopup();
    },
    // onInputSearch(event) {
    //   const newQuery = event.target.value;
    //   this.searchQuery = newQuery;
    // },
  },
};
</script>
