export default {
  methods: {
    formattedColor(status) {
      switch (status) {
        case 'SCHEDULED':
          return '#ffbf00';
        case 'CONFIRMED':
          return '#22c563';
        case 'AVAILABLE':
          return 'rgba(0, 127, 246, 0)'; // '#007ff6'
        default:
          return 'rgba(0, 127, 246, 0)'; // '#007ff6'
      }
    },
    formattedTextColor(status) {
      switch (status) {
        case 'SCHEDULED':
          return '';
        case 'CONFIRMED':
          return '';
        case 'AVAILABLE':
          return 'rgba(0, 0, 0)';
        default:
          return 'rgba(0, 0, 0)';
      }
    },
    formattedTitle(item) {
      const status = this.$t('SCHEDULE.STATUS.' + item.status);
      if (item.status === 'AVAILABLE') return status;
      if (item.contact_id && item.contact.name)
        return `${item.contact.name} - ${status}`;
      return status;
    },
  },
};
