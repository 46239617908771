export const ATTRIBUTE_MODELS = [
  {
    id: 0,
    option: 'Conversation',
    code: 'conversation_attribute',
  },
  {
    id: 1,
    option: 'Contact',
    code: 'contact_attribute',
  },
  {
    id: 2,
    option: 'Professional',
    code: 'professional_attribute',
  },
];

export const ATTRIBUTE_TYPES = [
  {
    id: 0,
    option: 'Text',
  },
  {
    id: 1,
    option: 'Number',
  },
  {
    id: 4,
    option: 'Link',
  },
  {
    id: 5,
    option: 'Date',
  },
  {
    id: 6,
    option: 'List',
  },
  {
    id: 7,
    option: 'Checkbox',
  },
  {
    id: 8,
    option: 'options',
  },
];

export const ATTRIBUTE_WHO_MANAGE = [
  {
    id: 'admin',
    option: 'admin',
  },
  {
    id: 'user',
    option: 'user',
  },
  {
    id: 'read_only',
    option: 'read_only',
  },
];
