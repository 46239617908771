<template>
  <div
    v-if="hasSecondaryMenu"
    class="h-full overflow-auto w-48 flex flex-col bg-white dark:bg-slate-900 border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50 text-sm px-2 pb-8"
  >
    <account-context @toggle-accounts="toggleAccountModal" />
    <transition-group
      name="menu-list"
      tag="ul"
      class="pt-2 list-none ml-0 mb-0"
    >
      <secondary-nav-item
        v-for="menuItem in accessibleMenuItems"
        :key="menuItem.toState"
        :menu-item="menuItem"
      />
      <secondary-nav-item
        v-for="menuItem in additionalSecondaryMenuItems[menuConfig.parentNav]"
        :key="menuItem.key"
        :menu-item="menuItem"
        @add-label="showAddLabelPopup"
      />
    </transition-group>
  </div>
</template>
<script>
import { frontendURL } from '../../../helper/URLHelper';
import SecondaryNavItem from './SecondaryNavItem.vue';
import AccountContext from './AccountContext.vue';
import { mapGetters } from 'vuex';
import { FEATURE_FLAGS } from '../../../featureFlags';

export default {
  components: {
    AccountContext,
    SecondaryNavItem,
  },
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    inboxes: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    customViews: {
      type: Array,
      default: () => [],
    },
    menuConfig: {
      type: Object,
      default: () => {},
    },
    currentRole: {
      type: String,
      default: '',
    },
    isOnChatwootCloud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusCampaignMsg: [
        { label: 'COMPLETED', color: '#22c563' },
        { label: 'PARTIALLY_COMPLETED', color: '#22c563' },
        { label: 'SCHEDULED', color: '#ffbf00' },
        { label: 'PROCESSING', color: '#ffbf00' },
        { label: 'IMPORTING_CONTACTS', color: '#ffbf00' },
        { label: 'OUTSIDE_CONFIGURED_HOURS', color: '#ffbf00' },
        // { label: 'MESSAGE_LIMIT_REACHED', color: '#ff0008' },
        { label: 'FILE_IMPORT_ERROR', color: '#ff0008' },
        { label: 'CONNECTOR_OFFLINE', color: '#ff0008' },
      ],
      statusSchedules: [
        { label: 'NOT_SCHEDULE', color: '#a0a0a0' },
        { label: 'SCHEDULED', color: '#ffbf00' },
        { label: 'CONFIRMED', color: '#22c563' },
      ],
      visibilityHistoric: [
        { label: 'ONLY_USER' },
        { label: 'ADMIN' },
        { label: 'ALL_USERS' },
        { label: 'COMPANY_PROFESSIONALS' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    hasSecondaryMenu() {
      return this.menuConfig.menuItems && this.menuConfig.menuItems.length;
    },
    contactCustomViews() {
      return this.customViews.filter(view => view.filter_type === 'contact');
    },
    accessibleMenuItems() {
      if (!this.currentRole) {
        return [];
      }
      const menuItemsFilteredByRole = this.menuConfig.menuItems.filter(
        menuItem =>
          window.roleWiseRoutes[this.currentRole].indexOf(
            menuItem.toStateName
          ) > -1
      );
      return menuItemsFilteredByRole.filter(item => {
        if (item.showOnlyOnCloud) {
          return this.isOnChatwootCloud;
        }
        return true;
      });
    },
    inboxSection() {
      return {
        icon: 'folder',
        label: 'INBOXES',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.INBOX_MANAGEMENT),
        newLinkTag: 'NEW_INBOX',
        key: 'inbox',
        toState: frontendURL(`accounts/${this.accountId}/settings/inboxes/new`),
        toStateName: 'settings_inbox_new',
        newLinkRouteName: 'settings_inbox_new',
        children: this.inboxes
          .map(inbox => ({
            id: inbox.id,
            label: inbox.name,
            truncateLabel: true,
            status: inbox.status_connection,
            toState: frontendURL(
              `accounts/${this.accountId}/inbox/${inbox.id}`
            ),
            type: inbox.channel_type,
            phoneNumber: inbox.phone_number,
            reauthorizationRequired: inbox.reauthorization_required,
          }))
          .sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
          ),
      };
    },
    labelSection() {
      return {
        icon: 'number-symbol',
        label: 'LABELS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        key: 'label',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        dataTestid: 'sidebar-new-label-button',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}`
          ),
        })),
      };
    },
    contactLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/labels/${label.title}/contacts`
          ),
        })),
      };
    },
    campaignMsgLabelSection() {
      return {
        icon: 'number-symbol',
        label: 'TAGGED_WITH',
        hasSubMenu: true,
        key: 'label',
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: true,
        modalName: 'AddLabel',
        children: this.labels.map(label => ({
          id: label.id,
          label: label.title,
          color: label.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/label/${label.title}/campaigns_messages`
          ),
        })),
      };
    },
    campaignMsgStatusSection() {
      return {
        icon: 'number-symbol',
        label: 'STATUS_WITH',
        hasSubMenu: false,
        key: 'label',
        // newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        // newLinkTag: 'NEW_LABEL',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: false,
        // modalName: 'AddLabel',
        children: this.statusCampaignMsg.map(status => ({
          id: status.label,
          label: this.$t('CAMPAIGN_MSG.STATUS.' + status.label),
          color: status.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/status/${status.label}/campaigns_messages`
          ),
        })),
      };
    },
    // historicToContact() {
    //   const contact = this.getContact();
    //   return {
    //     icon: 'contact-card-group',
    //     label: 'SEE_CONTACT',
    //     key: 'SEE_CONTACT',
    //     toState: frontendURL(
    //       `accounts/${this.accountId}/contacts/${contact.id}`
    //     ),
    //     toStateName: 'contact_profile_dashboard',
    //   };
    // },
    historiesMenu() {
      const contact = this.getContact();

      return [
        {
          icon: 'contact-card-group',
          label: 'SEE_CONTACT',
          key: 'SEE_CONTACT',
          toState: frontendURL(
            `accounts/${this.accountId}/contacts/${contact.id}`
          ),
          toStateName: 'contact_profile_dashboard',
        },
        {
          icon: 'calendar-clock',
          label: 'ALL_HISTORIES',
          key: 'ALL_HISTORIES',
          toState: frontendURL(
            `accounts/${this.accountId}/historic/${contact.id}/all`
          ),
          toStateName: 'historic_home',
        },
      ];
    },
    historicStatusSection() {
      const contact = this.getContact();
      // const visibilityFilter = this.$route.params.visibility;
      // console.log('historicStatusSection visibilityFilter ', visibilityFilter);
      return {
        icon: 'number-symbol',
        label: 'SCHEDULE_STATUS_WITH',
        hasSubMenu: false,
        key: 'status',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: false,
        // modalName: 'AddLabel',
        children: this.statusSchedules.map(status => ({
          id: status.label,
          label: this.$t('SCHEDULE.STATUS.' + status.label),
          color: status.color,
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/historic/${contact.id}/status/${status.label}/`
          ),
          // toState: frontendURL(
          //   visibilityFilter
          //     ? this.getHistoryFilterUrl(contact)
          //     : `accounts/${this.accountId}/historic/${contact.id}/status/${status.label}/`
          // ),
        })),
      };
    },

    historicVisibilitySection() {
      const contact = this.getContact();
      // const statusFilter = this.$route.params.status;
      // console.log('historicVisibilitySection status ', statusFilter);
      return {
        icon: 'number-symbol',
        label: 'VISIBILITY',
        hasSubMenu: false,
        key: 'visibility',
        toState: frontendURL(`accounts/${this.accountId}/settings/labels`),
        toStateName: 'labels_list',
        showModalForNewItem: false,
        // modalName: 'AddLabel',
        children: this.visibilityHistoric.map(visibility => ({
          id: visibility.label,
          label: this.$t(
            'HISTORIC.FORM.VISIBILITY.OPTIONS.' + visibility.label
          ),
          truncateLabel: true,
          toState: frontendURL(
            `accounts/${this.accountId}/historic/${contact.id}/visibility/${visibility.label}/`
          ),
          // toState: frontendURL(
          //   statusFilter
          //     ? this.getHistoryFilterUrl(contact)
          //     : `accounts/${this.accountId}/historic/${contact.id}/visibility/${visibility.label}/`
          // ),
        })),
      };
    },

    teamSection() {
      return {
        icon: 'people-team',
        label: 'TEAMS',
        hasSubMenu: true,
        newLink: this.showNewLink(FEATURE_FLAGS.TEAM_MANAGEMENT),
        newLinkTag: 'NEW_TEAM',
        key: 'team',
        toState: frontendURL(`accounts/${this.accountId}/settings/teams/new`),
        toStateName: 'settings_teams_new',
        newLinkRouteName: 'settings_teams_new',
        children: this.teams.map(team => ({
          id: team.id,
          label: team.name,
          truncateLabel: true,
          toState: frontendURL(`accounts/${this.accountId}/team/${team.id}`),
        })),
      };
    },
    foldersSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_FOLDER',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'conversation')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/custom_view/${view.id}`
            ),
          })),
      };
    },
    contactSegmentsSection() {
      return {
        icon: 'folder',
        label: 'CUSTOM_VIEWS_SEGMENTS',
        hasSubMenu: true,
        key: 'custom_view',
        children: this.customViews
          .filter(view => view.filter_type === 'contact')
          .map(view => ({
            id: view.id,
            label: view.name,
            truncateLabel: true,
            toState: frontendURL(
              `accounts/${this.accountId}/contacts/custom_view/${view.id}`
            ),
          })),
      };
    },
    additionalSecondaryMenuItems() {
      let conversationMenuItems = [this.inboxSection, this.labelSection];
      let contactMenuItems = [this.contactLabelSection];
      // let campaignsMessagesItems = [this.campaignMsgLabelSection];
      let campaignsMessagesItems = [this.campaignMsgStatusSection];
      if (this.teams.length) {
        conversationMenuItems = [this.teamSection, ...conversationMenuItems];
      }
      if (this.customViews.length) {
        conversationMenuItems = [this.foldersSection, ...conversationMenuItems];
      }
      if (this.contactCustomViews.length) {
        contactMenuItems = [this.contactSegmentsSection, ...contactMenuItems];
      }
      const contact = this.getContact();
      return {
        conversations: conversationMenuItems,
        contacts: contactMenuItems,
        campaigns_messages: campaignsMessagesItems,
        // historic: [this.historicVisibilitySection],
        // historic: [this.historicStatusSection],
        ...(contact && {
          historic: [
            // this.historiesAll,
            ...this.historiesMenu,
            this.historicStatusSection,
            this.historicVisibilitySection,
          ],
        }),
      };
    },
  },
  methods: {
    showAddLabelPopup() {
      this.$emit('add-label');
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    showNewLink(featureFlag) {
      return this.isFeatureEnabledonAccount(this.accountId, featureFlag);
    },
    getContact() {
      const result = null;
      const contactId = this.$route.params.contactId;
      if (contactId) {
        return this.$store.getters['contacts/getContact'](contactId);
      }
      return result;
    },
    // getHistoryFilterUrl(contact) {
    //   const status = this.$route.params.status;
    //   const visibility = this.$route.params.visibility;
    //   console.log('this.$route ', this.$route);
    //   console.log('status ', status);
    //   console.log('visibility ', visibility);
    //   if (status && visibility) {
    //     return `accounts/${this.accountId}/historic/${contact.id}/status/${status}/visibility/${visibility}/`;
    //   }
    //   if (status || visibility) {
    //     if (status)
    //       return `accounts/${this.accountId}/historic/${contact.id}/status/${status}/`;
    //     return `accounts/${this.accountId}/historic/${contact.id}/visibility/${visibility}/`;
    //   }
    //   return `accounts/${this.accountId}/historic/${contact.id}/`;
    // },
  },
};
</script>
