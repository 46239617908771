const isHostDev = window.location.host.includes('localhost');
export const INTEGRATION_OPC = [
  { title: 'Cloud API (Meta)', value: 'WHATSAPP-BUSINESS' },
  { title: 'Espelhamento', value: 'WHATSAPP-BAILEYS' },
];

const hostLocalEvo = { title: 'Dev local', value: 'http://localhost:8080' };
const hostLocalEvo2 = { title: 'Dev local', value: 'http://localhost:8082' };

export let EVO_VERSION = [
  { title: 'Nubla EVO V1', value: 'nubla-evo-v1' },
  { title: 'Nubla EVO V2', value: 'nubla-evo-v2' },
];

export let EVO_HOST = [
  { title: 'Nubla Cloud', value: 'https://evomanager.nubla.cloud' },
  { title: 'Nubla Proxy', value: 'https://evoproxy.nubla.cloud' },
];

export let EVO_V2_HOST = [
  { title: 'Nubla V2', value: 'https://v2evomanager.nubla.cloud' },
];

if (isHostDev && !EVO_HOST.includes(hostLocalEvo)) {
  EVO_HOST.push(hostLocalEvo);
}
if (isHostDev && !EVO_VERSION.includes(hostLocalEvo2)) {
  EVO_V2_HOST.push(hostLocalEvo2);
}
import { EVO_WOOT_CONFIG } from 'dashboard/constants/evolution.js';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    steps() {
      return this.$t('API_CONTROL.NOT_INSTANCE.CREATE_FLOW');
    },
  },
  methods: {
    evoStatusLabel(evoStatus) {
      const status = evoStatus && evoStatus !== '' ? evoStatus : undefined;
      return status
        ? this.$t(`INBOX_MGMT.EVOLUTION.STATUS.${status.toLocaleUpperCase()}`)
        : '';
    },
    instanceName(inbox) {
      const inboxName = inbox.name;
      return inbox.webhook_url
        ? this.inbox.webhook_url.split('/webhook/')[1]
        : inboxName;
    },
    getConfigWoot() {
      const url = window.location.origin;
      const account_id = String(this.currentAccountId);
      const token = this.currentUser.access_token;
      const config = {
        enabled: true,
        url,
        account_id,
        token,
        sign_msg: false,
        reopen_conversation: true,
        conversation_pending: false,
      };
      return { ...EVO_WOOT_CONFIG, ...config };
    },
    async sendDataWoot(instance, settings) {
      try {
        const result = await this.$store.dispatch('apiEvolution/setWoot', {
          instance,
          settings,
        });
        return result;
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
        return error;
      }
    },
  },
};
