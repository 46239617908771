<template>
  <div class="contact-conversation--panel">
    <div v-if="!uiFlags.isFetching" class="contact-conversation__wrap">
      <div v-if="!schedules.length" class="no-label-message">
        <span>
          {{ $t('CONTACT_PANEL.SCHEDULES.NO_RECORDS_FOUND') }}
        </span>
      </div>
      <div v-else class="contact-conversation--list">
        <div
          v-for="schedule in schedules"
          :key="schedule.id"
          class="relative flex items-start flex-grow-0 flex-shrink-0 w-auto max-w-full py-0 border-t-0 border-b-0 border-l-2 border-r-0 border-transparent border-solid cursor-pointer conversation hover:bg-slate-25 dark:hover:bg-slate-800 group"
          @click="onCardClick(schedule)"
        >
          <div
            class="px-0 py-3 border-b group-last:border-transparent group-hover:border-transparent border-slate-50 dark:border-slate-800/75 columns"
          >
            <div class="flex justify-between">
              <div
                class="inbox--name inline-flex items-center py-0.5 px-0 leading-3 font-medium bg-none text-slate-600 dark:text-slate-500 text-xs my-0 mx-2.5"
              >
                <fluent-icon
                  class="mr-0.5 rtl:ml-0.5 rtl:mr-0"
                  icon="person"
                  size="12"
                />
                {{ schedule.professional.name }}
              </div>
            </div>
            <h4
              class="conversation--user text-sm my-0 mx-2 capitalize pt-0.5 text-ellipsis overflow-hidden whitespace-nowrap w-[60%] text-slate-900 dark:text-slate-100"
            >
              {{ parseData(schedule.schedule_date_time) }}
            </h4>
            <p
              class="conversation--message text-slate-700 dark:text-slate-200 text-sm my-0 mx-2 leading-6 h-6 max-w-[96%] w-[16.875rem] overflow-hidden text-ellipsis whitespace-nowrap"
            >
              <fluent-icon
                size="16"
                class="-mt-0.5 align-middle inline-block text-slate-600 dark:text-slate-300"
                icon="calendar-clock"
              />
              <span>
                {{
                  getIntervalTime(
                    schedule.schedule_date_time,
                    schedule.schedule_date_time_end
                  )
                }}
              </span>
            </p>
            <div
              class="absolute flex flex-col conversation--meta right-4 top-4"
            >
              <span
                class="ml-auto font-normal leading-4 text-black-600 text-xxs"
              >
                <div
                  v-tooltip.top="
                    false
                      ? {
                          content: `Criado em 19/20/24
                                    Atualizado: 20/12/24`,
                          delay: { show: 1500, hide: 0 },
                          hideOnClick: true,
                        }
                      : undefined
                  "
                  class="text-xxs text-slate-500 dark:text-slate-500 leading-4 ml-auto hover:text-slate-900 dark:hover:text-slate-100"
                >
                  <!-- <span> 10:30 - 12:20</span> -->
                  <span
                    :style="{
                      backgroundColor: formattedColor(schedule.status),
                    }"
                    class="p-1 text-slate-50 rounded"
                  >
                    {{ $t('SCHEDULE.STATUS.' + schedule.status) }}
                  </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <spinner v-else />
    <woot-modal :show.sync="showEditAgenda" :on-close="toggleSelectPopup">
      <handle-event-modal
        :title="titleAgenda"
        :data-event="selectEvent"
        @on-close="toggleSelectPopup"
      />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import dateMixin from 'dashboard/mixins/dateMixin';
import scheduleMixin from 'dashboard/mixins/scheduleMixin';
import handleEventModal from 'dashboard/components/widgets/calendar/HandleEventModal.vue';

export default {
  components: {
    Spinner,
    handleEventModal,
  },
  mixins: [dateMixin, scheduleMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    contactId: {
      type: [String, Number],
      required: true,
    },
    conversationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      selectEvent: {},
      showEditAgenda: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contactConversations/getUIFlags',
      records: 'schedules/getAllSchedules',
    }),
    schedules() {
      return this.records;
    },
    titleAgenda() {
      return this.formattedTitle(this.selectEvent);
    },
  },
  watch: {
    contactId(newContactId, prevContactId) {
      if (newContactId && newContactId !== prevContactId) {
        this.$store.dispatch('contactConversations/get', newContactId);
      }
    },
  },
  mounted() {
    this.$store.dispatch('contactConversations/get', this.contactId);
    this.getSchedule();
  },
  methods: {
    getSchedule() {
      if (this.contact && this.contact.id) {
        this.$store.dispatch('schedules/getFilter', {
          contact_id: this.contact.id,
        });
      }
    },
    parseData(date) {
      if (!date) return '';
      return this.getDateDay(date, "EEEE,' 'dd'/'MM'/'yyyy");
    },
    onCardClick(schedule) {
      this.selectEvent = schedule;
      this.toggleSelectPopup();
    },
    toggleSelectPopup() {
      this.showEditAgenda = !this.showEditAgenda;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-label-message {
  @apply text-slate-500 dark:text-slate-400 mb-4;
}

::v-deep .conversation {
  @apply pr-0;

  .conversation--details {
    @apply pl-2;
  }
}
</style>
