import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default {
  methods: {
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    getDateDay(dateString, formated) {
      const date = new Date(dateString);
      const useFormated = formated || "EEEE, dd 'de' MMMM 'de' yyyy";
      const dateFormat = format(date, useFormated, {
        locale: ptBR,
      });
      return this.capitalize(dateFormat);
    },
    getTime(dateString) {
      const time = new Date(dateString).toLocaleTimeString().substring(0, 5);
      return time;
    },
    getDateTime(dateString) {
      const [day] = dateString.split('T');
      // const day =
      const time = new Date(dateString).toLocaleTimeString().substring(0, 5);
      return this.capitalize(`${day} ${time}`);
    },
    getIntervalTime(initDateString, endDateString) {
      // const startTime = initDateString.split('T')[1].substr(0, 5);
      // const endTime = endDateString.split('T')[1].substr(0, 5);
      // return `${startTime} - ${endTime}`;
      const startTime = new Date(initDateString)
        .toLocaleTimeString()
        .substring(0, 5);
      const endTime = new Date(endDateString)
        .toLocaleTimeString()
        .substring(0, 5);
      return `${startTime} - ${endTime}`;
    },
  },
};
