const formatEvo2 = data => {
  const { name, id, connectionStatus, token, integration, Chatwoot } = data;
  const dataFormated = {
    instanceName: name,
    instanceId: id,
    status: connectionStatus,
    serverUrl: '', // "http://localhost:8080",
    apikey: token,
    integration: {
      integration: integration,
      token: Chatwoot,
      webhook_wa_business: '', // "http://localhost:8080/webhook/whatsapp/testegroup"
    },
    v2: {
      ...data,
    },
  };
  return dataFormated;
};
const getSettingsEvo2 = data => {
  const {
    rejectCall,
    msgCall,
    groupsIgnore,
    alwaysOnline,
    readMessages,
    readStatus,
    syncFullHistory,
  } = data;
  return {
    reject_call: rejectCall,
    msg_call: msgCall,
    groups_ignore: groupsIgnore,
    always_online: alwaysOnline,
    read_messages: readMessages,
    read_status: readStatus,
    sync_full_history: syncFullHistory,
  };
};

const getWootEvo2 = data => {
  const {
    accountId,
    nameInbox,
    signMsg,
    signDelimiter,
    reopenConversation,
    conversationPending,
    mergeBrazilContacts,
    importContacts,
    importMessages,
    daysLimitImportMessages,
  } = data;
  return {
    account_id: accountId,
    name_inbox: nameInbox,
    sign_msg: signMsg,
    sign_delimiter: signDelimiter,
    reopen_conversation: reopenConversation,
    conversation_pending: conversationPending,
    merge_brazil_contacts: mergeBrazilContacts,
    import_contacts: importContacts,
    import_messages: importMessages,
    days_limit_import_messages: daysLimitImportMessages,
    ...data,
  };
};

const typeApiNubla = {
  'nubla-evo-v1': {
    getInstance: data => data.instance,
    getSettings: data => data,
    getWoot: data => data,
  },
  'nubla-evo-v2': {
    getInstance: data => formatEvo2(data[0]),
    getSettings: getSettingsEvo2,
    getWoot: getWootEvo2,
  },
};

export const apiDataParse = (isApi, endPoint, data) => {
  if (Object.prototype.hasOwnProperty.call(typeApiNubla, isApi))
    return typeApiNubla[isApi][endPoint](data);
  return { versionApi: undefined, ...data };
};

export const formatV2ConfigWoot = v1ConfigWoot => {
  const {
    enabled,
    account_id,
    token,
    sign_msg,
    url,
    import_contacts,
    sign_delimiter,
    reopen_conversation,
    conversation_pending,
    name_inbox,
    import_messages,
    auto_create,
    days_limit_import_messages,
  } = v1ConfigWoot;
  return {
    enabled: enabled,
    accountId: account_id,
    token: token,
    url: url,
    signMsg: sign_msg,
    reopenConversation: reopen_conversation,
    conversationPending: conversation_pending,
    nameInbox: name_inbox,
    importContacts: import_contacts,
    importMessages: import_messages,
    daysLimitImportMessages: days_limit_import_messages,
    signDelimiter: sign_delimiter,
    autoCreate: auto_create,
    organization: '',
    logo: '',
    ignoreJids: [],
  };
};

export const formatV2Config = v1Config => {
  const {
    reject_call,
    msg_call,
    groups_ignore,
    always_online,
    read_messages,
    read_status,
    sync_full_history,
  } = v1Config;
  return {
    rejectCall: reject_call,
    msgCall: msg_call,
    groupsIgnore: groups_ignore,
    alwaysOnline: always_online,
    readMessages: read_messages,
    syncFullHistory: sync_full_history,
    readStatus: read_status,
  };
};
