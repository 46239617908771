<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ $t('CAMPAIGN.HEADER_BTN_TXT.WHATSAPP') }}
    </woot-button>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign />
    </woot-modal>
  </div>
</template>

<script>
import AddCampaign from './components/whatsapp/AddCampaign.vue';

export default {
  components: {
    AddCampaign,
  },
  data() {
    return { showAddPopup: false };
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
