var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"-mt-px text-sm"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
      _vm.disabled
        ? {
            content: _vm.$t('CONTACT_PANEL.SIDEBAR_SECTIONS.DISABLED'),
            delay: { show: 500, hide: 0 },
            hideOnClick: true,
          }
        : undefined
    ),expression:"\n      disabled\n        ? {\n            content: $t('CONTACT_PANEL.SIDEBAR_SECTIONS.DISABLED'),\n            delay: { show: 500, hide: 0 },\n            hideOnClick: true,\n          }\n        : undefined\n    ",modifiers:{"top":true}}],staticClass:"flex items-center select-none w-full bg-slate-50 dark:bg-slate-800 border border-l-0 border-r-0 border-solid m-0 border-slate-100 dark:border-slate-700/50 cursor-grab justify-between py-2 px-4 drag-handle",class:{ disabled: _vm.disabled },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"flex justify-between mb-0.5"},[_c('emoji-or-icon',{staticClass:"inline-block w-5",attrs:{"icon":_vm.icon,"emoji":_vm.emoji}}),_vm._v(" "),_c('h5',{staticClass:"text-slate-800 text-sm dark:text-slate-100 mb-0 py-0 pr-2 pl-0"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"flex flex-row"},[_vm._t("button"),_vm._v(" "),_c('div',{staticClass:"flex justify-end w-3 text-woot-500"},[(_vm.isOpen)?_c('fluent-icon',{attrs:{"size":"24","icon":"subtract","type":"solid"}}):_c('fluent-icon',{attrs:{"size":"24","icon":"add","type":"solid"}})],1)],2)]),_vm._v(" "),(_vm.disabled ? false : _vm.isOpen)?_c('div',{staticClass:"bg-white dark:bg-slate-900",class:{
      'p-0': _vm.compact,
      'p-4': !_vm.compact,
      'nb-max-height': _vm.isContactSchedule,
    }},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }