import SettingsContent from '../Wrapper';
import AuditLogsHome from './Index';
import DetailLog from './Details.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/audit-log'),
      component: SettingsContent,
      props: route => {
        const showBackButton = route.name !== 'auditlogs_list';
        const isDetail = route.name === 'auditlogs_detail';
        const backUrl =
          route.name !== 'auditlogs_list' ? { name: 'auditlogs_list' } : '';
        return {
          headerTitle: !isDetail
            ? 'AUDIT_LOGS.HEADER'
            : 'AUDIT_LOGS.DETAIL.HEADER',
          icon: 'key',
          showNewButton: false,
          showBackButton,
          backUrl,
          showSidemenuIcon: !isDetail,
        };
      },
      children: [
        {
          path: '',
          name: 'auditlogs_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'auditlogs_list',
          roles: ['administrator'],
          component: AuditLogsHome,
        },
        {
          path: 'detail/:id',
          name: 'auditlogs_detail',
          roles: ['administrator'],
          component: DetailLog,
        },
      ],
    },
  ],
};
