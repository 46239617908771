<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="$t('EDITOR_WHATSAPP.TITLE')" />
    <div class="reply-box">
      <div class="reply-box__top">
        <div v-if="showFormatMsg">
          <h6>{{ $t('EDITOR_WHATSAPP.MESSAGE_FORMATTED') }}</h6>
          <div v-html="msgFormated" />
        </div>
        <reply-Top-panel-campaign
          :show-emoji-picker="showEmojiPicker"
          :toggle-emoji-picker="toggleEmojiPicker"
          :ref-input-msg="$refs"
          :message="message"
          :show-eye="showFormatMsg"
          @funtion-show="toogleShowFormat"
          @edit-text="editTextFormat"
        />
        <emoji-input
          v-if="showEmojiPicker"
          v-on-clickaway="hideEmojiPicker"
          :class="emojiDialogClassOnExpandedLayoutAndRTLView"
          :on-click="emojiOnClick"
        />
        <resizable-text-area
          ref="messageInput"
          v-model="message"
          class="input"
          :placeholder="messagePlaceHolder"
          :min-height="4"
          :allow-signature="true"
          @focus="onFocus"
          @blur="onBlur"
        />
        <!-- v-if="!showRichContentEditor" -->
      </div>
      <reply-bottom-panel-campaign
        :is-send-disabled="isReplyButtonDisabled"
        :on-send="onSendReply"
        :send-button-text="$t('CONVERSATION.REPLYBOX.CONFIRM')"
        :show-editor-toggle="true"
        :show-emoji-picker="showEmojiPicker"
        :toggle-emoji-picker="toggleEmojiPicker"
        :vars-doc="varsDoc"
        :message="message"
        :ref-input-msg="$refs"
        :has-whatsapp-templates="hasWhatsappTemplates"
        @selectWhatsappTemplate="openWhatsappTemplateModal"
        @edit-text="editTextFormat"
      />
      <div v-if="inbox && inbox.id">
        <whatsapp-templates
          :inbox-id="inbox.id"
          :show="showWhatsAppTemplatesModal"
          @close="hideWhatsappTemplatesModal"
          @on-send="onSendWhatsAppReply"
          @cancel="hideWhatsappTemplatesModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import rtlMixin from 'shared/mixins/rtlMixin';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import ReplyBottomPanelCampaign from './ReplyBottomPanelCampaign.vue';
import ReplyTopPanelCampaign from './ReplyTopPanelCampaign.vue';
import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import WhatsappTemplates from './WhatsappTemplates/Modal.vue';
import { format } from '@flasd/whatsapp-formatting';
const EmojiInput = () => import('shared/components/emoji/EmojiInput');

export default {
  components: {
    EmojiInput,
    ResizableTextArea,
    ReplyBottomPanelCampaign,
    WhatsappTemplates,
    ReplyTopPanelCampaign,
  },
  mixins: [clickaway, uiSettingsMixin, messageFormatterMixin, rtlMixin],
  props: {
    inbox: {
      type: Object,
      default: () => {},
    },
    messageProps: {
      type: String,
      default: '',
    },
    varsDoc: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isRichEditorEnabled: false,
      message: '',
      isFocused: false,
      showEmojiPicker: false,
      showWhatsAppTemplatesModal: false,
      updateEditorSelectionWith: '',
      showUserMentions: false,
      showFormatMsg: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    msgFormated() {
      return format(this.message);
    },
    messagePlaceHolder() {
      return this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isReplyButtonDisabled() {
      return false;
    },
    emojiDialogClassOnExpandedLayoutAndRTLView() {
      return 'emoji-dialog--expanded';
    },
    hasWhatsappTemplates() {
      // return true;
      return !!this.$store.getters['inboxes/getWhatsAppTemplates'](this.inboxId)
        .length;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyEvents);
    this.message = this.messageProps;
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    toogleShowFormat() {
      this.showFormatMsg = !this.showFormatMsg;
    },
    editTextFormat(newMessage) {
      this.message = newMessage;
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    async onSendReply() {
      this.$emit('set-message', this.message);
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    openWhatsappTemplateModal() {
      this.showWhatsAppTemplatesModal = true;
    },
    hideWhatsappTemplatesModal() {
      this.showWhatsAppTemplatesModal = false;
    },
    hideEmojiPicker() {
      this.showEmojiPicker = false;
    },
    insertEmoji(emoji, selectionStart, selectionEnd) {
      const { message } = this;
      const newMessage =
        message.slice(0, selectionStart) +
        emoji +
        message.slice(selectionEnd, message.length);
      this.message = newMessage;
    },
    emojiOnClick(emoji) {
      const { selectionStart, selectionEnd } = this.$refs.messageInput.$el;
      this.insertEmoji(emoji, selectionStart, selectionEnd);
    },
    handleKeyEvents(e) {
      const keyCode = buildHotKeys(e);
      if (keyCode === 'escape') {
        this.hideEmojiPicker();
      }
    },
    async onSendWhatsAppReply() {
      // messagePayload
      // this.sendMessage({
      //   conversationId: this.currentChat.id,
      //   ...messagePayload,
      // });
      this.hideWhatsappTemplatesModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.send-button {
  @apply mb-0;
}

.banner--self-assign {
  @apply py-2;
}

.message-signature-wrap {
  @apply my-0 mx-4 px-1 flex max-h-[8vh] items-baseline justify-between hover:bg-slate-25 dark:hover:bg-slate-800 border border-dashed border-slate-100 dark:border-slate-700 rounded-sm overflow-auto;
}

.message-signature {
  @apply w-fit m-0;
}

.attachment-preview-box {
  @apply bg-transparent py-0 px-4;
}

.reply-box {
  @apply border-t border-slate-50 dark:border-slate-700 bg-white dark:bg-slate-900;
}
.send-button {
  @apply mb-0;
}

.reply-box__top {
  @apply relative py-0 px-4 -mt-px border-t border-solid border-slate-50 dark:border-slate-700;
}

.emoji-dialog {
  @apply top-[unset] -bottom-10 -left-80 right-[unset];

  &::before {
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    @apply -right-4 bottom-2 rtl:right-0 rtl:-left-4;
  }
}

.emoji-dialog--rtl {
  @apply left-[unset] -right-80;
  &::before {
    transform: rotate(90deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.emoji-dialog--expanded {
  @apply left-[unset] bottom-0 absolute z-[100];

  &::before {
    transform: rotate(0deg);
    @apply left-1 -bottom-2;
  }
}
.message-signature {
  @apply mb-0;

  ::v-deep p:last-child {
    @apply mb-0;
  }
}

.normal-editor__canned-box {
  width: calc(100% - 2 * var(--space-normal));
  left: var(--space-normal);
}
</style>
