import { frontendURL } from '../../../helper/URLHelper';
import professional from './professional/professional.routes';
import ScheduleView from './ScheduleView';
// import historicView from './historic/index.vue';
import historicRoutes from './historic/historic.routes';
// import store from '../../../store';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/schedules'),
      name: 'schedules_home',
      roles: ['administrator', 'agent'],
      component: ScheduleView,
      // redirect: () => {
      // if (store.getters.getCurrentRole === 'administrator') {
      //   return frontendURL('accounts/:accountId/settings/general');
      // }
      // return frontendURL('accounts/:accountId/schedules/professional');
      // },
    },
    // {
    //   path: frontendURL('accounts/:accountId/historic/:contactId'),
    //   name: 'historic_home',
    //   roles: ['administrator', 'agent'],
    //   component: historicView,
    //   props: route => {
    //     return { contactId: route.params.contactId };
    //   },
    // },
    // {
    //   path: frontendURL(
    //     'accounts/:accountId/historic/:contactId/status/:status'
    //   ),
    //   name: 'status_schedule_historic',
    //   roles: ['administrator', 'agent'],
    //   component: historicView,
    //   props: route => ({
    //     contactId: route.params.contactId,
    //     status: route.params.status,
    //   }),
    // },
    ...professional.routes,
    ...historicRoutes.routes,
  ],
};
