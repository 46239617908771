<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col h-full" :class="wrapClass">
      <header-campaign
        :search-query="searchQuery"
        :header-title="pageTitle"
        this-selected-contact-id=""
        @on-open-add-popup="toggleSelectPopup"
        @on-input-search="onInputSearch"
      />
      <campaigns-table
        v-if="records.length > 0"
        :campaigns="campaigns"
        :status="status"
        :meta="meta"
      />
      <table-footer
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="meta.perPage"
        @page-change="onPageChange"
      />
    </div>
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <add-campaign :channel="selectedChanel" @on-close="hideAddPopup" />
    </woot-modal>
    <woot-modal :show.sync="showAddSelectPopup" :on-close="toggleSelectPopup">
      <add-select-campaign @channel-item-click="onSelectChanel" />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderCampaign from './components/Header';
import alertMixin from 'shared/mixins/alertMixin';
import AddCampaign from './components/AddCampaign';
import CampaignsTable from './components/Table';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import AddSelectCampaign from './components/AddSelectCampaign';
// const DEFAULT_PAGE = 1;

export default {
  components: {
    HeaderCampaign,
    AddCampaign,
    CampaignsTable,
    AddSelectCampaign,
    TableFooter,
  },
  mixins: [alertMixin],
  props: {
    // label: { type: String, default: '' },
    status: { type: String, default: '' },
    // segmentsId: {
    //   type: [String, Number],
    //   default: 0,
    // },
  },
  data() {
    return {
      searchQuery: '',
      showCreateModal: false,
      showImportModal: false,
      selectedContactId: '',
      sortConfig: { last_activity_at: 'desc' },
      showFiltersModal: false,
      // contactFilterItems: contactFilterItems.map(filter => ({
      //   ...filter,
      //   attributeName: this.$t(
      //     `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
      //   ),
      // })),
      segmentsQuery: {},
      // filterType: FILTER_TYPE_CONTACT,
      showAddSegmentsModal: false,
      showDeleteSegmentsModal: false,
      appliedFilter: [],
      showAddPopup: false,
      showAddSelectPopup: false,
      selectedChanel: '',
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      records: 'campaignsMessages/getAllCampaigns',
    }),
    meta() {
      const total = this.campaigns.length;
      return {
        currentPage: this.currentPage,
        perPage: 10,
        count: total,
      };
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    pageTitle() {
      if (this.hasActiveSegments) {
        return this.activeSegment.name;
      }
      if (this.status) {
        return `# ${this.$t('CAMPAIGN_MSG.STATUS.' + this.status)}`;
      }
      return this.$t('CAMPAIGN_MSG.HEADER');
    },
    campaigns() {
      if (this.searchQuery !== '') {
        const search = this.searchQuery;
        return this.records.filter(
          campaign => campaign.description.indexOf(search) > -1
        );
      }
      return this.records;
    },
    showContactViewPane() {
      return this.selectedContactId !== '';
    },
    wrapClass() {
      return this.showContactViewPane ? 'w-[75%]' : 'w-full';
    },
  },
  mounted() {
    this.$store.dispatch('campaignsMessages/get');
  },
  methods: {
    onPageChange(page) {
      this.selectedContactId = '';
      this.currentPage = page;
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    toggleSelectPopup() {
      this.showAddSelectPopup = !this.showAddSelectPopup;
    },
    onSelectChanel(channel) {
      this.showAddSelectPopup = false;
      this.selectedChanel = channel;
      this.openAddPopup();
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
      // const refetchAllContacts = !!this.searchQuery && newQuery === '';
      // if (refetchAllContacts) {
      //   this.fetchContacts(DEFAULT_PAGE);
      // }
    },
  },
};
</script>
