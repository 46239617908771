<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header :header-title="titleHeader" />
    <form
      class="flex flex-col w-full pt-1"
      style="padding-bottom: 1rem; padding-top: 0.5rem"
      @submit.prevent="sendCampaign"
    >
      <div class="w-full">
        <label>
          {{ $t('INBOX_MGMT.ADD.AUTH.CHANNEL') }}
          <span>{{ channel }}</span>
        </label>
        <woot-input
          v-model="description"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.description.$error }"
          :error="
            $v.description.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''
          "
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.description.$touch"
        />
        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <multiselect
            v-model="selectedInbox"
            :options="inboxes"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="true"
            :placeholder="$t('CAMPAIGN.ADD.FORM.INBOX.LABEL')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @blur="$v.selectedInbox.$touch"
            @select="$v.selectedInbox.$touch"
          >
            <template slot="noResult">
              {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
            </template>
            <template slot="noOptions">
              {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
            </template>
          </multiselect>
        </label>
        <label class="editor-wrap">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <textarea
            v-model="message"
            class="message-editor"
            rows="3"
            :class="{ editor_warning: $v.message.$error }"
            :placeholder="
              $t(
                disabledMessage
                  ? 'CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER'
                  : 'CAMPAIGN.ADD.FORM.INBOX.LABEL'
              )
            "
            :disabled="!disabledMessage"
            @blur="$v.message.$touch"
            @click.prevent="showPopoutReplyBox"
          />
          <span v-if="$v.message.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </label>
        <div v-if="!fileImport" class="screen-selector flex">
          <label
            class="multiselect-wrap--small w-full"
            :class="{ error: $v.selectedAudience.$error }"
          >
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
            <multiselect
              v-model="selectedAudience"
              :options="audienceList"
              track-by="id"
              label="title"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :disabled="!isDisabled"
              @blur="$v.selectedAudience.$touch"
              @select="$v.selectedAudience.$touch"
            >
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
            <span v-if="$v.selectedAudience.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
            </span>
          </label>
          <div class="btn-import" :class="{ mb4: $v.selectedAudience.$error }">
            <woot-button
              v-tooltip="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              class-names="btn-icon-import"
              icon="upload"
              size="small"
              title="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              @click.prevent="onToggleImport"
            />
          </div>
        </div>
        <label v-else-if="fileImport" class="multiselect-wrap--small w-full">
          {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
          <div class="flex gap-1">
            <fluent-icon
              icon="file-upload"
              size="26"
              class="icon icon--font text-woot-500 iconImport"
            />
            <label class="multiselect-wrap--small w-full">
              {{ fileImport.name }}
            </label>
            <woot-button
              v-tooltip="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              class-names="btn-icon-import bg-red-400"
              icon="dismiss"
              size="small"
              color-scheme="secondary"
              title="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              @click.prevent="discardFile"
            />
          </div>
        </label>
        <label class="w-full mb-4">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-range-picker
            show-range
            class="no-margin auto-width"
            type="datetime"
            :value="customDateRange"
            range-separator=" até "
            :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
            :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
            @change="onCustomDateRangeChange"
          />
          <span
            v-if="$v.customDateRange.$error"
            class="editor-warning__message"
          >
            {{ $t('CAMPAIGN_MSG.ADD.FORM.SCHEDULED_AT.ERROR') }}
          </span>
        </label>
        <div class="div-form">
          <ui-form-time
            :label="$t('CAMPAIGN_MSG.ADD.FORM.TIME_INIT.LABEL')"
            :placeholder="$t('CAMPAIGN_MSG.ADD.FORM.TIME_INIT.PLACEHOLDER')"
            :value="timeInit"
            :error-message="$t('CAMPAIGN_MSG.ADD.FORM.TIME_INIT.ERROR')"
            :error-form="$v.timeInit.$error"
            @change="onChangeTimeInit"
          />
          <ui-form-time
            :label="$t('CAMPAIGN_MSG.ADD.FORM.TIME_END.LABEL')"
            :placeholder="$t('SCAMPAIGN_MSG.ADD.FORM.TIME_END.PLACEHOLDER')"
            :value="timeEnd"
            :error-message="$t('CAMPAIGN_MSG.ADD.FORM.TIME_END.ERROR')"
            :error-form="$v.timeEnd.$error"
            @change="onChangeTimeEnd"
          />
        </div>
        <div class="flex gap-1">
          <woot-input
            v-model="intervalInit"
            :label="$t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MIN.LABEL')"
            type="number"
            class="w-full"
            :placeholder="$t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MIN.PLACEHOLDER')"
            :error="
              $v.intervalInit.$error
                ? $t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MIN.ERROR')
                : ''
            "
            :class="{ error: $v.intervalInit.$error }"
            @blur="$v.intervalInit.$touch"
          />
          <woot-input
            v-model="intervalEnd"
            :label="$t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MAX.LABEL')"
            type="number"
            class="w-full"
            :placeholder="$t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MAX.PLACEHOLDER')"
            :error="
              $v.intervalEnd.$error
                ? $t('CAMPAIGN_MSG.ADD.FORM.INTERVAL_MAX.ERROR')
                : ''
            "
            :class="{ error: $v.intervalEnd.$error }"
            @blur="$v.intervalEnd.$touch"
          />
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button variant="clear" @click.prevent="onClose">
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button :is-loading="uiFlags.isCreating">
            {{ textBtnSend }}
          </woot-button>
        </div>
      </div>
    </form>
    <woot-modal :show.sync="showImportModal" :on-close="onToggleImport">
      <import-contacts
        v-if="showImportModal"
        :on-close="onToggleImport"
        :is-campaign="true"
        :on-return-file="getReturnFile"
      />
    </woot-modal>
    <woot-modal :show.sync="isPopoutReplyBox" :on-close="showPopoutReplyBox">
      <reply-box-campaign
        :inbox="selectedInbox[0]"
        :popout-reply-box.sync="isPopoutReplyBox"
        :message-props="message"
        :vars-doc="varsDoc"
        @click="showPopoutReplyBox"
        @set-message="setMessage"
      />
    </woot-modal>
    <div v-if="selectIdInbox !== ''">
      <whatsapp-templates
        :inbox-id="selectIdInbox"
        :show="showWhatsAppTemplatesModal"
        :vars-doc="varsDoc"
        @close="hideWhatsappTemplatesModal"
        @on-send="onSendWhatsAppReply"
        @cancel="hideWhatsappTemplatesModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import ReplyBoxCampaign from 'dashboard/components/widgets/conversation/ReplyBoxCampaign.vue';
import ImportContacts from '../../contacts/components/ImportContacts.vue';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
// import WootTimePicker from 'dashboard/components/ui/TimePicker.vue';
import { CAMPAIGNS_MESSAGES_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import WhatsappTemplates from 'dashboard/components/widgets/conversation/WhatsappTemplates/Modal.vue';
import UiFormTime from 'dashboard/components/widgets/forms/UIFormTime.vue';

export default {
  components: {
    WootDateRangePicker,
    // WootDateTimePicker,
    // WootCampaignEditor,
    ImportContacts,
    // WootTimePicker,
    ReplyBoxCampaign,
    WhatsappTemplates,
    UiFormTime,
  },
  mixins: [alertMixin],
  props: {
    campaignMsg: {
      type: Object,
      default: () => ({}),
    },
    channel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      description: '',
      message: '',
      selectedInbox: [],
      intervalInit: 5,
      intervalEnd: 15,
      // show: true,
      timeInit: null,
      timeEnd: null,
      selectedAudience: [],
      showImportModal: false,
      customDateRange: [],
      isPopoutReplyBox: false,
      fileImport: null,
      showWhatsAppTemplatesModal: false,
      whatsappTemplateData: null,
      varsDoc: null,
      // news vars
    };
  },
  validations() {
    const commonValidations = {
      description: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    return {
      ...commonValidations,
      intervalInit: {
        required,
      },
      intervalEnd: {
        required,
      },
      timeInit: {
        required,
      },
      timeEnd: {
        required,
      },
      customDateRange: {
        required,
      },
      selectedAudience: {},
      // selectedAudience: {
      //   isEmpty() {
      //     return !!this.selectedAudience.length;
      //   },
      // },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaignsMessages/getUIFlags',
      audienceList: 'labels/getLabels',
      // campaignInboxes: 'campaignsMessages/getInboxes',
      // inboxesList: 'inboxes/getInboxes',
      // activeInbox: 'getSelectedInbox',
      // currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    showWhatsappModal() {
      if (!this.selectedInbox[0]) return false;
      // if (!this.showWhatsAppTemplatesModal) return false;
      return this.showWhatsAppTemplatesModal;
    },
    selectIdInbox() {
      if (this.selectedInbox[0] && this.selectedInbox[0].id)
        return this.selectedInbox[0].id;
      return '';
    },
    disabledMessage() {
      return this.isWhatsappChannel ? this.selectedInbox.length > 0 : true;
    },
    isWhatsappChannel() {
      return this.channel === 'whatsapp';
    },
    isEditing() {
      return this.campaignMsg && this.campaignMsg.id;
    },
    isDisabled() {
      return !this.fileImport;
    },
    titleHeader() {
      const label = this.isEditing
        ? 'CAMPAIGN.EDIT.TITLE'
        : 'CAMPAIGN.ADD.TITLE';
      return this.$t(label);
    },
    textBtnSend() {
      const label = this.isEditing
        ? 'CAMPAIGN.EDIT.UPDATE_BUTTON_TEXT'
        : 'CAMPAIGN.ADD.CREATE_BUTTON_TEXT';
      return this.$t(label);
    },
    inboxes() {
      if (this.isWhatsappChannel) {
        return this.$store.getters['inboxes/getInboxes'].filter(
          item => item.channel_type === 'Channel::Whatsapp'
        );
      }
      const apis = this.$store.getters['inboxes/getInboxes'].filter(
        item => item.channel_type === 'Channel::Api'
      );
      const TwilioInboxes = this.$store.getters['inboxes/getTwilioInboxes'];
      const GupshupInboxes = this.$store.getters['inboxes/getGupshupInboxes'];
      return [...TwilioInboxes, ...GupshupInboxes, ...apis];
    },
  },
  mounted() {
    this.$track(CAMPAIGNS_MESSAGES_EVENTS.OPEN_NEW_CAMPAIGN_MODAL, {
      type: this.campaignMessagesType,
    });
    this.setCampaign();
  },
  methods: {
    getVarsFile() {
      let heads = null;
      if (this.fileImport) {
        const getDoc = new FileReader();
        getDoc.addEventListener('load', () => {
          const rows = getDoc.result?.toString().split('\n');
          if (rows) {
            heads = rows[0]
              .split(',')
              .map(item => '$' + item.replaceAll('\r', ''));
            this.varsDoc = heads;
            // getDocHeaders?getDocHeaders(heads, rows):undefined
          }
        });
        if (this.fileImport) {
          getDoc.readAsText(this.fileImport);
        }
      }
    },
    setMessage(newMessage) {
      this.message = newMessage;
      this.showPopoutReplyBox();
    },
    async onSendWhatsAppReply(message) {
      this.whatsappTemplateData = message;
      this.message = message.message;
      this.hideWhatsappTemplatesModal();
    },
    openWhatsappTemplateModal() {
      if (this.selectedInbox[0] && this.selectedInbox[0].id)
        this.showWhatsAppTemplatesModal = true;
    },
    hideWhatsappTemplatesModal() {
      this.showWhatsAppTemplatesModal = false;
    },
    campaignInboxes() {
      if (!this.isEditing) return [];
      return this.$store.getters['campaignsMessages/getInboxes'](
        this.campaignMsg.id
      );
    },
    onClose() {
      this.$emit('on-close');
    },
    dateToTimeString(date) {
      let time = date.toISOString();
      return String(time).split('T')[1];
    },
    formatData(date) {
      const dateTemp = new Date(date);
      return dateTemp.toISOString();
    },
    dataData() {
      const campaignDetails = this.getCampaignDetails();
      // console.log('campaign campaignDetails ', campaignDetails);
      return campaignDetails;
    },
    getReturnFile(file) {
      this.fileImport = file;
      this.getVarsFile();
      this.onToggleImport();
    },
    discardFile() {
      this.fileImport = null;
      this.getVarsFile();
    },
    setCampaign() {
      if (this.isEditing) {
        const {
          description,
          message,
          minimum_interval,
          maximum_interval,
          initial_send_time,
          final_send_time,
          campaign_start,
          campaign_end,
        } = this.campaignMsg;
        this.description = description;
        this.message = message;

        // selectedInbox: [],
        this.selectedInbox = this.getInboxes();
        this.intervalInit = minimum_interval;
        this.intervalEnd = maximum_interval;

        this.timeInit = new Date(initial_send_time);
        this.timeEnd = new Date(final_send_time);

        // selectedAudience: [],
        // showImportModal: false,
        this.customDateRange = [
          new Date(campaign_start),
          new Date(campaign_end),
        ];
        // isPopoutReplyBox: false,
      }
    },
    getInboxes() {
      const inboxCampaign = this.campaignInboxes();
      if (inboxCampaign.length === 0) return [];
      const selectInboxIds = inboxCampaign.map(inbox => inbox.inbox_id);
      const result = this.inboxes.filter(label =>
        selectInboxIds.includes(label.id)
      );
      return result;
    },
    getCampaignDetails() {
      const [dateInit, dateEnd] = this.customDateRange;
      let campaignDetails = null;
      let dataDefault;
      if (this.isEditing) {
        const { id, media_type, media_path, contact_file, user_id } =
          this.campaignMsg;
        dataDefault = {
          id,
          media_type,
          media_path,
          contact_file,
          user_id,
        };
      }
      let additional_attributes = null;
      if (this.isWhatsappChannel && this.whatsappTemplateData) {
        additional_attributes = this.whatsappTemplateData.templateParams;
      }
      campaignDetails = {
        account_id: this.accountId,
        description: this.description,
        status: this.campaignMsg.status || 'SCHEDULED',
        inbox_ids: this.selectedInbox.map(inbox => inbox.id),
        tag_ids: this.selectedAudience.map(tag => tag.title),
        campaign_start: dateInit.toISOString(),
        campaign_end: dateEnd.toISOString(),
        minimum_interval: this.intervalInit,
        maximum_interval: this.intervalEnd,
        initial_send_time: this.dateToTimeString(this.timeInit),
        final_send_time: this.dateToTimeString(this.timeEnd),
        message: this.message,
        //  ...(this.isWhatsappChannel && additional_attributes),
        ...(dataDefault && dataDefault),
      };
      if (this.isWhatsappChannel)
        campaignDetails.additional_attributes = {
          template_params: additional_attributes,
        };
      return campaignDetails;
    },
    async sendCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // console.log('sendCampaign 22');
      try {
        const campaignDetails = this.getCampaignDetails();
        if (this.isEditing) {
          await this.$store.dispatch('campaignsMessages/update', {
            campaign: campaignDetails,
            file: this.fileImport,
          });
          this.showAlert(this.$t('CAMPAIGN.EDIT.API.SUCCESS_MESSAGE'));
        } else {
          await this.$store.dispatch('campaignsMessages/create', {
            campaign: campaignDetails,
            file: this.fileImport,
          });
          // if (this.fileImport) this.sendFile();
          // tracking this here instead of the store to track the type of campaign
          // this.$track(CAMPAIGNS_MESSAGES_EVENTS.CREATE_CAMPAIGN_MESSAGES);
          this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        }
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onChangeTimeInit(value) {
      this.timeInit = value;
    },
    onChangeTimeEnd(value) {
      this.timeEnd = value;
    },
    onToggleImport() {
      this.showImportModal = !this.showImportModal;
    },
    onCustomDateRangeChange(value) {
      this.customDateRange = value;
      // this.selectedGroupByFilter = this.validGroupBy;
      // this.emitChange();
    },
    showPopoutReplyBox() {
      if (this.isWhatsappChannel) {
        this.openWhatsappTemplateModal();
      } else {
        this.isPopoutReplyBox = !this.isPopoutReplyBox;
      }
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      @apply max-h-[25rem];
    }

    .reply-box {
      @apply border border-solid border-slate-75 dark:border-slate-600;
    }

    .reply-box .reply-box__top {
      @apply relative min-h-[27.5rem];
    }

    .reply-box__top .input {
      @apply min-h-[27.5rem];
    }

    .emoji-dialog {
      @apply absolute left-auto bottom-1;
    }
  }
}
</style>
<style>
.btn-import {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.mb4 {
  margin-bottom: 1.7rem;
}
.btn-import > .btn-icon-import {
  margin-top: 8px;
  height: 40px;
  width: 40px;
  border-radius: 0 4px 4px 0;
}
.iconImport {
  font-size: 24px;
}
</style>
