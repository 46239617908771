import Vue from 'vue';
import apiEvolution from '../../../api/apisControl/apiEvolution';
import types from '../../mutation-types';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import { getQueryApi, $tl } from 'dashboard/helper/auditlogHelper';
import {
  apiDataParse,
  formatV2ConfigWoot,
  formatV2Config,
} from 'dashboard/helper/APIControlHelper';

// const timeout = async() => {
//   new Promise(resolve => setTimeout(resolve, 3000))
// }

const VERSION_EVO_DEFAULT = 'nubla-evo-v1';

export const state = {
  settings: {
    reject_call: false,
    msg_call: '',
    groups_ignore: false,
    always_online: false,
    read_messages: false,
    read_status: false,
    sync_full_history: false,
  },
  currentInbox: {},
  tempInstance: {},
  instance: {},
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isConnecting: false,
    isSettingFetch: false,
    isWootFetch: false,
  },
  connection: {},
  integrationWoot: {},
};
export const getters = {
  uiFlags($state) {
    return $state.uiFlags;
  },
  getInstance($state) {
    return $state.instance;
  },
  getSettings($state) {
    return $state.settings;
  },
  getConnection($state) {
    return $state.connection;
  },
  getWoot($state) {
    return $state.integrationWoot;
  },
};

export const actions = {
  getInstance: async ({ commit }, { inbox }) => {
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isFetching: true });
    commit(types.SET_INSTANCE_INBOX, inbox);
    const { id: inboxId, additional_attributes } = inbox;
    const versionApi =
      (additional_attributes && additional_attributes.version) ||
      VERSION_EVO_DEFAULT;
    try {
      const response = await apiEvolution.getInstance(inboxId);
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isFetching: false });
      commit(types.SET_INSTANCE_EVO, { data: response.data, versionApi });
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isFetching: false });
    }
  },
  setInstance: async (
    { commit },
    { inbox, instanceName, number, integration, token, apikey }
  ) => {
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isCreating: true });
    commit(types.SET_INSTANCE_INBOX, inbox);
    // const newInstance = normalizeText(instanceName);
    const { id: inboxId, additional_attributes } = inbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;

    try {
      const response = await apiEvolution.setInstance({
        inboxId,
        // instanceName: newInstance,
        instanceName,
        integration,
        number,
        token,
        apikey,
      });
      if (response.status >= 400 || response.data.error) {
        commit(types.SET_INSTANCE_EVO_UI_FLAG, { isCreating: false });
        return response.data;
      }
      let instance = await apiEvolution.getInstance(inboxId);
      if (versionApi === 'nubla-evo-v1') {
        commit(types.SET_TEMP_INSTANCE_EVO, {
          data: instance.data.instance,
          version: versionApi,
        });
        return instance.data.instance;
      }
      if (versionApi === 'nubla-evo-v2') {
        commit(types.SET_TEMP_INSTANCE_EVO, {
          data: instance.data,
          version: versionApi,
        });
        return instance.data[0];
      }
      return instance.data;
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isCreating: false });
      return error;
    }
  },
  // eslint-disable-next-line no-unused-vars
  getTemplates: async ({ commit }, { inboxId, webhook_url }) => {
    try {
      const response = await apiEvolution.getTemplates({
        inboxId,
        webhook_url,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  // eslint-disable-next-line no-unused-vars
  sendTemplates: async ({ commit }, { inboxId, webhook_url, data }) => {
    try {
      const response = await apiEvolution.sendTemplates({
        inboxId,
        webhook_url,
        data,
      });
      return response;
    } catch (error) {
      return error;
    }
  },
  setTempInstance: async ({ commit, state: $state }) => {
    const { tempInstance, currentInbox } = $state;
    const versionApi =
      currentInbox.additional_attributes.version || VERSION_EVO_DEFAULT;
    commit(types.SET_INSTANCE_EVO, { data: tempInstance, versionApi });
    // commit(types.SET_INTEGRATION_WOOT, tempInstance);
  },
  getSettings: async ({ commit }, { instance }) => {
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: true });
    try {
      const response = await apiEvolution.getSettings(instance);
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: false });
      commit(types.SET_SETTINGS_EVO, response.data);
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: false });
    }
  },
  connectInstance: async (
    { commit, state: $state },
    { instance, automatic = true }
  ) => {
    const { id: inboxId, additional_attributes } = $state.currentInbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;
    const instanceNewStatus = 'connecting';
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: true });
    try {
      let logData = {
        action: 'connecting_instance',
        comment: 'api control, connectInstance',
      };
      const response = await apiEvolution.connectInstance({
        instance,
        inboxId,
        logData,
      });
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
      if (!automatic && !response.data.instance) {
        const { code, count } = response.data;
        const changes = {
          query: getQueryApi(response),
          old: { status: instance.status },
          new: {
            status: instanceNewStatus,
            code,
            count,
          },
        };
        logData.change = changes;
        apiEvolution.setLog(inboxId, logData);
      }
      if (response.data.instance) {
        commit(types.SET_INSTANCE_EVO_STATUS, response.data.instance.state);
        const instance_result = await apiEvolution.getInstance(inboxId);

        // const data = apiDataParse(versionApi, instance_result.data)

        commit(types.SET_INSTANCE_EVO, {
          versionApi,
          data: instance_result.data,
        });

        apiEvolution.setLog(inboxId, {
          action: 'connected_instance',
          change: {
            query: getQueryApi(response),
            old: { status: instanceNewStatus },
            new: { status: response.data.instance.state },
          },
          comment: 'api control, connectInstance',
        });
      } else {
        commit(types.SET_INSTANCE_EVO_STATUS, instanceNewStatus);
      }
      commit(types.CONNECT_INSTANCE_EVO, response.data);
    } catch (error) {
      window.bus.$emit(
        'newToastMessage',
        $tl('AUDIT_LOGS.API.ERROR_GENERAL_API')
      );
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
    }
  },
  logoutInstance: async ({ commit, state: $state }, { instance }) => {
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: true });
    const { id: inboxId } = $state.currentInbox;
    const instanceNewStatus = 'close';
    try {
      let logData = {
        action: 'stock_instance',
        comment: 'api control, logoutInstance',
      };
      const response = await apiEvolution.logoutInstance({
        instance,
        sendData: {},
        inboxId,
        logData,
      });

      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
      commit(types.LOGOUT_INSTANCE_EVO, response.data);

      const changes = {
        query: getQueryApi(response),
        old: { status: instance.status },
        new: { status: instanceNewStatus },
      };
      logData.change = changes;
      if (response.data.status === 'SUCCESS') {
        apiEvolution.setLog(inboxId, logData);
        commit(types.SET_INSTANCE_EVO_STATUS, instanceNewStatus);
        commit(types.SET_INSTANCE_PROP, { key: 'owner', value: undefined });
      }
    } catch (error) {
      window.bus.$emit(
        'newToastMessage',
        $tl('AUDIT_LOGS.API.ERROR_GENERAL_API')
      );
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
    }
  },
  restartInstance: async ({ commit, state: $state }, { instance }) => {
    const { id: inboxId } = $state.currentInbox;
    const instanceNewStatus = 'connecting';
    const oldStatus = instance.status;
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: true });
    try {
      let logData = {
        action: 'reboot_instance',
        comment: 'api control, restartInstance',
      };
      const logout = await apiEvolution.logoutInstance({
        instance,
        inboxId,
        logData,
      });
      commit(types.CONNECT_INSTANCE_EVO, undefined);
      if (logout.data.status === 'SUCCESS') {
        commit(types.SET_INSTANCE_EVO_STATUS, 'close');
      }
      const changes = {
        query: getQueryApi(logout),
        old: { status: oldStatus },
        new: { status: instanceNewStatus },
      };
      logData.change = changes;
      const start = await apiEvolution.connectInstance({
        instance,
        inboxId,
        logData,
      });
      commit(types.CONNECT_INSTANCE_EVO, start.data);
      commit(types.SET_INSTANCE_EVO_STATUS, instanceNewStatus);

      apiEvolution.setLog(inboxId, logData);

      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
    } catch (error) {
      window.bus.$emit(
        'newToastMessage',
        $tl('AUDIT_LOGS.API.ERROR_GENERAL_API')
      );
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isConnecting: false });
    }
  },
  setSettings: async ({ commit, state: $state }, { instance, settings }) => {
    const { id: inboxId, additional_attributes } = $state.currentInbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: true });
    try {
      let logData = {
        action: 'set_settings_instance',
        comment: 'api control, setSettings',
      };

      let settingsData = settings;
      if (versionApi === 'nubla-evo-v2')
        settingsData = formatV2Config(settings);

      const response = await apiEvolution.setSettings({
        instance,
        sendData: settingsData,
        inboxId,
        logData,
      });
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: false });
      const changes = {
        query: getQueryApi(response),
        old: {
          instanceName: instance.instanceName,
          settings: $state.settings,
        },
        new: response.data.settings,
      };
      logData.change = changes;
      apiEvolution.setLog(inboxId, logData);
      commit(types.SET_SETTINGS_EVO, response.data.settings.settings);
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isSettingFetch: false });
      throw new Error(error);
    }
  },
  getWoot: async ({ commit }, { instance }) => {
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: true });
    try {
      const response = await apiEvolution.getIntegrationWoot(instance);
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: false });
      commit(types.SET_INTEGRATION_WOOT, response.data);
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: false });
    }
  },
  setWoot: async ({ commit, state: $state }, { instance, settings }) => {
    const { id: inboxId, additional_attributes } = $state.currentInbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;
    commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: true });
    try {
      let logData = {
        action: 'set_woot_instance',
        comment: 'api control, setWoot',
      };

      let settingsData = settings;
      if (versionApi === 'nubla-evo-v2')
        settingsData = formatV2ConfigWoot(settings);

      const response = await apiEvolution.setIntegrationWoot({
        instance,
        sendData: settingsData,
        inboxId,
        logData,
      });

      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: false });
      const changes = {
        query: getQueryApi(response),
        old: $state.integrationWoot,
        new: response.data,
      };
      logData.change = changes;
      commit(types.SET_INTEGRATION_WOOT, response.data);
      apiEvolution.setLog(inboxId, logData);
      // setLog(inboxId, { action, change, comment })
      return response.data;
    } catch (error) {
      commit(types.SET_INSTANCE_EVO_UI_FLAG, { isWootFetch: false });
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.SET_INSTANCE_EVO_UI_FLAG]($state, data) {
    Vue.set($state, 'uiFlags', { ...$state.uiFlags, ...data });
  },
  [types.SET_INSTANCE_INBOX]: ($state, data) => {
    Vue.set($state, 'currentInbox', data);
  },
  [types.SET_INSTANCE_EVO]: ($state, { data, versionApi }) => {
    let dataFormat = data;
    if (!data.status && !data.error) {
      dataFormat = apiDataParse(versionApi, 'getInstance', data);
    }

    Vue.set($state, 'instance', dataFormat);
  },
  [types.SET_TEMP_INSTANCE_EVO]: ($state, { data, versionApi }) => {
    const dataFormat = apiDataParse(versionApi, 'getInstance', data);
    Vue.set($state, 'tempInstance', dataFormat);
    // Vue.set($state, 'tempInstance', data);
  },
  [types.SET_INSTANCE_PROP]: ($state, { key, value }) => {
    Vue.set($state.instance, key, value);
  },
  [types.EDIT_INSTANCE_EVO]: MutationHelpers.update,
  [types.SET_SETTINGS_EVO]: ($state, data) => {
    const { additional_attributes } = $state.currentInbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;
    const dataFormat = apiDataParse(versionApi, 'getSettings', data);
    Vue.set($state, 'settings', dataFormat);
  },
  [types.CONNECT_INSTANCE_EVO]: ($state, data) => {
    Vue.set($state, 'connection', data);
  },
  [types.LOGOUT_INSTANCE_EVO]: ($state, data) => {
    Vue.set($state, 'connection', data);
  },
  [types.SET_INSTANCE_EVO_STATUS]: ($state, status) => {
    Vue.set($state.instance, 'status', status);
  },
  [types.SET_INTEGRATION_WOOT]: ($state, data) => {
    const { additional_attributes } = $state.currentInbox;
    const versionApi = additional_attributes.version || VERSION_EVO_DEFAULT;
    const dataFormat = apiDataParse(versionApi, 'getWoot', data);
    Vue.set($state, 'integrationWoot', dataFormat);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
