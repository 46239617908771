/* global axios */
import axios2 from 'axios';

const DEFAULT_API_VERSION = 'v1';

class ApiEvolutionApi {
  constructor() {
    this.axios2 = axios2;
    this.baseURL = '';
    this.accountId = '';
    this.axiosApi = undefined;
  }

  setUrlAxiosApi(url, apikey) {
    this.baseURL = url;
    this.axiosApi = axios2.create({
      baseURL: this.baseURL,
    });
    this.axiosApi.defaults.headers.apikey = apikey;
  }

  errorInterceptor({ apiAxios, inboxId, logData, sendData }) {
    let apiClient = apiAxios;
    apiClient.interceptors.response.use(
      response => {
        // with status 2xx
        return response;
      },
      error => {
        // without status 2xx
        const logError = {
          message: error.message,
          status: error.response ? error.response.status : 'Network Error',
          data: error.response ? error.response.data : null,
          timestamp: new Date().toISOString(),
        };

        const { url, method, baseURL } = error.config;
        const urlComplete = baseURL + url;

        const query = {
          status: error.response ? error.response.status : 'Network Error',
          url: urlComplete,
          method: method,
        };

        const { action, comment } = logData;

        const data_audit = {
          action_audit: 'error_' + action,
          audited_changes: {
            query: query,
            sendData,
            response: logError,
          },
          comment,
        };
        axios.post(
          `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/create_log`,
          data_audit
        );

        return Promise.reject(error);
      }
    );
    return apiClient;
  }

  axiosEvo2({ instance, inboxId, logData, sendData }) {
    const { apikey, token, serverUrl } = instance;
    const key = apikey || token;

    const url = serverUrl || this.baseURL;

    this.axiosApi = axios2.create({
      baseURL: url,
    });
    this.axiosApi.defaults.headers.apikey = key;
    this.axiosApi = this.errorInterceptor({
      apiAxios: this.axiosApi,
      inboxId,
      logData,
      sendData,
    });
    return this.axiosApi;
  }

  accountIdFromRoute() {
    const isInsideAccountScopedURLs =
      window.location.pathname.includes('/app/accounts');
    if (isInsideAccountScopedURLs) {
      this.accountId = window.location.pathname.split('/')[3];
    }
    return this.accountId;
  }

  setInstance({ inboxId, instanceName, integration, number, token, apikey }) {
    return axios.post(
      `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/create_intance_evo`,
      {
        instanceName,
        integration,
        ...(apikey && { apikey }),
        ...(integration === 'WHATSAPP-BUSINESS' && { number }),
        ...(integration === 'WHATSAPP-BUSINESS' && { token }),
      }
    );
  }

  async getTemplates({ inboxId, webhook_url }) {
    const result = await axios.post(
      `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/get_templates`,
      {
        webhook_url,
      }
    );
    return result?.data;
  }

  async sendTemplates({ inboxId, webhook_url, data }) {
    const result = await axios.post(
      `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/send_templates`,
      {
        webhook_url,
        data,
      }
    );
    return result?.data;
  }

  getInstance(inboxId) {
    return axios.get(
      `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/api_instance`
    );
  }

  setLog(inboxId, { action, change, comment }) {
    const data_audit = {
      action_audit: action,
      audited_changes: change,
      comment,
    };
    return axios.post(
      `/api/${DEFAULT_API_VERSION}/accounts/${this.accountIdFromRoute()}/inboxes/${inboxId}/create_log`,
      data_audit
    );
  }

  getSettings(instance) {
    const { instanceName } = instance;
    return this.axiosApi.get(`/settings/find/${instanceName}`);
  }

  setSettings({ instance, sendData, inboxId, logData }) {
    const { instanceName } = instance;
    const apiAxios = this.axiosEvo2({ instance, inboxId, logData, sendData });
    return apiAxios.post(`/settings/set/${instanceName}`, sendData);
  }

  statusInstance(instance) {
    const { instanceName } = instance;
    return this.axiosApi.get(`/instance/connectionState/${instanceName}`);
  }

  connectInstance({ instance, inboxId, logData }) {
    const { instanceName } = instance;
    const apiAxios = this.axiosEvo2({ instance, inboxId, logData });
    return apiAxios.get(`/instance/connect/${instanceName}`);
  }

  logoutInstance({ instance, inboxId, logData }) {
    const { instanceName } = instance;
    const apiAxios = this.axiosEvo2({ instance, inboxId, logData });
    return apiAxios.delete(`/instance/logout/${instanceName}`);
  }

  restartInstance({ instance, inboxId, logData }) {
    const { instanceName } = instance;
    const apiAxios = this.axiosEvo2({ instance, inboxId, logData });
    return apiAxios.put(`/instance/restart/${instanceName}`);
  }

  getIntegrationWoot(instance) {
    const { instanceName } = instance;
    return this.axiosApi.get(`/chatwoot/find/${instanceName}`);
  }

  setIntegrationWoot({ instance, sendData, inboxId, logData }) {
    const { instanceName, name } = instance;
    const instance_Name = instanceName || name;
    const apiAxios = this.axiosEvo2({ instance, inboxId, logData, sendData });
    return apiAxios.post(`/chatwoot/set/${instance_Name}`, sendData);
  }
}

export default new ApiEvolutionApi();
