<template>
  <div class="h-auto overflow-auto flex flex-col w-full">
    <woot-modal-header :header-title="title" />
    <div class="w-full">
      <form
        class="flex flex-col w-full pt-1"
        style="padding-bottom: 1rem; padding-top: 0.5rem"
        @on-toggle-create="onToggleCreate"
        @submit.prevent="() => sendSchedule('CONFIRMED')"
      >
        <div class="div-form">
          <text-label-form
            :title="$t('CALENDAR.INFORMATION.PROFESSIONAL.NAME')"
            :value="event.professional.name"
          />
        </div>
        <div class="div-form gap-4">
          <text-label-form
            :title="$t('CALENDAR.SCHEDULE.DAY')"
            :value="getDateDay(event.schedule_date_time)"
          />
          <text-label-form
            :title="$t('CALENDAR.SCHEDULE.SCHEDULE')"
            v
            :value="
              getIntervalTime(
                event.schedule_date_time,
                event.schedule_date_time_end
              )
            "
          />
        </div>
        <multiselect-contacts :contact="contact" @update="onUpdateSelect" />
        <!-- <div class="div-form">
          <div
            class="mt-1 multiselect-wrap--medium w-full"
            :class="{ error: $v.contact.$error }"
          >
            <label class="multiselect__label">
              {{ $t('CALENDAR.SCHEDULE.CONTACT.LABEL') }}
            </label>
            <multiselect
              v-model="contact"
              track-by="id"
              class="select-min"
              :placeholder="$t('CALENDAR.SCHEDULE.CONTACT.PLACEHOLDER')"
              selected-label
              :select-label="$t('CALENDAR.SCHEDULE.CONTACT.SELECT_PLACEHOLDER')"
              :deselect-label="$t('CALENDAR.SCHEDULE.CONTACT.REMOVE')"
              :max-height="160"
              label="name"
              :options="contactList"
              :allow-empty="true"
              :class="{ error: $v.contact.$error }"
              :custom-label="customContactLabel"
              @select="handleContactSelection"
            >
              <template slot="option" slot-scope="{ option }">
                <div>
                  {{ formatContactLabel(option) }}
                </div>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{
                  option && option.name !== undefined
                    ? formatContactLabel(option)
                    : ''
                }}
              </template>
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
              <template slot="afterList">
                <li role="option" class="multiselect__element">
                  <span
                    v-if="showAddNewContactButton"
                    class="multiselect__option multiselect__option--highlight add-new-contact-btn"
                    @click="handleAddNewContactClick"
                  >
                    {{ $t('CALENDAR.SCHEDULE.CONTACT.NEW') }}
                  </span>
                </li>
              </template>
            </multiselect>
          </div>
        </div> -->
        <div :class="{ 'div-form': !isMicro, 'content-micro': isMicro }">
          <div :class="{ 'mt-1 multiselect-wrap--medium w-full': !isMicro }">
            <label v-if="!isMicro" class="multiselect__label">
              {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
            </label>
            <multiselect
              v-model="inbox"
              :options="inboxes"
              track-by="id"
              label="name"
              class="mb-0-important"
              :class="{ 'multiselect-micro-line': isMicro }"
              :placeholder="$t('CAMPAIGN.ADD.FORM.INBOX.LABEL')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              :internal-search="true"
              :preserve-search="true"
              :close-on-select="true"
              :clear-on-select="false"
              :hide-selected="true"
              @blur="$v.inbox.$touch"
              @select="$v.inbox.$touch"
            >
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
          </div>
        </div>
        <div :class="{ 'div-form': !isMicro, 'content-micro': isMicro }">
          <div :class="{ 'mt-1 multiselect-wrap--medium w-full': !isMicro }">
            <label :class="{ error: $v.markingType.$error }">
              {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.LABEL') }}
            </label>
            <select v-model="markingType" class="text-sm">
              <option
                v-for="typeItem in markingTypes"
                :key="typeItem.id"
                :value="typeItem.id"
                class="text-sm"
              >
                {{ typeItem.name }}
              </option>
            </select>
            <span v-if="$v.markingType.$error" class="message">
              {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.ERROR') }}
            </span>
          </div>
        </div>
        <div class="form-observation">
          {{ $t('CALENDAR.SCHEDULE.OBSERVATION.LABEL') }}
        </div>
        <div
          class="flex flex-col mb-2 p-4 border border-solid border-slate-75 dark:border-slate-700 overflow-hidden rounded-md flex-grow shadow-sm bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100"
          style="padding-top: 0px"
        >
          <woot-message-editor
            v-model="observation"
            class="input--note"
            :placeholder="$t('CALENDAR.SCHEDULE.OBSERVATION.PLACEHOLDER')"
            :enable-suggestions="false"
          />
        </div>
        <div class="div-form justify-end py-2 px-0 w-full">
          <woot-button variant="clear" @click.prevent="onClose">
            {{ $t('SCHEDULE.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <div class="button-group">
            <woot-button
              :is-loading="uiFlags.isCreating"
              @click.prevent="() => sendSchedule('SCHEDULED')"
            >
              {{ textBtnSend }}
            </woot-button>
            <woot-button
              ref="arrowDownButton"
              icon="chevron-up"
              emoji="🔼"
              class="arrow-button"
              @click="openDropdown"
            />
          </div>
          <div
            v-if="showActionsDropdown"
            v-on-clickaway="closeDropdown"
            class="dropdown-pane dropdown-pane--open dropdown-pane--up"
          >
            <woot-dropdown-menu>
              <woot-dropdown-item>
                <woot-button
                  variant="clear"
                  color-scheme="secondary"
                  size="small"
                >
                  {{ $t('CALENDAR.SCHEDULE.BUTTON_SCHEDULED_CONFIRMED') }}
                </woot-button>
              </woot-dropdown-item>
            </woot-dropdown-menu>
          </div>
        </div>
        <!-- <create-contact
          :show="showCreateModal"
          @cancel="onToggleCreate"
          @contact-created="onContactCreated"
        /> -->
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
// import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact.vue';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import dateMixin from 'dashboard/mixins/dateMixin';
import TextLabelForm from '../../ui/TextLabelForm.vue';
import MultiselectContacts from 'dashboard/components/widgets/forms/MultiselectContacts.vue';

export default {
  components: {
    TextLabelForm,
    // CreateContact,
    WootMessageEditor,
    MultiselectContacts,
  },
  mixins: [clickaway, alertMixin, dateMixin, eventListenerMixins],
  props: {
    title: {
      type: String,
      default: '',
    },
    event: {
      type: Object,
      default: () => ({}),
    },
    isMicro: {
      type: Boolean,
      default: false,
    },
    selectedInbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      contact: this.event.contact || {},
      inbox: [] || this.event.inbox,
      observation: this.event.observation ? this.event.observation : '',
      showCreateModal: false,
      showAddNewContactButton: false,
      showActionsDropdown: false,
      markingType: 'PRESENTIAL',
      markingTypes: [
        { id: 'PRESENTIAL', name: this.$t('SCHEDULE.MARKING_TYPE.PRESENTIAL') },
        { id: 'VIRTUAL', name: this.$t('SCHEDULE.MARKING_TYPE.VIRTUAL') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'schedules/getUIFlags',
      contactList: 'contacts/getContacts',
    }),
    textBtnSend() {
      return this.$t('CALENDAR.SCHEDULE.BUTTON_SAVE');
    },
    selectedMarkingType() {
      return this.markingTypes.find(
        item => item.name.toUpperCase() === this.event.markingType
      ).id;
    },
    inboxes() {
      const WhatsappInboxes = this.$store.getters['inboxes/getInboxes'].filter(
        item => item.channel_type === 'Channel::Whatsapp'
      );
      const apis = this.$store.getters['inboxes/getInboxes'].filter(
        item => item.channel_type === 'Channel::Api'
      );
      const TwilioInboxes = this.$store.getters['inboxes/getTwilioInboxes'];
      const GupshupInboxes = this.$store.getters['inboxes/getGupshupInboxes'];
      return [...TwilioInboxes, ...GupshupInboxes, ...WhatsappInboxes, ...apis];
    },
  },
  validations() {
    return {
      contact: {
        required,
      },
      markingType: {},
      inbox: {
        required,
      },
    };
  },
  mounted() {
    if (this.event.inbox) {
      this.inbox = this.event.inbox;
    }
    if (this.event.contact) {
      this.onUpdateSelect(this.event.contact);
    }
    //   this.$store.dispatch('contacts/getAll');
  },
  methods: {
    onUpdateSelect(selectContact) {
      this.contact = selectContact;
    },
    onClose() {
      this.$emit('on-close');
    },
    formatContactLabel(contact) {
      const phone = contact.phone_number !== null ? contact.phone_number : '';
      return `${contact.name} ${phone}`;
    },
    customContactLabel(option) {
      this.showAddNewContactButton = true;
      const phone = option.phone_number !== null ? option.phone_number : '';
      return `${option.name} ${phone}`;
    },
    handleContactSelection(option) {
      if (option.id === 'new') {
        this.showCreateModal = true;
      }
    },
    handleAddNewContactClick() {
      this.showCreateModal = true;
    },
    onContactCreated(createdContact) {
      this.contact = createdContact;
      this.showCreateModal = false;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    openDropdown(event) {
      event.preventDefault();
      event.stopPropagation();
      this.showActionsDropdown = true;
    },
    async sendSchedule(status) {
      this.$v.$touch();
      // console.log('event edit agendar ', this.event);
      if (this.$v.$invalid || this.contact.id === 'new') {
        return;
      }
      // const {professional_id, } = this.event;
      let dataSend = {
        id: this.event.id,
        professional_id: this.event.professional_id,
        inbox_id: this.inbox.id,
        // schedule_date_time: "2019-08-24T14:15:22Z",
        status: status,
        observation: this.observation,
        contact_id: this.contact.id,
        changing: true,
        marking_selected: this.markingType,
      };
      try {
        await this.$store.dispatch('schedules/update', dataSend);
        this.showAlert(this.$t('SCHEDULE.ADD.API.SUCCESS_MESSAGE'));
        // this.onClose();
        this.$emit('on-closes');
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
  },
};
</script>
<style lang="scss">
.div-form {
  @apply flex flex-row gap-2;
}
.content-micro {
  width: 300px;
}
.dropdown-pane--up {
  bottom: 16%;
}
.form-observation {
  --tw-text-opacity: 1;
  color: hsl(198 6.6% 15.8% / var(--tw-text-opacity));
}
.mb-0-important {
  margin-bottom: 0px !important;
}
.multiselect-micro-line {
  min-height: 30px !important;
  height: 30px !important;
  .multiselect__select {
    min-height: 30px;
    height: 30px;
    padding-top: 5px;
  }
  .multiselect__tags {
    min-height: 30px;
    height: 30px;
    padding: 0px 30px 0 4px;
    border-width: 0 0 1px 0;
    border-radius: 4px;
    .multiselect__input {
      height: 30px;
    }
    .multiselect__single {
      padding: 4px;
    }
    .multiselect__placeholder {
      padding-top: 4px;
    }
  }
}
.multiselect .multiselect__placeholder {
  color: hsl(198 6.6% 15.8% / var(--tw-text-opacity));
}
</style>
