<template>
  <div>
    <p v-if="varsDoc" class="variables-label">
      {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_AVAILABLE_DOC') }}
    </p>
    <div class="flex ml-2">
      <button
        v-for="varItem in varsDoc"
        :key="varItem"
        class="button secondary mx-1"
        @click="onSelectVar(varItem)"
      >
        {{ varItem }}
      </button>
    </div>
    <div class="bottom-box">
      <div class="left-wrap">
        <woot-button
          v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
          :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
          icon="emoji"
          emoji="😊"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          @click="toggleEmojiPicker"
        />
        <!-- <woot-button
        v-if="showEditorToggle"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_FORMAT_ICON')"
        icon="quote"
        emoji="🖊️"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="$emit('toggle-editor')"
      /> -->
        <woot-button
          v-if="hasWhatsappTemplates"
          v-tooltip.top-end="'Whatsapp Templates'"
          icon="whatsapp"
          color-scheme="secondary"
          variant="smooth"
          size="small"
          :title="'Whatsapp Templates'"
          @click="$emit('selectWhatsappTemplate')"
        />
      </div>
      <div class="right-wrap">
        <woot-button size="small" :is-disabled="isSendDisabled" @click="onSend">
          {{ sendButtonText }}
        </woot-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as ActiveStorage from 'activestorage';
import { hasPressedAltAndAKey } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { mapGetters } from 'vuex';

export default {
  name: 'ReplyBottomPanelCampaign',
  components: {},
  mixins: [eventListenerMixins, uiSettingsMixin],
  props: {
    onSend: {
      type: Function,
      default: () => {},
    },
    message: {
      type: String,
      default: '',
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    showEditorToggle: {
      type: Boolean,
      default: false,
    },
    hasWhatsappTemplates: {
      type: Boolean,
      default: false,
    },
    refInputMsg: {
      type: Object,
      default: () => {},
    },
    varsDoc: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    showAttachButton() {
      return this.showFileUpload;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    onSelectVar(varItem) {
      const { selectionStart, selectionEnd } =
        this.refInputMsg.messageInput.$el;
      const newMessage = this.insertFormat(
        varItem,
        selectionStart,
        selectionEnd
      );
      this.$emit('edit-text', newMessage);
    },
    insertFormat(emoji, selectionStart, selectionEnd) {
      const { message } = this;
      const newMessage =
        message.slice(0, selectionStart) +
        emoji +
        message.slice(selectionEnd, message.length);
      return newMessage;
    },
    handleKeyEvents(e) {
      if (hasPressedAltAndAKey(e)) {
        this.$refs.upload.$children[1].$el.click();
      }
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
    },
    replaceText(text) {
      this.$emit('replace-text', text);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  @apply flex justify-between py-3 px-4;
}

.left-wrap {
  @apply items-center flex gap-2;
}

.right-wrap {
  @apply flex;
}

.variables-label {
  @apply ml-3;
}

::v-deep .file-uploads {
  label {
    @apply cursor-pointer;
  }
  &:hover button {
    @apply dark:bg-slate-800 bg-slate-100;
  }
}

.modal-mask {
  @apply text-slate-600 dark:text-slate-200 bg-white_transparent dark:bg-black_transparent flex-col;
}

.icon {
  @apply text-[5rem];
}
</style>
