<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.API_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.API_CHANNEL.DESC')"
    />
    <div class="mx-0 flex flex-wrap">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            class="mb-2"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
          />
          <div v-if="nameInstFormatted" class="mb-2">
            <span class="message">
              {{ $t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL') }}:
              <strong> {{ nameInstFormatted }} </strong>
            </span>
          </div>
        </label>
        <label>
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.VERSION.LABEL') }}
          <select v-model="versionEvo">
            <option
              v-for="version in evoVersions"
              :key="version.value"
              :value="version.value"
            >
              {{ version.title }}
            </option>
          </select>
        </label>

        <div>
          <evolution
            v-if="versionEvo === 'nubla-evo-v1'"
            :channel-name="nameInstFormatted"
          />
          <evolution-v2
            v-if="versionEvo === 'nubla-evo-v2'"
            :channel-name="nameInstFormatted"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { normalizeText } from 'dashboard/helper/commons.js';
import inboxApiMixin, { EVO_VERSION } from 'shared/mixins/InboxApiMixin';
import PageHeader from '../../../SettingsSubPageHeader.vue';
import Evolution from './Evo.vue';
import EvolutionV2 from './EvoV2.vue';

const VERSION_EVO_DEFAULT = 'nubla-evo-v1';
export default {
  components: {
    PageHeader,
    Evolution,
    EvolutionV2,
  },
  mixins: [inboxApiMixin],
  data() {
    return {
      channelName: '',
      versionEvo: VERSION_EVO_DEFAULT,
      evoVersions: EVO_VERSION,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    nameInstFormatted() {
      return normalizeText(this.channelName);
    },
  },
  methods: {},
};
</script>

<style></style>
