<template>
  <div class="custom-attributes--panel">
    <!-- {{ resumo }}
    ----
    {{ $v.$invalid }} -->
    <custom-attributes-form-item
      v-for="attribute in dataAttributes"
      :key="attribute.id"
      :attribute-key="attribute.attribute_key"
      :attribute-type="attribute.attribute_display_type"
      :values="attribute.attribute_values"
      :label="capitalize(attribute.attribute_display_name)"
      :placeholder="capitalize(attribute.attribute_filling_tip)"
      :can-manage="canManage(attribute.attribute_who_can_manage)"
      :required="attribute.attribute_required"
      :icon="attribute.icon"
      :value="attribute.value"
      :class="attributeClass"
      :class-content="''"
      :is-edit="isEdit"
      :active-valid-form="activeValidForm"
      @update="onUpdate"
    />
  </div>
</template>

<script>
import CustomAttributesFormItem from './CustomAttributesFormItem.vue';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    CustomAttributesFormItem,
  },
  mixins: [alertMixin],
  props: {
    attributeType: { type: String, default: 'professional_attribute' },
    attributeClass: {
      type: String,
      default: '',
    },
    customAttributes: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    isEdit: { type: Boolean, default: false },
    activeValidForm: { type: Boolean, default: false },
    // attributes2: { type: Object, default: {} },
  },
  data() {
    const varAttributes = this.$store.getters[
      'attributes/getAttributesByModel'
    ](this.attributeType);
    let varsLabels = {};
    varAttributes.map(item => {
      varsLabels[item.attribute_key] = '';
      return '';
    });
    return {
      ...varsLabels,
      base: '',
      dataAttributes: {},
      varsLabels,
      varAttributes,
    };
  },
  validations() {
    // const varAttributes = this.$store.getters[
    //   'attributes/getAttributesByModel'
    // ](this.attributeType);
    // const varsLabels = varAttributes.map(item => item.attribute_display_name);
    let validators = {};
    this.varAttributes.map(item => {
      validators[item.attribute_key] = {
        ...(item.attribute_required ? { required } : {}),
      };
      return null;
    });
    // console.log('Requireds ', validators);
    return {
      //  ...varsLabels,
      ...validators,
    };
  },
  computed: {
    attributes() {
      return this.$store.getters['attributes/getAttributesByModel'](
        this.attributeType
      );
    },
  },
  mounted() {
    // this.setCustomAttributes();
    // console.log('ola ', this.customAttributes);
    this.setDataAttributes();
  },
  methods: {
    canManage(whoCan) {
      if (whoCan === 'user') return true;
      if (whoCan === 'admin') {
        if (this.user.type === 'admin' || this.user.type === 'SuperAdmin')
          return true;
        return false;
      }
      return false;
    },
    capitalize(word) {
      if (word === '' || word === null) return '';
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    setDataAttributes() {
      let result = [];
      result = this.attributes.map(attribute => {
        return {
          ...attribute,
          value: this.customAttributes[attribute.attribute_key]
            ? this.customAttributes[attribute.attribute_key]
            : '',
        };
      });
      this.dataAttributes = result;
    },
    // setCustomAttributes() {
    //   let attributeResult = {};
    //   this.attributes.map(attribute => {
    //     if (this.customAttributes[attribute.attribute_key]) {
    //       attributeResult[attribute.attribute_key] =
    //         this.customAttributes[attribute.attribute_key];
    //       return '';
    //     }
    //     attributeResult[attribute.attribute_key] = '';
    //     return '';
    //   });
    //   this.dataAttributes = attributeResult;
    // },
    async onUpdate(key, value) {
      const tempDataAttributes = { ...this.customAttributes, [key]: value };
      this.$emit('update', tempDataAttributes);
      this[key] = value;
      // console.log('this.$v.$invalid', this.$v.$invalid);
      this.$emit('formValid', !this.$v.$invalid);
    },
  },
};
</script>
