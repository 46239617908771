<template>
  <div
    class="flex flex-col h-full m-0 p-2 flex-1 bg-white dark:bg-slate-900"
    style="width: calc(100vw - 70px)"
  >
    <div
      v-if="auditLog && auditLog.created_at"
      class="flex justify-between mb-3"
    >
      <bread-crumbs :bread-crumbs="breadcrumb" />
      <div class="text-sm font-medium">
        {{ dateFormat(auditLog.created_at, 'MMM dd, yyyy hh:mm a') }}
      </div>
    </div>
    <div v-if="auditLog && agentList && agentList.length > 0" class="flex">
      <div class="flex flex-col w-1/4">
        <div class="text-woot-400 font-semibold">
          {{ $t('AUDIT_LOGS.DETAIL.SUMMARY.SUBTITLE') }}
        </div>

        <info-description
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.RELATION')"
          :value="auditLog.auditable_type"
        />
        <info-description
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.ITEM_NAME')"
          :value="inbox.name"
        />
        <info-description
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.USER')"
          :value="agent.name"
        />
        <info-description
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.ACTION')"
          :value="auditLog.action"
        />

        <info-description
          v-if="auditLog.version && auditLog.version !== ''"
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.VERSION')"
          :value="auditLog.version"
        />
        <info-description
          v-if="auditLog.comment && auditLog.comment !== ''"
          :description="$t('AUDIT_LOGS.DETAIL.SUMMARY.COMMENT')"
          :value="auditLog.comment"
        />

        <!-- Summary Changes
        <div v-if="false" class="flex flex-col font-medium w-1/4 max-w-25" >
          <b class="text-xs" >Mudanças:</b>
          <div class="pl-2" v-for="(value, key) in changes" :key="key">
            <info-row
              :value="`${key}: ${value}`"
              icon="checkmark"
            />
          </div>
        </div> 
        -->
      </div>
      <div class="flex flex-col w-2/3">
        <div class="text-woot-400 font-semibold">
          {{ generateLogText(auditLog) }}
        </div>
        <div v-if="queryApiData" class="flex gap-4">
          <info-description
            :description="$t('AUDIT_LOGS.DETAIL.QUERY.URL')"
            :value="queryApiData.url"
            :flex-col="true"
          />
          <info-description
            :description="$t('AUDIT_LOGS.DETAIL.QUERY.METHOD')"
            :value="queryApiData.method.toUpperCase()"
            :flex-col="true"
          />
          <info-description
            :description="$t('AUDIT_LOGS.DETAIL.QUERY.STATUS')"
            :value="statusQuery"
            :flex-col="true"
          />
        </div>
        <div v-if="changesOld && changesNew">
          <object-comparer :object-old="changesOld" :object-new="changesNew" />
        </div>
        <div v-else-if="changesSendData && changesResponse">
          <response-query-box
            :query="changesSendData"
            :response="changesResponse"
          />
        </div>
        <div v-else>
          {{ $t('AUDIT_LOGS.DETAIL.NO_DETAILS') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { diff } from 'deep-object-diff';
import ObjectComparer from './components/ObjectComparer.vue';
import ResponseQueryBox from './components/ResponseQueryBox.vue';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import {
  generateTranslationPayload,
  generateLogActionKey,
} from 'dashboard/helper/auditlogHelper';
import BreadCrumbs from 'dashboard/components/ui/BreadCrumbs.vue';
import InfoDescription from 'dashboard/components/ui/text/InfoDescription.vue';

export default {
  components: {
    ObjectComparer,
    ResponseQueryBox,
    BreadCrumbs,
    InfoDescription,
  },
  mixins: [alertMixin, timeMixin],
  computed: {
    ...mapGetters({
      auditLog: 'auditlogs/getAudit',
      uiFlags: 'auditlogs/getUIFlags',
      inboxes: 'inboxes/getInboxes',
      agentList: 'agents/getAgents',
    }),
    agent() {
      return this.$store.getters['agents/getAgent'](this.auditLog.user_id);
    },
    auditId() {
      return this.$route.params.id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.auditLog.auditable_id
      );
    },
    queryApiData() {
      return this.auditLog &&
        this.auditLog.audited_changes &&
        this.auditLog.audited_changes.query
        ? this.auditLog.audited_changes.query
        : undefined;
    },
    statusQuery() {
      if (this.queryApiData) {
        const { status, statusText } = this.queryApiData;
        const text = statusText ? `(${statusText})` : '';
        return `${status} ${text}`;
      }
      return '';
    },
    changesSendData() {
      let sendData =
        this.auditLog.audited_changes && this.auditLog.audited_changes.sendData
          ? this.auditLog.audited_changes.sendData
          : undefined;
      return sendData;
    },
    changesResponse() {
      return this.auditLog &&
        this.auditLog.audited_changes &&
        this.auditLog.audited_changes.response
        ? this.auditLog.audited_changes.response
        : undefined;
    },
    changesOld() {
      return this.auditLog &&
        this.auditLog.audited_changes &&
        this.auditLog.audited_changes
        ? this.auditLog.audited_changes.old
        : undefined;
    },
    changesNew() {
      return this.auditLog &&
        this.auditLog.audited_changes &&
        this.auditLog.audited_changes
        ? this.auditLog.audited_changes.new
        : undefined;
    },
    breadcrumb() {
      const action = this.getFormatAction(this.auditLog.action);
      const data = [
        { label: 'Audits logs', urlName: 'auditlogs_list' },
        this.auditLog.action.includes('_instance') && {
          label: 'Api',
          urlName: 'settings_inbox_api_show',
          params: { inboxId: this.inbox.id },
        },
        { label: action },
      ].filter(Boolean);
      return data;
    },
    changes() {
      return diff(this.changesOld, this.changesNew);
    },
  },
  watch: {
    // '$route'(to, from) {
    //   // this.auditId = to.params.id;
    //   // console.log('(to, from)', to, from)
    // }
    auditId() {
      this.$store.dispatch('auditlogs/find', this.auditId);
    },
  },
  mounted() {
    this.$store.dispatch('auditlogs/find', this.auditId);
    if (!this.agentList || this.agentList.length === 0) {
      this.$store.dispatch('agents/get');
    }
  },
  methods: {
    getFormatAction(action) {
      return action.replaceAll('_', ' ');
    },
    generateLogText(auditLogItem) {
      let inboxName = '';
      if (auditLogItem.auditable_type === 'Inbox') {
        inboxName = this.inbox.name;
      }
      const translationPayload = generateTranslationPayload(
        auditLogItem,
        this.agentList
      );
      const translationKey = generateLogActionKey(auditLogItem);

      const translationData = { ...translationPayload, inboxName };
      return this.$t(translationKey, translationData);
    },
  },
};
</script>
<style scoped>
.max-w-25 {
  max-width: 25%;
}
</style>
