import { frontendURL } from '../../../../helper/URLHelper';

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: ['settings_account_campaigns', 'one_off', 'whatsapp'],
  menuItems: [
    {
      icon: 'arrow-swap',
      label: 'SITE',
      key: 'ongoingCampaigns',
      hasSubMenu: true,
      toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
      toStateName: 'settings_account_campaigns',
      children: [
        {
          icon: 'arrow-swap',
          label: 'ONGOING',
          key: 'ongoingCampaigns',
          hasSubMenu: false,
          toState: frontendURL(`accounts/${accountId}/campaigns/ongoing`),
          toStateName: 'settings_account_campaigns',
          useDictionary: true,
        },
        {
          key: 'oneOffCampaigns',
          icon: 'sound-source',
          label: 'ONE_OFF',
          hasSubMenu: false,
          toState: frontendURL(`accounts/${accountId}/campaigns/one_off`),
          toStateName: 'one_off',
          useDictionary: true,
        },
      ],
    },
    {
      icon: 'whatsapp',
      label: 'WHATSAPP',
      key: 'whatsappCampaigns',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/whatsapp`),
      toStateName: 'whatsapp',
    },
  ],
});

export default campaigns;
