<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal
    full-width
    :show.sync="show"
    :show-close-button="false"
    :on-close="onClose"
  >
    <div
      v-on-clickaway="onClose"
      class="bg-white dark:bg-slate-900 flex flex-col h-[inherit] w-[inherit]"
      @click="onClose"
    >
      <div class="items-center flex h-16 justify-between py-2 px-6 w-full">
        <div class="items-center flex justify-start min-w-[15rem]" @click.stop>
          <!-- <thumbnail :username="thumbnailName" :src="thumbnailAvatar" /> -->
          <div
            class="flex items-start flex-col justify-center ml-2 rtl:ml-0 rtl:mr-2"
          >
            <h3
              class="sub-block-title inline-block leading-[1.4] m-0 p-0 capitalize"
            >
              <span
                class="text-slate-800 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {{ thumbnailName }}
              </span>
            </h3>
            <!-- <span
              class="text-xs m-0 p-0 text-slate-400 dark:text-slate-200 overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {{ readableTime }}
            </span> -->
          </div>
        </div>
        <div
          class="items-center text-slate-700 dark:text-slate-100 flex font-semibold justify-start min-w-0 p-1 w-auto text-block-title"
        >
          <span
            class="text-slate-700 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis"
          >
            {{ fileNameFromDataUrl }}
          </span>
        </div>
        <div
          class="items-center flex gap-2 justify-end min-w-[15rem]"
          @click.stop
        >
          <woot-button
            size="large"
            color-scheme="secondary"
            variant="clear"
            icon="arrow-download"
            @click="onClickDownload"
          />
          <woot-button
            size="large"
            color-scheme="secondary"
            variant="clear"
            icon="dismiss"
            @click="onClose"
          />
        </div>
      </div>
      <div class="items-center flex h-full justify-center w-full">
        <div class="flex items-center flex-col justify-center w-full h-full">
          <div>
            <!-- <img
              :src="src"
              class="modal-image skip-context-menu my-0 mx-auto"
            /> -->
            <div @click.stop>
              <image-zoom
                :src="src"
                classes="modal-image skip-context-menu my-0 mx-auto"
              />
            </div>
            <!-- 
            <img
              v-if="isImage"
              :key="activeAttachment.message_id"
              :src="activeAttachment.data_url"
              class="modal-image skip-context-menu my-0 mx-auto"
              @click.stop
            />
            <video
              v-if="isVideo"
              :key="activeAttachment.message_id"
              :src="activeAttachment.data_url"
              controls
              playsInline
              class="modal-video skip-context-menu my-0 mx-auto"
              @click.stop
            />
            <audio
              v-if="isAudio"
              :key="activeAttachment.message_id"
              controls
              class="skip-context-menu"
              @click.stop
            >
              <source :src="`${activeAttachment.data_url}?t=${Date.now()}`" />
            </audio> -->
          </div>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { isEscape } from 'shared/helpers/KeyboardHelpers';
// import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import ImageZoom from 'dashboard/components/widgets/images/ImageZoom.vue';
export default {
  components: {
    // Thumbnail: () => import('dashboard/components/widgets/Thumbnail.vue'),
    ImageZoom,
  },
  // mixins: [eventListenerMixins, clickaway, timeMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    thumbnailName: {
      type: String,
      default: '',
    },
    thumbnailAvatar: {
      type: String,
      default: '',
    },
    customName: {
      type: String,
      default: '',
    },
  },
  computed: {
    fileNameFromDataUrl() {
      if (this.customName !== '') return this.customName;
      if (!this.src) return '';
      const fileName = this.src?.split('/').pop();
      return fileName || '';
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onClickDownload() {
      const link = document.createElement('a');
      link.href = this.src;
      link.download = `attachment.image`;
      link.click();
    },
    onKeyDownHandler(e) {
      if (isEscape(e)) {
        this.onClose();
      }
    },
  },
};
</script>
