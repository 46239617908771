/* global axios */
import ApiClient from './ApiClient';

class SCHEDULESAPI extends ApiClient {
  constructor() {
    super('schedules', { accountScoped: true });
  }

  createSchedules(data) {
    return axios.post(`${this.url}/generate`, { ...data });
  }

  getFilter(params) {
    return axios.get(`${this.url}/`, { params });
  }
}

export default new SCHEDULESAPI();
