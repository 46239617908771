<template>
  <div>
    <settings-section
      :title="$t('API_CONTROL.SUMMARY.TITLE')"
      :icon="'whatsapp'"
    >
      <div class="settings-item">
        <p>
          <span class="font-semibold">
            {{ $t('API_CONTROL.SUMMARY.STATUS') }}
          </span>
          {{ statusInbox }}
        </p>
        <p>
          <span class="font-semibold">
            {{ $t('API_CONTROL.SUMMARY.CHANNEL_TYPE') }}:
          </span>
          {{ $t('API_CONTROL.API') }} ::
          {{ apiNameVersion }}
        </p>
        <p>
          <span class="font-semibold">
            {{ $t('API_CONTROL.SUMMARY.INSTANCE_NAME') }}
          </span>
          {{ apiInstance.instanceName }}
        </p>
        <p>
          <span class="font-semibold">
            {{ $t('API_CONTROL.SUMMARY.LINKED_NUMBER') }}
          </span>
          {{ linkedPhoneNumber }}
        </p>
        <!-- <p>
          <span class="font-semibold">
            {{ $t('API_CONTROL.SUMMARY.LAST_STATUS') }}
          </span>
          {{ lastStatusDate }}
        </p> -->
        <div
          v-if="apiConnection && apiConnection.base64"
          class="flex items-center flex-col"
        >
          <img :src="apiConnection.base64" alt="QrCode" srcset="" />
          <p>
            {{ `${timeLeft} ${$t('API_CONTROL.SUMMARY.SECONDS_REMAINING')}` }}
          </p>
          <bar-progress :time-left="timeLeft" :total-time="timeReloadQr" />
          <p>
            {{ `${$t('API_CONTROL.SUMMARY.CONNECTED_HELP')}` }}
          </p>
        </div>

        <div class="flex flex-wrap gap-4">
          <woot-button
            v-if="status.includes('close')"
            type="button"
            :is-loading="uiFlags.isConnecting"
            size="medium"
            @click="onOpen(false)"
          >
            {{ $t('API_CONTROL.SUMMARY.ACTIONS.CONNECT') }}
          </woot-button>
          <woot-button
            v-if="status === 'open'"
            size="medium"
            color-scheme="alert"
            :is-loading="uiFlags.isConnecting"
            @click="onClosed"
          >
            {{ $t('API_CONTROL.SUMMARY.ACTIONS.DISCONNECT') }}
          </woot-button>
          <div v-if="status === 'connecting'" class="flex gap-4 w-full">
            <woot-button
              size="medium"
              color-scheme="warning w-full"
              :is-loading="uiFlags.isConnecting"
              @click="onConnecting"
            >
              {{ $t('API_CONTROL.SUMMARY.ACTIONS.RESTART_QR_CODE') }}
            </woot-button>
            <woot-button
              size="medium"
              color-scheme="alert w-full"
              :is-loading="uiFlags.isConnecting"
              @click="onClosed"
            >
              {{ $t('API_CONTROL.SUMMARY.ACTIONS.STOP_QR_CODE') }}
            </woot-button>
          </div>
        </div>
      </div>
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import inboxApiMixin from 'shared/mixins/InboxApiMixin';
import BarProgress from 'dashboard/components/widgets/chart/BarProgress.vue';
import { API_STATUS } from 'shared/constants/apiControl.js';

const TIME_RELOAD_QR = 40;

export default {
  components: {
    SettingsSection,
    BarProgress,
  },
  mixins: [inboxApiMixin],
  props: {
    status: { type: String, required: true },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    apiInstance: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showBorder: true,
      lastStatusDate: 'Seg. 18 de Outubro as 14:40',
      timeLeft: TIME_RELOAD_QR,
      intervalId: null,
      timeReloadQr: TIME_RELOAD_QR,
    };
  },
  computed: {
    ...mapGetters({
      apiConnection: 'apiEvolution/getConnection',
      apiQrCode: 'apiEvolution/getQrCode',
      uiFlags: 'apiEvolution/uiFlags',
    }),
    linkedPhoneNumber() {
      return this.apiInstance.owner
        ? this.apiInstance.owner.split('@')[0]
        : this.$t('API_CONTROL.SUMMARY.UNLINKED_NUMBER');
    },
    statusInbox() {
      return this.evoStatusLabel(this.status);
    },
    apiNameVersion() {
      if (
        this.inbox.additional_attributes &&
        this.inbox.additional_attributes.version
      ) {
        return this.inbox.additional_attributes.version
          .toUpperCase()
          .replaceAll('-', ' ');
      }
      return '';
    },
  },
  watch: {
    apiConnection() {
      clearInterval(this.intervalId);
      if (this.apiConnection && this.apiConnection.base64) {
        this.timeLeft = this.timeReloadQr;
        this.startTimer();
      }
    },
  },
  mounted() {
    if (this.status === API_STATUS.CONNECTING) this.onOpen(false);
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
        } else {
          this.onOpen();
        }
      }, 1000);
    },
    onOpen(automatic = true) {
      this.$store.dispatch('apiEvolution/connectInstance', {
        instance: this.apiInstance,
        automatic,
      });
    },
    onClosed() {
      this.$store.dispatch('apiEvolution/logoutInstance', {
        instance: this.apiInstance,
      });
    },
    onConnecting() {
      this.$store.dispatch('apiEvolution/restartInstance', {
        instance: this.apiInstance,
      });
    },
  },
};
</script>

<style></style>
