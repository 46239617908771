<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('CAMPAIGN.ADD.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addCampaign">
      <div class="w-full">
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <label class="editor-wrap">
          {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.LABEL') }}
          <woot-message-editor
            v-model="message"
            class="message-editor"
            :class="{ editor_warning: $v.message.$error }"
            :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            @blur="$v.message.$touch"
          />
          <span v-if="$v.message.$error" class="editor-warning__message">
            {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
          </span>
        </label>
        <label :class="{ error: $v.selectedInbox.$error }">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
          <select v-model="selectedInbox" @change="onChangeInbox($event)">
            <option v-for="item in inboxes" :key="item.name" :value="item.id">
              {{ item.name }}
            </option>
          </select>
          <span v-if="$v.selectedInbox.$error" class="message">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
          </span>
        </label>
        <div class="screen-selector flex">
          <label
            class="multiselect-wrap--small w-full"
            :class="{ error: $v.selectedAudience.$error }"
          >
            {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
            <multiselect
              v-model="selectedAudience"
              :options="audienceList"
              track-by="id"
              label="title"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :placeholder="$t('CAMPAIGN.ADD.FORM.AUDIENCE.PLACEHOLDER')"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
              @blur="$v.selectedAudience.$touch"
              @select="$v.selectedAudience.$touch"
            >
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
            <span v-if="$v.selectedAudience.$error" class="message">
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
            </span>
          </label>
          <div class="btn-import">
            <woot-button
              v-tooltip="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              :class-names="['btn-icon-import']"
              icon="upload"
              size="small"
              title="$t('IMPORT_CONTACTS.BUTTON_LABEL')"
              @click="onToggleImport"
            />
          </div>
        </div>
        <label class="w-full">
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-range-picker
            show-range
            class="no-margin auto-width"
            :value="customDateRange"
            :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
            :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
            @change="onCustomDateRangeChange"
          />
        </label>
        <div class="flex gap-1">
          <label class="w-full">
            {{ $t('CAMPAIGN.WHATSAPP.ADD.TIME_INIT.LABEL') }}
            <WootTimePicker
              :value="timeAt"
              :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.INTERVAL_MAX')"
              :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
              @change="onChangeTime"
            />
          </label>
          <label class="w-full">
            {{ $t('CAMPAIGN.WHATSAPP.ADD.TIME_END.LABEL') }}
            <WootTimePicker
              :value="timeAt"
              :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
              :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
              @change="onChangeTime"
            />
          </label>
        </div>
        <div class="flex gap-1">
          <woot-input
            v-model="timeOnPage"
            :label="$t('CAMPAIGN.WHATSAPP.ADD.INTERVAL_MIN.LABEL')"
            type="text"
            class="w-full"
            :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
            :error="
              $v.timeOnPage.$error
                ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
                : ''
            "
            :class="{ error: $v.timeOnPage.$error }"
            @blur="$v.timeOnPage.$touch"
          />
          <woot-input
            v-model="timeOnPage"
            :label="$t('CAMPAIGN.WHATSAPP.ADD.INTERVAL_MAX.LABEL')"
            type="text"
            class="w-full"
            :placeholder="$t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.PLACEHOLDER')"
            :error="
              $v.timeOnPage.$error
                ? $t('CAMPAIGN.ADD.FORM.TIME_ON_PAGE.ERROR')
                : ''
            "
            :class="{ error: $v.timeOnPage.$error }"
            @blur="$v.timeOnPage.$touch"
          />
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button :is-loading="uiFlags.isCreating">
            {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
          </woot-button>
          <woot-button variant="clear" @click.prevent="onClose">
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </div>
    </form>
    <woot-modal :show.sync="showImportModal" :on-close="onToggleImport">
      <import-contacts v-if="showImportModal" :on-close="onToggleImport" />
    </woot-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
// import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import ImportContacts from '../../../../contacts/components/ImportContacts.vue';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import WootTimePicker from 'dashboard/components/ui/TimePicker.vue';

export default {
  components: {
    WootDateRangePicker,
    // WootDateTimePicker,
    WootMessageEditor,
    ImportContacts,
    WootTimePicker,
  },
  mixins: [alertMixin],
  data() {
    return {
      title: '',
      message: '',
      selectedSender: 0,
      selectedInbox: null,
      endPoint: '',
      timeOnPage: 10,
      show: true,
      enabled: true,
      triggerOnlyDuringBusinessHours: false,
      scheduledAt: null,
      timeAt: null,
      selectedAudience: [],
      senderList: [],
      showImportModal: false,
      // customDateRange: [new Date(), new Date()],
      customDateRange: [],
    };
  },
  validations() {
    const commonValidations = {
      title: {
        required,
      },
      message: {
        required,
      },
      selectedInbox: {
        required,
      },
    };
    return {
      ...commonValidations,
      timeOnPage: {
        required,
      },
      selectedAudience: {
        isEmpty() {
          return !!this.selectedAudience.length;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      audienceList: 'labels/getLabels',
    }),
    inboxes() {
      const TwilioInboxes = this.$store.getters['inboxes/getTwilioInboxes'];
      const GupshupInboxes = this.$store.getters['inboxes/getGupshupInboxes'];
      const apis = this.$store.getters['inboxes/getInboxes'].filter(
        item => item.channel_type === 'Channel::Api'
      );
      return [...TwilioInboxes, ...GupshupInboxes, ...apis];
    },
    // sendersAndBotList() {
    //   return [
    //     {
    //       id: 0,
    //       name: 'Bot',
    //     },
    //     ...this.senderList,
    //   ];
    // },
  },
  methods: {
    async addCampaign() {
      return '';
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    onChangeTime(value) {
      this.timeAt = value;
    },
    async onChangeInbox() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.selectedInbox,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.senderList = inboxMembers;
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onToggleImport() {
      this.showImportModal = !this.showImportModal;
    },
    onCustomDateRangeChange(value) {
      this.customDateRange = value;
      // this.selectedGroupByFilter = this.validGroupBy;
      // this.emitChange();
    },
  },
};
</script>

<style>
.btn-import {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.btn-import > .btn-icon-import {
  margin-top: 8px;
  height: 40px;
  width: 40px;
  border-radius: 0 4px 4px 0;
}
</style>
