<template>
  <div>
    <div class="container mx-auto mt-6">
      <ol class="relative border-s border-woot-200 dark:border-gray-700">
        <li v-for="(item, i) in histories" :key="item.id" class="mb-6 ms-6">
          <span
            class="bg-white absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900"
          >
            <svg
              class="w-3.5 h-3.5 text-woot-600 dark:text-blue-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"
              />
            </svg>
          </span>
          <div
            v-if="i === histories.length - 1"
            class="line absolute bg-slate-25 dark:bg-slate-800"
          />
          <!-- <h5 v-if="item.historic_type" class="flex gap-1 text-xs -mb-1">
            <strong :style="{ color: colorVisibility[item.visibility] }">
              <fluent-icon icon="eye-show" class="icon-min" size="16" />
            </strong>
            {{ $t(`HISTORIC.FORM.VISIBILITY.OPTIONS.${item.visibility}`) }}
          </h5> -->
          <!-- <h5 v-if="item.historic_type" class="flex gap-1 text-xs -mb-1">
            {{ item.historic_type.title }}
          </h5> -->
          <h3
            class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white"
          >
            {{ getTitle(item) }}
            <span
              :style="{ 'background-color': colorVisibility[item.visibility] }"
              class="bg-woot-100 text-slate-50 text-sm font-medium me-0 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3"
            >
              {{ $t(`HISTORIC.FORM.VISIBILITY.OPTIONS.${item.visibility}`) }}
            </span>
            <span
              v-if="i === 0"
              class="bg-woot-100 text-woot-800 text-sm font-medium ml-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3"
            >
              {{ $t('HISTORIC.TIMELINE.LAST') }}
            </span>
          </h3>
          <time
            class="block mb-2 text-sm font-normal capitalize leading-none text-gray-400 dark:text-gray-500"
          >
            <!-- {{
              `${getDateDay(item.registry_date)} - ${getTime(
                item.registry_date
              )}`
            }} -->
            <span
              v-if="item.historic_type"
              class="bg-slate-200 text-slate-600 mr-1 text-sm font-medium me-0 px-2 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
            >
              {{ item.historic_type.title }}
            </span>
            {{ getStatus(item) }}
          </time>
          <p
            v-dompurify-html="formatMessage(item.content || '')"
            class="mb-1 text-base font-normal text-gray-500 dark:text-gray-400 content-height"
          >
            {{ item.content }}
          </p>
          <div v-if="item.files" class="flex gap-1 flex-wrap">
            <woot-button
              v-for="file in item.files"
              :key="file.id"
              v-tooltip="$t('HISTORIC.TIMELINE.DOWNLOAD_FILE')"
              size="small"
              color-scheme="primary"
              icon="download"
              class="width-label-att"
              @click="download(file)"
            >
              {{ file.filename }}
            </woot-button>
          </div>
          <!-- <a
            v-if="item.files"
            :href="item.files[0].file_url"
            class="inline-flex items-center px-4 py-2 text-sm font-medium text-woot-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
            ><svg
              class="w-4 h-4 me-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"
              />
              <path
                d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
              />
            </svg>
            {{ $t('HISTORIC.TIMELINE.DOWNLOAD_FILE') }}
          </a> -->
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import dateMixin from 'dashboard/mixins/dateMixin';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [dateMixin, alertMixin, messageFormatterMixin],
  props: {
    histories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      colorVisibility: {
        ONLY_USER: '#dc2626',
        ADMIN: '#f97316',
        ALL_USERS: '#10b981',
        COMPANY_PROFESSIONALS: '#0891b2',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'histories/getUIFlags',
      agentList: 'agents/getAgents',
    }),
  },
  methods: {
    // async download(file) {
    //   const { file_url, filename } = file;
    //   try {
    //     const result = await fetch(file_url);
    //     const blob = await result.blob();
    //     const urlBlob = URL.createObjectURL(blob);

    //     const linkDownload = document.createElement('a');
    //     linkDownload.href = urlBlob;
    //     linkDownload.download = filename;

    //     linkDownload.click();
    //   } catch (error) {
    //     this.showAlert(this.$t('HISTORIC.TIMELINE.DOWNLOAD_ERROR'));
    //   }
    // },
    download(file) {
      try {
        const { file_url, filename } = file;
        const linkDownload = document.createElement('a');
        linkDownload.href = file_url;
        linkDownload.download = filename;
        linkDownload.target = '_blank';
        // linkDownload.click();

        setTimeout(() => {
          linkDownload.click();
        }, 100);
      } catch (error) {
        this.showAlert(this.$t('HISTORIC.TIMELINE.DOWNLOAD_ERROR'));
      }
    },
    getTitle(itemLine) {
      // const ExistType = itemLine.historic_type;
      // const textType = ExistType ? `[${ExistType.title}]` : '';
      // return `${ExistType && textType} ${this.getDateDay(
      //   itemLine.registry_date
      // )} - ${this.getTime(itemLine.registry_date)}`;
      return `${this.getDateDay(itemLine.registry_date)} - ${this.getTime(
        itemLine.registry_date
      )}`;
    },
    getStatus(itemLine) {
      const author = this.agentList.find(item => item.id === itemLine.user_id);
      const infoSchedule =
        itemLine.schedule &&
        ` - ${this.$t('HISTORIC.FORM.SCHEDULE.LABEL')} ${this.$t(
          'SCHEDULE.STATUS.' + itemLine.schedule.status
        )}`;
      const result = `${author.name || ''} ${infoSchedule || ''}`;
      return result;
    },
  },
};
</script>

<style>
.line {
  left: -1px;
  height: 120px;
  width: 2px;
  margin-top: 32px;
}
.type-badge {
  background-color: #d3d3e1;
  color: #636363;
}
.content-height {
  max-height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow: auto;
}
.width-label-att > span {
  line-height: 1.5;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
