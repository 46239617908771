/* eslint arrow-body-style: 0 */
import CampaignsMessagesView from './CampaignsMessagesView';
import { frontendURL } from '../../../helper/URLHelper';
import CampaignsMsgManagerView from './pages/CampaignsMsgManagerView';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns_messages'),
      name: 'campaigns_messages',
      roles: ['administrator', 'agent'],
      component: CampaignsMessagesView,
    },
    {
      path: frontendURL(
        'accounts/:accountId/inbox/:inbox_id/campaigns_messages'
      ),
      name: 'inbox_campaigns_messages',
      roles: ['administrator', 'agent'],
      component: CampaignsMessagesView,
      props: route => {
        return { inboxId: route.params.inbox_id };
      },
    },
    {
      path: frontendURL('accounts/:accountId/label/:label/campaigns_messages'),
      name: 'label_campaigns_messages',
      roles: ['administrator', 'agent'],
      component: CampaignsMessagesView,
      props: route => ({ label: route.params.label }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/status/:status/campaigns_messages'
      ),
      name: 'status_campaigns_messages',
      roles: ['administrator', 'agent'],
      component: CampaignsMessagesView,
      props: route => ({ status: route.params.status }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/campaigns_messages/:campaignMsgId'
      ),
      name: 'campaigns_messages_details',
      roles: ['administrator', 'agent'],
      component: CampaignsMsgManagerView,
      props: route => {
        return { campaignMsgId: route.params.campaignMsgId };
      },
    },
  ],
};
