<template>
  <div>
    <form class="mx-0 flex-col flex-wrap" @submit.prevent="createChannel()">
      <label>
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST.LABEL') }}
        <select v-model="hostEvo" @change="onChangeHost($event)">
          <option
            v-for="host in evoHosts"
            :key="host.value"
            :value="host.value"
          >
            {{ host.title }}
          </option>
          <option value="OTHER">
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.OTHER_HOST') }}
          </option>
        </select>
      </label>
      <label
        v-if="showInputTempHost"
        class="ml-4"
        :class="{ error: $v.tempHost.$error }"
      >
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_TEMP.LABEL') }}
        <input
          v-model.trim="tempHost"
          type="text"
          class="mb-2"
          :placeholder="
            $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_TEMP.PLACEHOLDER')
          "
          @blur="$v.tempHost.$touch"
        />
        <span v-if="$v.tempHost.$error" class="message">{{
          $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_TEM.ERROR')
        }}</span>
      </label>
      <label
        v-if="showInputTempHost"
        class="ml-4"
        :class="{ error: $v.tempKeyHost.$error }"
      >
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_KEY_TEMP.LABEL') }}
        <input
          v-model.trim="tempKeyHost"
          type="text"
          class="mb-2"
          :placeholder="
            $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_KEY_TEMP.PLACEHOLDER')
          "
          @blur="$v.tempKeyHost.$touch"
        />
        <span v-if="$v.tempKeyHost.$error" class="message">{{
          $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.HOST_TEM.ERROR')
        }}</span>
      </label>
      <label>
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.PROVIDER.LABEL') }}
        <select v-model="integration">
          <option
            v-for="integrationItem in integrationOpc"
            :key="integrationItem.value"
            :value="integrationItem.value"
          >
            {{ integrationItem.title }}
          </option>
        </select>
      </label>
      <label v-if="isMetaApi" class="ml-4">
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.NUMBER.LABEL') }}
        <input
          v-model.trim="numberEvoMeta"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.API_CHANNEL.EVO.NUMBER.PLACEHOLDER')"
          @blur="$v.numberEvoMeta.$touch"
        />
        <span v-if="$v.numberEvoMeta.$error" class="message">{{
          $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.NUMBER.ERROR')
        }}</span>
      </label>
      <label v-if="isMetaApi" class="ml-4">
        {{ $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.APIKEY.LABEL') }}
        <input
          v-model.trim="TokenMeta"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.API_CHANNEL.EVO.APIKEY.PLACEHOLDER')"
          @blur="$v.TokenMeta.$touch"
        />
        <span v-if="$v.TokenMeta.$error" class="message">{{
          $t('INBOX_MGMT.ADD.API_CHANNEL.EVO.APIKEY.ERROR')
        }}</span>
      </label>

      <div class="w-full">
        <woot-submit-button
          :loading="isLoading"
          :button-text="$t('INBOX_MGMT.ADD.API_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import inboxApiMixin, {
  INTEGRATION_OPC,
  EVO_V2_HOST,
} from 'shared/mixins/InboxApiMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../../index';
import apiEvolution from 'dashboard/api/apisControl/apiEvolution';

const shouldBeWebhookUrl = (value = '') =>
  value ? value.startsWith('http') : true;

export default {
  components: {},
  mixins: [alertMixin, inboxApiMixin],
  props: {
    channelName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      webhookUrl: '',
      hostEvo: 'https://v2evomanager.nubla.cloud',
      numberEvoMeta: '',
      integration: 'WHATSAPP-BAILEYS',
      integrationOpc: INTEGRATION_OPC,
      evoHosts: EVO_V2_HOST,
      tempHost: '',
      tempKeyHost: '',
      showInputTempHost: false,
      TokenMeta: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    isMetaApi() {
      return this.integration === 'WHATSAPP-BUSINESS';
    },
  },
  validations: {
    channelName: { required },
    webhookUrl: {},
    numberEvoMeta: {},
    integration: { required },
    tempHost: { shouldBeWebhookUrl },
    tempKeyHost: {},
    TokenMeta: {},
  },
  beforeMount() {
    this.integrationOpc = [
      ...INTEGRATION_OPC,
      { title: 'Evolution', value: 'EVOLUTION' },
    ];
  },
  methods: {
    setUrlApiAxios(url, apikey) {
      apiEvolution.setUrlAxiosApi(url, apikey);
    },
    onChangeHost() {
      this.showInputTempHost = this.hostEvo === 'OTHER';
    },
    getWebHook(nameInstance) {
      const tempHost =
        this.tempHost.slice(-1) === '/'
          ? this.tempHost.slice(0, -1)
          : this.tempHost;
      const host = this.hostEvo !== 'OTHER' ? this.hostEvo : tempHost;
      return `${host}/chatwoot/webhook/${nameInstance}`;
    },
    async createChannel() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isLoading = false;
        return;
      }
      this.webhookUrl = this.getWebHook(this.channelName);
      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'api',
            webhook_url: this.webhookUrl,
            additional_attributes: { integration: this.integration },
          },
        });

        let instance = await this.$store.dispatch('apiEvolution/setInstance', {
          instanceName: this.channelName,
          integration: this.integration,
          ...(this.tempKeyHost !== '' && { apikey: this.tempKeyHost }),
          ...(this.isMetaApi && { token: this.TokenMeta }),
          number: this.numberEvoMeta,
          inbox: apiChannel,
        });
        if (instance.error) {
          this.showAlert(
            this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE') +
              `, ${instance.error}`
          );
          this.isLoading = false;
        } else {
          this.setUrlApiAxios(this.hostEvo, instance.token);
          let setWoot = this.getConfigWoot();
          const instanciaData = {
            serverUrl: this.hostEvo,
            instanceName: instance.name,
            apikey: instance.token,
            ...instance,
          };
          await this.sendDataWoot(instanciaData, setWoot);

          this.isLoading = false;
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id: apiChannel.id,
            },
          });
        }
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
