<template>
  <div class="bottom-box">
    <div class="left-wrap">
      <woot-button
        v-tooltip.top-end="$t('EDITOR_WHATSAPP.TOB_BAR.BOLD')"
        icon="bold"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="$t('EDITOR_WHATSAPP.TOB_BAR.BOLD')"
        @click="EditText('*')"
      />
      <woot-button
        v-tooltip.top-end="$t('EDITOR_WHATSAPP.TOB_BAR.ITALIC')"
        icon="italic"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="$t('EDITOR_WHATSAPP.TOB_BAR.ITALIC')"
        @click="EditText('_')"
      />
      <woot-button
        v-tooltip.top-end="$t('EDITOR_WHATSAPP.TOB_BAR.STRIKETHROUGH')"
        icon="Strikethrough"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="$t('EDITOR_WHATSAPP.TOB_BAR.STRIKETHROUGH')"
        @click="EditText('~')"
      />
      <woot-button
        v-tooltip.top-end="$t('EDITOR_WHATSAPP.TOB_BAR.MESSAGE_PREVIEW')"
        :icon="showEye ? 'eye-show' : 'eye-hide'"
        color-scheme="primary"
        variant="smooth"
        size="small"
        :title="$t('EDITOR_WHATSAPP.TOB_BAR.MESSAGE_PREVIEW')"
        @click="toggleTextFormat()"
      />
    </div>
    <!-- <div class="right-wrap">
      <woot-button size="small" :is-disabled="isSendDisabled" @click="onSend">
        {{ sendButtonText }}
      </woot-button>
    </div> -->
  </div>
</template>

<script>
import * as ActiveStorage from 'activestorage';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { mapGetters } from 'vuex';

export default {
  name: 'ReplyBottomPanelCampaign',
  components: {},
  mixins: [eventListenerMixins, uiSettingsMixin],
  props: {
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    refInputMsg: {
      type: Object,
      default: () => {},
    },
    showEye: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    toggleTextFormat() {
      this.$emit('funtion-show');
    },
    EditText(text) {
      const { selectionStart, selectionEnd } =
        this.refInputMsg.messageInput.$el;
      const newMessage = this.insertFormat(text, selectionStart, selectionEnd);
      this.$emit('edit-text', newMessage);
    },
    insertFormat(emoji, selectionStart, selectionEnd) {
      const { message } = this;
      if (selectionEnd > selectionStart) {
        const selectMsg = message.slice(selectionStart, selectionEnd).trim();
        return (
          message.slice(0, selectionStart) +
          ' ' +
          emoji +
          selectMsg +
          emoji +
          ' ' +
          message.slice(selectionEnd, message.length)
        );
      }
      const newMessage =
        message.slice(0, selectionStart) +
        emoji +
        message.slice(selectionEnd, message.length);
      return newMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  @apply flex justify-between pt-4;
}

.left-wrap {
  @apply items-center flex gap-2;
}

.right-wrap {
  @apply flex;
}

::v-deep .file-uploads {
  label {
    @apply cursor-pointer;
  }
  &:hover button {
    @apply dark:bg-slate-800 bg-slate-100;
  }
}

.modal-mask {
  @apply text-slate-600 dark:text-slate-200 bg-white_transparent dark:bg-black_transparent flex-col;
}

.icon {
  @apply text-[5rem];
}
</style>
