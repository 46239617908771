<template>
  <div class="w-full flex flex-row">
    <div class="flex flex-col h-full">
      <header-schedule
        :search-query="searchQuery"
        :header-title="title"
        :placeholder-search="$t('PROFESSIONAL.SEARCH_PLACEHOLDER')"
        :title-button="$t('PROFESSIONAL.ADD.TITLE')"
        :show-search="true"
        this-selected-contact-id=""
        @on-open-add-popup="toggleAddPopup"
        @on-search-submit="onSearchSubmit"
        @on-input-search="onInputSearch"
      />
      <TableASchedules
        :data-list="professionals"
        :meta="meta"
        @on-edit="onSelectProfessional"
      />
      <table-footer
        :current-page="Number(meta.currentPage)"
        :total-count="meta.count"
        :page-size="10"
        @page-change="onPageChange"
      />
      <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
        <add-professional
          :title="titleAddModal"
          :professional="selectProfessional"
          @on-close="hideAddPopup"
        />
      </woot-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderSchedule from '../components/Header.vue';
import alertMixin from 'shared/mixins/alertMixin';
import addProfessional from './AddProfessional.vue';
import TableASchedules from '../components/Table.vue';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';

export default {
  components: {
    HeaderSchedule,
    TableASchedules,
    addProfessional,
    TableFooter,
  },
  mixins: [alertMixin],
  props: {},
  data() {
    return {
      searchQuery: '',
      showCreateModal: false,
      showImportModal: false,
      selectedContactId: '',
      sortConfig: { last_activity_at: 'desc' },
      showFiltersModal: false,
      segmentsQuery: {},
      showAddSegmentsModal: false,
      appliedFilter: [],
      showAddPopup: false,
      selectedChanel: '',
      currentPage: 1,
      selectProfessional: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'professionals/getUIFlags',
      records: 'professionals/getAllProfessionals',
    }),
    title() {
      return this.$t('PROFESSIONAL.TITLE');
    },
    meta() {
      const total = this.professionals.length;
      return {
        currentPage: this.currentPage,
        perPage: 10,
        count: total,
      };
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    titleAddModal() {
      if (this.selectProfessional && this.selectProfessional.id) {
        return this.$t('PROFESSIONAL.EDIT.TITLE');
      }
      return this.$t('PROFESSIONAL.ADD.TITLE');
    },
    professionals() {
      if (this.searchQuery !== '') {
        const search = this.searchQuery;
        let result = this.records.filter(professional => {
          if (
            professional.name.toLowerCase().indexOf(search.toLowerCase()) > -1
          )
            return professional;
          return false;
        });
        return result;
      }
      return this.records;
    },
  },
  mounted() {
    this.$store.dispatch('professionals/get');
  },
  methods: {
    fetchProfessional() {
      // console.log('searchQuery', this.searchQuery);
      // return this.professionals.filter(professional => {
      //   if(professional.name.include(this.searchQuery))
      // })
    },
    onSearchSubmit() {
      this.selectProfessional = '';
      if (this.searchQuery) {
        this.fetchProfessional();
      }
    },
    onSelectProfessional(professional) {
      this.selectProfessional = professional;
      this.showAddPopup = !this.showAddPopup;
    },
    onPageChange(page) {
      this.selectedContactId = '';
      this.currentPage = page;
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    toggleAddPopup() {
      this.selectProfessional = null;
      this.showAddPopup = !this.showAddPopup;
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      this.searchQuery = newQuery;
      // const refetchAllContacts = !!this.searchQuery && newQuery === '';
      // if (refetchAllContacts) {
      //   this.fetchContacts(DEFAULT_PAGE);
      // }
    },
  },
};
</script>
