<template>
  <div class="settings--content flex">
    <div class="w-1/4">
      <settings-sub-page-header
        :header-title="
          $t('API_CONTROL.NOT_INSTANCE.TITLE', { name: inbox.name })
        "
        :header-content="$t('API_CONTROL.NOT_INSTANCE.DESCRIPTION')"
      />
      <woot-button
        :disabled="disabledSummitBtn"
        :is-loading="disabledSummitBtn"
        @click="sendData"
      >
        {{ $t(`API_CONTROL.NOT_INSTANCE.BUTTON`) }}
      </woot-button>
    </div>
    <div v-if="showForm && inbox" class="w-2/3">
      <wizard-auto v-if="showForm" :steps="steps" :step="stepCratingInstance" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSubPageHeader from '../../SettingsSubPageHeader.vue';
import WizardAuto from 'dashboard/components/ui/WizardAuto';
import inboxApiMixin from 'shared/mixins/InboxApiMixin';
import apiEvolution from 'dashboard/api/apisControl/apiEvolution';
export default {
  components: {
    WizardAuto,
    SettingsSubPageHeader,
  },
  mixins: [alertMixin, inboxApiMixin],
  props: {
    inbox: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  data() {
    return {
      showForm: false,
      stepCratingInstance: 0,
      integration: 'WHATSAPP-BAILEYS',
      disabledSummitBtn: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'apiEvolution/uiFlags',
      currentAccountId: 'getCurrentAccountId',
      currentUser: 'getCurrentUser',
    }),
    steps() {
      return this.$t('API_CONTROL.NOT_INSTANCE.CREATE_FLOW');
    },
    instanceNameLocal() {
      const inboxName = this.inbox.name;
      return this.inbox.webhook_url
        ? this.inbox.webhook_url.split('/webhook/')[1]
        : inboxName;
    },
  },
  methods: {
    setUrlApiAxios(url, apikey) {
      apiEvolution.setUrlAxiosApi(url, apikey);
    },
    setHeadersApi(instance) {
      if (
        instance &&
        !instance?.error &&
        this.inbox &&
        this.inbox.webhook_url
      ) {
        const { serverUrl, apikey, token } = instance;
        const url =
          this.inbox.webhook_url.split('/chatwoot/webhook/')[0] || serverUrl;
        const key = apikey || token;
        this.setUrlApiAxios(url, key);
      }
    },
    async sendData() {
      // console.log('not instance sendData')
      this.showForm = true;
      try {
        this.disabledSummitBtn = true;
        let instance = await this.$store.dispatch('apiEvolution/setInstance', {
          instanceName: this.instanceNameLocal,
          integration: this.integration,
          // ...(this.isMetaApi && { token: this.TokenMeta }),
          // number: this.numberEvoMeta,
          inbox: this.inbox,
        });
        await this.timeout(1500);
        this.stepCratingInstance = 1;

        // this.setHeadersApi(instance)
        let setWoot = this.getConfigWoot();
        await this.sendDataWoot(instance, setWoot);

        await this.timeout(1500);
        this.stepCratingInstance = 2;

        await this.timeout(1500);
        await this.$store.dispatch('apiEvolution/setTempInstance');
        this.stepCratingInstance = 3;
        this.showAlert(this.$t('API_CONTROL.NOT_INSTANCE.API.SUCCESS_MESSAGE'));

        this.disabledSummitBtn = false;
      } catch (error) {
        this.disabledSummitBtn = false;
        this.showAlert(this.$t('API_CONTROL.NOT_INSTANCE.API.ERROR_MESSAGE'));
      }
    },
    async timeout(time = 3000) {
      await new Promise(resolve => {
        setTimeout(() => resolve(), time);
      });
    },
  },
};
</script>

<style></style>
