import {
  format,
  // isSameYear,
  // fromUnixTime,
  // formatDistanceToNow,
} from 'date-fns';

export default {
  methods: {
    dateFormat(dateString, dateFormat = 'MMM d, yyyy') {
      // console.log('formatcs', dateString);
      const date = new Date(dateString);
      return format(date, dateFormat);
    },
  },
};
