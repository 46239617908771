<template>
  <section
    class="campaign-table-wrap bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      :columns="columns"
      :table-data="
        tableData.slice(
          (meta.currentPage - 1) * meta.perPage,
          (meta.currentPage - 1) * meta.perPage + meta.perPage
        )
      "
      :border-around="false"
      :sort-option="sortOption"
    />

    <!-- <empty-state
      v-if="showSearchEmptyState"
      :title="$t('CONTACTS_PAGE.LIST.404')"
    />
    <empty-state
      v-else-if="!isLoading && !contacts.length"
      :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
    /> -->
    <!-- <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner />
      <span>{{ $t('CONTACTS_PAGE.LIST.LOADING_MESSAGE') }}</span>
    </div> -->
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
import timeMixin from 'dashboard/mixins/time';
import rtlMixin from 'shared/mixins/rtlMixin';
export default {
  components: {
    VeTable,
  },
  mixins: [clickaway, timeMixin, rtlMixin],
  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    status: { type: String, default: null },
    meta: { type: Object, default: null },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },
  computed: {
    tableData() {
      let data = this.campaigns; // this.$store.getters['campaignsMessages/getAllCampaigns'];
      if (this.status) {
        return data
          .filter(item => {
            if (item.status === this.status) return true;
            return false;
          })
          .map(item => {
            return this.formatData(item);
          });
      }
      return data.map(item => {
        return this.formatData(item);
      });
    },
    columns() {
      return [
        {
          field: 'description',
          key: 'description',
          fixed: 'left',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.TITLE'),
          // sortBy: this.sortConfig.description || '',
          align: this.isRTLView ? 'right' : 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <woot-button
              variant="clear"
              onClick={() => this.onClickContact(row)}
            >
              <div class="row--user-block">
                <div class="user-block">
                  <router-link
                    to={`/app/accounts/${this.$route.params.accountId}/campaigns_messages/${row.id}`}
                    class="user-name"
                  >
                    <h6 class="sub-block-title overflow-hidden whitespace-nowrap text-ellipsis">
                      <a>{row.description}</a>
                    </h6>
                    <button class="button clear small link view-details--button">
                      {this.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')}
                    </button>
                  </router-link>
                  {/* <h6 class="sub-block-title overflow-hidden whitespace-nowrap text-ellipsis">
                    <router-link
                      to={`/app/accounts/${this.$route.params.accountId}/campaigns_messages/${row.id}`}
                      class="user-name"
                    >
                      {row.description}
                    </router-link>
                  </h6>
                  <button class="button clear small link view-details--button">
                    {this.$t('CONTACTS_PAGE.LIST.VIEW_DETAILS')}
                  </button> */}
                </div>
              </div>
            </woot-button>
          ),
        },
        {
          field: 'status',
          key: 'status',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.STATUS'),
          // sortBy: this.sortConfig.status || '',
          align: this.isRTLView ? 'right' : 'left',
          width: 70,
          renderBodyCell: ({ row }) => {
            if (row.status)
              return (
                <div id="span-status">
                  <span {...this.colorStatus(row.status)}>
                    {this.$t('CAMPAIGN_MSG.STATUS.' + row.status)}
                  </span>
                </div>
              );
            return '---';
          },
        },
        {
          field: 'campaign_start',
          key: 'campaign_start',
          // sortBy: this.sortConfig.campaign_start || '',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.SCHEDULED_AT'),
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'campaign_end',
          key: 'campaign_end',
          // sortBy: this.sortConfig.campaign_end || '',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.SCHEDULED_END'),
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
        },
        {
          field: 'time',
          key: 'time',
          // sortBy: this.sortConfig.initial_send_time || '',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.TIME'),
          align: this.time ? 'right' : 'left',
          width: 70,
          renderBodyCell: ({ row }) => {
            if (row.initial_send_time)
              return (
                <div class="">
                  {row.initial_send_time} - {row.final_send_time}
                </div>
              );
            return '---';
          },
        },
        {
          field: 'interval',
          key: 'interval',
          // sortBy: this.sortConfig.interval || '',
          title: this.$t('CAMPAIGN_MSG.LIST.TABLE_HEADER.INTERVAL'),
          align: this.isRTLView ? 'right' : 'left',
          width: 100,
          renderBodyCell: ({ row }) => {
            if (row.campaign_start)
              return (
                <div class="">
                  {row.minimum_interval} - {row.maximum_interval}
                </div>
              );
            return '---';
          },
        },
      ];
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    formatData(campaign) {
      const {
        campaign_start: campaignStart,
        campaign_end: campaignEnd,
        minimum_interval: minimumInterval,
        maximum_interval: maximumInterval,
        initial_send_time: initialTime,
        final_send_time: final_Time,
      } = campaign;
      return {
        ...campaign,
        campaign_start: campaignStart ? this.getDate(campaignStart) : '---',
        campaign_end: campaignEnd ? this.getDate(campaignEnd) : '---',
        minimum_interval: minimumInterval ? minimumInterval + 'S' : '---',
        maximum_interval: minimumInterval ? maximumInterval + 'S' : '---',
        initial_send_time: initialTime
          ? this.getTime(initialTime, true)
          : '---',
        final_send_time: final_Time ? this.getTime(final_Time, true) : '---',
      };
    },
    colorStatus(status) {
      const className = 'span-status p-1 text-slate-800 ';
      const statusNames = {
        SCHEDULED: 'bg-yellow-600',
        FILE_IMPORT_ERROR: 'bg-red-300',
        IMPORTING_CONTACTS: 'bg-yellow-600',
        OUTSIDE_CONFIGURED_HOURS: 'bg-yellow-600',
        COMPLETED: 'bg-woot-100',
        PARTIALLY_COMPLETED: 'bg-woot-100',
        CONNECTOR_OFFLINE: 'bg-red-300',
        PROCESSING: 'bg-yellow-600',
        MESSAGE_LIMIT_REACHED: 'bg-red-300',
      };
      return { class: className + statusNames[status] };
    },
    onClickContact(data) {
      return data;
    },
    getDate(date) {
      const dateTemp = new Date(date);
      // const optionsDate = {
      //   year: 'numeric',
      //   month: 'short',
      //   day: 'numeric',
      // };
      return dateTemp.toLocaleDateString() + ' ' + this.getTime(date);
    },
    getTime(dateOrigin, changeYear) {
      // console.log('date getime ', date);
      let date = dateOrigin;
      if (changeYear)
        date = date.replace('2000', String(new Date().getFullYear()));
      const dateTemp = new Date(date);
      const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
      };
      // console.log(
      //   'date getime format',
      //   dateTemp.toLocaleTimeString(undefined, optionsTime)
      // );
      return dateTemp.toLocaleTimeString(undefined, optionsTime);
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }
  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    @apply -top-1;
  }
  #span-status > .span-status {
    padding: 0.25rem;
    border-radius: 4px;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
</style>
