<template>
  <div class="date-picker">
    <date-picker
      :type="type"
      :range="true"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="value"
      :range-separator="rangeSeparator"
      format="DD-MMM-YYYY HH:mm"
      @change="handleChange"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/pt-br';
export default {
  components: { DatePicker },
  props: {
    type: {
      type: String,
      default: 'date',
    },
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    rangeSeparator: {
      type: String,
      default: ' ~ ',
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>
