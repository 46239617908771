// import SettingsContent from '../Wrapper';
import Content from '../components/Content';
import Index from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/schedules/professional'),
      roles: ['administrator'],
      component: Content,
      props: {
        headerTitle: 'PROFESSIONAL.TITLE',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          name: 'professional_schedules_index',
          component: Index,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
