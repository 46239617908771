import types from '../../mutation-types';
import NotificationsAPI from '../../../api/notifications';

export const actions = {
  get: async ({ commit }, { page = 1 } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get(page);
      commit(types.CLEAR_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    }
  },
  unReadCount: async ({ commit } = {}) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnreadCount();
      const { data: unReadMentionsCount } =
        await NotificationsAPI.getUnreadMentionsCount();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, data);
      commit(types.SET_MENTIONS_UNREAD_COUNT, unReadMentionsCount);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    }
  },
  readMention: async (
    { commit },
    { primaryActorType, primaryActorId, meta }
  ) => {
    try {
      const { unreadCount, unReadMentionCount } = meta;
      const { data } = await NotificationsAPI.readMention(
        primaryActorType,
        primaryActorId
      );
      const { count_read, count_read_mentions } = data;
      if (data.count_read) {
        commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, unreadCount - count_read);
        commit(
          types.SET_MENTIONS_UNREAD_COUNT,
          unReadMentionCount - count_read_mentions
        );
      }
      commit(types.UPDATE_NOTIFICATION, primaryActorId);
    } catch (error) {
      throw new Error(error);
    }
  },
  read: async (
    { commit },
    {
      primaryActorType,
      primaryActorId,
      meta,
      isMention = false,
      notificationId,
    }
  ) => {
    try {
      const { unreadCount, unReadMentionCount } = meta;
      if (isMention) {
        await NotificationsAPI.readNotification(notificationId);
      } else {
        await NotificationsAPI.read(primaryActorType, primaryActorId);
      }
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, unreadCount - 1);
      commit(types.SET_MENTIONS_UNREAD_COUNT, unReadMentionCount - 1);
      commit(types.UPDATE_NOTIFICATION, primaryActorId);
    } catch (error) {
      throw new Error(error);
    }
  },
  readAll: async ({ commit }) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.readAll();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, 0);
      commit(types.SET_MENTIONS_UNREAD_COUNT, 0);
      commit(types.UPDATE_ALL_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      throw new Error(error);
    }
  },

  addNotification({ commit }, data) {
    commit(types.ADD_NOTIFICATION, data);
  },
};
