<template>
  <div
    class="bg-slate-25 dark:bg-slate-800 h-[calc(100%-40px)] p-4 overflow-auto"
  >
    <accordion-item
      :title="$t('HISTORIC.FORM.NEW')"
      :is-open="isOpenForm"
      code-settings="123"
      @click="value => toggleOpenForm()"
    >
      <historic-editor :contact-id="contactId" @close="toggleOpenForm" />
    </accordion-item>
    <time-line-historic :histories="histories" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem.vue';
import HistoricEditor from './historicEditor.vue';
import TimeLineHistoric from './TimeLineHistoric.vue';

export default {
  components: {
    AccordionItem,
    HistoricEditor,
    TimeLineHistoric,
  },
  props: {
    contactId: {
      type: [String, Number],
      required: true,
    },
    histories: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isOpenForm: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'histories/getUIFlags',
      // histories: 'histories/getHistories',
    }),
  },
  methods: {
    toggleOpenForm() {
      this.isOpenForm = !this.isOpenForm;
    },
  },
};
</script>

<style></style>
