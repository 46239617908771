/* global axios */
import ApiClient from './ApiClient';

class CampaignsMessagesAPI extends ApiClient {
  constructor() {
    super('campaigns_messages', { accountScoped: true });
  }

  getContacts(campaignMsgID) {
    return axios.get(`${this.url}/${campaignMsgID}/campaigns_message_contacts`);
  }

  getInboxes(campaignMsgID) {
    return axios.get(`${this.url}/${campaignMsgID}/campaigns_message_inboxes`);
  }

  importContacts(campaignMsgID, file) {
    const formData = new FormData();
    formData.append('import_file', file);
    formData.append('campaigns_message_id', campaignMsgID);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}

export default new CampaignsMessagesAPI();
