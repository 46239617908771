<template>
  <div class="-ml-1">
    <div class="flex items-center mb-2 text-slate-800 dark:text-slate-100">
      <!-- <emoji-or-icon
        :icon="icon"
        :emoji="emoji"
        icon-size="14"
        class="mr-2 ml-1 rtl:mr-1 rtl:ml-2"
      /> -->
      <!-- border p-1 rounded border-slate-300 text-woot-500 -->
      <div class="p-1" :class="{ 'text-woot-700': emphasis }">
        <fluent-icon :icon="icon" />
      </div>
      <span
        class="ml-2 overflow-hidden whitespace-nowrap text-ellipsis text-woot-700 dark:text-woot-400"
      >
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // mixins: [alertMixin],
  props: {
    icon: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    emphasis: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
