import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import HistoriesAPI from '../../api/histories';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { HISTORIC_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getHistoric: _state => campaignType => {
    return _state.records.filter(
      record => record.campaign_type === campaignType
    );
  },
  getHistories: _state => {
    return _state.records;
  },
};

export const actions = {
  get: async function getHistories({ commit }, params) {
    commit(types.SET_HISTORIES_UI_FLAG, { isFetching: true });
    try {
      const response = await HistoriesAPI.get(params);
      // console.log('histories response ', response);
      commit(types.SET_HISTORIES, response.data);
      // commit(types.SET_HISTORIES, localHistories);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_HISTORIES_UI_FLAG, { isFetching: false });
    }
  },
  getFilter: async function getHistoriesFilter({ commit }, params) {
    commit(types.SET_HISTORIES_UI_FLAG, { isFetching: true });
    try {
      const response = await HistoriesAPI.getFilter(params);
      // console.log('response ', response);
      commit(types.SET_HISTORIES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_HISTORIES_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createHistoric({ commit }, { data, files }) {
    // console.log('professionalObj ', professionalObj);
    commit(types.SET_HISTORIES_UI_FLAG, { isCreating: true });
    try {
      const response = await HistoriesAPI.create(data);
      // console.log('created dataHistoric', response);
      // await HistoriesAPI.create(dataHistoric);
      if (files && files.length > 0) {
        await HistoriesAPI.importFiles(response.data.id, files);
        // console.log('respFiles ', respFiles);
        // const tempFiles = respFiles.map(item => ({
        //   id: item.id,
        //   file_url: item.href,
        //   filename: item.name,
        // }));
        // resultData.files = tempFiles;
        const responseHistories = await HistoriesAPI.getFilter({
          contact_id: data.contact_id,
        });
        commit(types.SET_HISTORIES, responseHistories.data);
      } else {
        commit(types.ADD_HISTORIC, response.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_HISTORIES_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_HISTORIES_UI_FLAG, { isUpdating: true });
    try {
      const response = await HistoriesAPI.update(id, updateObj);
      // await HistoriesAPI.update(id, updateObj);
      AnalyticsHelper.track(HISTORIC_EVENTS.UPDATE_HISTORIC);
      commit(types.EDIT_HISTORIC, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_HISTORIES_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_HISTORIES_UI_FLAG, { isDeleting: true });
    try {
      await HistoriesAPI.delete(id);
      AnalyticsHelper.track(HISTORIC_EVENTS.DELETE_HISTORIC);
      // commit(types.DELETE_SCHEDULE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_HISTORIES_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_HISTORIES_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_HISTORIC]: MutationHelpers.createStart,
  [types.SET_HISTORIES]: MutationHelpers.set,
  [types.EDIT_HISTORIC]: MutationHelpers.update,
  [types.DELETE_HISTORIC]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
