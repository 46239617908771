<template>
  <banner
    v-if="shouldShowBanner"
    color-scheme="secondary"
    :banner-message="bannerMessage"
    :action-button-label="actionButtonMessage"
    @click="routeToBilling"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';
import { differenceInDays, differenceInHours } from 'date-fns';

export default {
  components: { Banner },
  mixins: [adminMixin, accountMixin],
  data() {
    return { conversationMeta: {} };
  },
  computed: {
    ...mapGetters({
      isOnChatwootCloud: 'globalConfig/isOnChatwootCloud',
      getAccount: 'accounts/getAccount',
    }),
    bannerMessage() {
      return this.trialRemaining();
      // 'Teste do Douglas 457';
      // this.$t('GENERAL_SETTINGS.LIMITS_UPGRADE');
    },
    actionButtonMessage() {
      return this.$t('GENERAL_SETTINGS.OPEN_BILLING');
    },
    shouldShowBanner() {
      // return false;
      // if (!this.isOnChatwootCloud) {
      //   return false;
      // }

      // if (this.isTrialAccount()) {
      //   return false;
      // }

      if (this.isNublaTrialAccount()) {
        return true;
      }
      return false;

      // return this.isLimitExceeded();
    },
  },
  mounted() {
    if (this.isOnChatwootCloud) {
      this.fetchLimits();
    }
  },
  methods: {
    fetchLimits() {
      this.$store.dispatch('accounts/limits');
    },
    routeToBilling() {
      this.$router.push({
        name: 'billing_settings_index',
        params: { accountId: this.accountId },
      });
    },
    isTrialAccount() {
      // check if account is less than 15 days old
      const account = this.getAccount(this.accountId);
      if (!account) return false;

      const createdAt = new Date(account.created_at);

      const diffDays = differenceInDays(new Date(), createdAt);

      return diffDays <= 15;
    },
    trialRemaining() {
      // check if account is less than 15 days old
      const account = this.getAccount(this.accountId);
      if (!account) return false;

      const trialEnds = new Date(account.trial_ends);
      if (new Date() >= trialEnds) {
        return 'Período de Testes Encerrado';
      }
      const diffDays = differenceInDays(trialEnds, new Date());
      if (diffDays < 1) {
        const diffHours = differenceInHours(trialEnds, new Date());
        return 'Restam ' + diffHours + ' horas do Período de Testes';
      }
      if (diffDays === 1) {
        return 'Resta ' + diffDays + ' dia do Período de Testes';
      }
      return 'Restam ' + diffDays + ' dias do Período de Testes';
    },
    isNublaTrialAccount() {
      // check if account is less than 15 days old
      const account = this.getAccount(this.accountId);
      if (!account) return false;
      return account.trial;
    },

    isLimitExceeded() {
      const account = this.getAccount(this.accountId);
      if (!account) return false;

      const { limits } = account;
      if (!limits) return false;

      const { conversation, non_web_inboxes: nonWebInboxes } = limits;
      return this.testLimit(conversation) || this.testLimit(nonWebInboxes);
    },
    testLimit({ allowed, consumed }) {
      return consumed > allowed;
    },
  },
};
</script>
