<template>
  <div>
    <!-- Breadcrumb -->
    <nav
      class="flex text-gray-700 bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
      aria-label="Breadcrumb"
    >
      <ol class="flex ml-0 mb-0 gap-2">
        <li
          v-for="(breadCrumb, index) in breadCrumbs"
          :key="breadCrumb.id"
          class="inline-flex items-center"
        >
          <router-link
            v-if="index !== breadCrumbs.length - 1"
            :to="{ name: breadCrumb.urlName, params: breadCrumb.params }"
            class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            @click="onRouter(breadCrumbs)"
          >
            {{ breadCrumb.label }}
            <svg
              class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </router-link>
          <div v-else class="text-sm font-medium text-gray-700">
            {{ breadCrumb.label }}
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    breadCrumbs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onRouter(breadCrumb) {
      this.$router.push({
        name: breadCrumb.urlName,
        params: breadCrumb.params,
      });
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb-item {
  margin-right: 5px;
}
.breadcrumb-item::after {
  content: '>';
  margin-left: 5px;
}
.breadcrumb-item:last-child::after {
  content: '';
}
</style>
