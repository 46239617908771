var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-auto overflow-auto flex flex-col w-full p-4 mt-2"},[_c('div',{staticClass:"flex justify-between mt-5"},[_c('div',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.titleBtnEdit),expression:"titleBtnEdit"}],attrs:{"title":"titleBtnEdit","icon":"calendar-clock","variant":"smooth","size":"small","disabled":_vm.isStatusConfirm,"color-scheme":"success"},on:{"click":_vm.toggleEditPopup}},[_vm._v("\n        "+_vm._s(_vm.$t('CALENDAR.INFORMATION.BUTTON_SCHEDULE_STATUS.AVAILABLE'))+"\n      ")]),_vm._v(" "),(!_vm.isStatusAvailable)?_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('CALENDAR.INFORMATION.BUTTON_HISTORIC')),expression:"$t('CALENDAR.INFORMATION.BUTTON_HISTORIC')"}],attrs:{"title":"$t('CALENDAR.INFORMATION.BUTTON_HISTORIC')","icon":"calendar-clock","variant":"smooth","size":"small","color-scheme":"info"},on:{"click":_vm.goHistoric}}):_vm._e(),_vm._v(" "),_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('CALENDAR.INFORMATION.BUTTON_DELETE')),expression:"$t('CALENDAR.INFORMATION.BUTTON_DELETE')"}],attrs:{"title":"$t('CALENDAR.INFORMATION.BUTTON_DELETE')","icon":"delete","variant":"smooth","size":"small","color-scheme":"alert"},on:{"click":_vm.toggleDeleteModal}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex gap-4"},[_c('info-row',{attrs:{"value":_vm.parseData(_vm.dataEvent.schedule_date_time),"icon":"calendar-clock","emoji":"📅","emphasis":""}}),_vm._v(" "),_c('info-row',{attrs:{"value":_vm.getIntervalTime(
          _vm.dataEvent.schedule_date_time,
          _vm.dataEvent.schedule_date_time_end
        ),"icon":"calendar-clock","emoji":"📅","emphasis":""}}),_vm._v(" "),_c('info-row',{attrs:{"value":_vm.$t(
          'SCHEDULE.MARKING_TYPE.' +
            (_vm.dataEvent.marking_selected || _vm.dataEvent.marking_type)
        ),"icon":"calendar-clock","emoji":"📅","emphasis":""}})],1),_vm._v(" "),_c('div',{staticClass:"flex"},[(_vm.dataEvent.professional)?_c('div',{staticClass:"w-[50%]"},[_c('card-profile',{attrs:{"title":_vm.$t('CALENDAR.INFORMATION.PROFESSIONAL.TITLE'),"name":_vm.dataEvent.professional.name,"information":_vm.dataEvent.professional.email}})],1):_vm._e(),_vm._v(" "),(_vm.dataEvent.contact)?_c('div',{staticClass:"w-px bg-slate-100 mx-2"},[_vm._v(" ")]):_vm._e(),_vm._v(" "),(_vm.dataEvent.contact)?_c('div',{staticClass:"w-[50%]"},[_c('card-profile',{attrs:{"title":_vm.$t('CALENDAR.INFORMATION.CONTACT.TITLE'),"name":_vm.dataEvent.contact.name,"information":_vm.hideNumPhone
            ? _vm.$t('CONTACT_PANEL.NOT_SEE_PHONE_NUMBER')
            : _vm.dataEvent.contact.phone_number,"link":`/app/accounts/${_vm.$route.params.accountId}/contacts/${_vm.dataEvent.contact.id}`,"tooltip-description":_vm.hideNumPhone ? _vm.$t('CONTACT_PANEL.NOT_SEE_PHONE_NUMBER') : '',"icon-information":_vm.hideNumPhone ? 'info' : ''}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-observation"},[_vm._v("\n    "+_vm._s(_vm.$t('CALENDAR.INFORMATION.OBSERVATION.TITLE'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-col mb-2 p-4 border border-solid border-slate-75 dark:border-slate-700 overflow-hidden rounded-md flex-grow shadow-sm bg-white dark:bg-slate-900 text-slate-700 dark:text-slate-100",style:({
      'padding-top': '0px',
      'pointer-events': _vm.dataEvent.status === 'CONFIRMED' ? 'none' : 'all',
    })},[_c('woot-message-editor',{staticClass:"input--note",attrs:{"placeholder":_vm.$t('CALENDAR.SCHEDULE.OBSERVATION.PLACEHOLDER'),"enable-suggestions":false},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1),_vm._v(" "),(!_vm.isStatusAvailable)?_c('div',{staticClass:"div-form justify-end py-2 px-0 w-full"},[_c('woot-button',{attrs:{"variant":"clear"},on:{"click":_vm.toggleCancel}},[_vm._v("\n      "+_vm._s(_vm.$t('CALENDAR.INFORMATION.BUTTON_CANCEL'))+"\n    ")]),_vm._v(" "),_c('woot-button',{attrs:{"disabled":_vm.isStatusConfirm},on:{"click":_vm.onConfirm}},[_vm._v("\n      "+_vm._s(_vm.$t('CALENDAR.INFORMATION.BUTTON_CONFIRM'))+"\n    ")])],1):_vm._e(),_vm._v(" "),(_vm.showCancelModal)?_c('woot-delete-modal',{attrs:{"show":_vm.showCancelModal,"on-close":_vm.toggleCancel,"on-confirm":_vm.confirmCancel,"title":_vm.$t('CALENDAR.INFORMATION.CANCEL_DIALOG.TITLE'),"message":_vm.$t('CALENDAR.INFORMATION.CANCEL_DIALOG.MESSAGE'),"message-value":_vm.titleValueCancelModal,"confirm-text":_vm.$t('CALENDAR.INFORMATION.CANCEL_DIALOG.YES'),"reject-text":_vm.$t('DELETE_CONTACT.CONFIRM.NO')},on:{"update:show":function($event){_vm.showCancelModal=$event}}}):_vm._e(),_vm._v(" "),_c('woot-modal',{attrs:{"show":_vm.showEditModal,"on-close":_vm.toggleEditPopup},on:{"update:show":function($event){_vm.showEditModal=$event}}},[_c('edit-schedule-event',{attrs:{"title":_vm.titleEditScheduleModal,"event":_vm.dataEvent},on:{"on-close":_vm.toggleEditPopup,"on-closes":_vm.onCloseEdit}})],1),_vm._v(" "),(_vm.showDeleteModal)?_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteModal,"on-close":_vm.closeDelete,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('DELETE_CONTACT.CONFIRM.TITLE'),"message":_vm.$t('DELETE_CONTACT.CONFIRM.MESSAGE'),"message-value":_vm.$t('SCHEDULE.CONFIRM_DELETE') +
      ' ' +
      _vm.parseData(_vm.dataEvent.schedule_date_time) +
      ' ' +
      _vm.getIntervalTime(
        _vm.dataEvent.schedule_date_time,
        _vm.dataEvent.schedule_date_time_end
      ),"confirm-text":_vm.$t('DELETE_CONTACT.CONFIRM.YES'),"reject-text":_vm.$t('DELETE_CONTACT.CONFIRM.NO')},on:{"update:show":function($event){_vm.showDeleteModal=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }