export class LocalStorageManager {
  constructor(accountId, modelName) {
    this.modelsToSync = ['inbox', 'label', 'team'];
    this.accountId = accountId;
    this.cacheModelName = modelName;
  }

  get accountModelData() {
    return `${this.accountId}_${this.cacheModelName}`;
  }

  set(data) {
    localStorage.setItem(this.accountModelData, JSON.stringify(data.data));
  }

  get() {
    const result = localStorage.getItem(this.accountModelData);
    return JSON.parse(result);
  }

  setCacheKeys(cacheKeys) {
    const name = this.accountModelData.split('_')[1];
    localStorage.setItem(
      `${this.accountModelData}_cache`,
      JSON.stringify(cacheKeys.cache_keys[name])
    );
  }

  getCacheKey() {
    let result = localStorage.getItem(`${this.accountModelData}_cache`);
    result = JSON.parse(result);
    return result;
  }
}
