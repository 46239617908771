<template>
  <div class="flex gap-4">
    <div
      v-if="objectOld && changes"
      class="box-content border-red-200 radius w-2/4"
    >
      <div class="border-b-2 border-slate-200">
        {{ $t('AUDIT_LOGS.DETAIL.ORIGINAL_OBJECT') }}
      </div>
      <property-viewer
        :object="objectOld"
        :changes="changes"
        type="old"
        :level="0"
        :key-father="[]"
      />
    </div>
    <div
      v-if="objectNew && changes"
      class="box-content border-woot-200 radius w-2/4"
    >
      <div class="border-b-2 border-slate-200">
        {{ $t('AUDIT_LOGS.DETAIL.MODIFIED_OBJECT') }}
      </div>
      <property-viewer
        :object="objectNew"
        :changes="changes"
        type="new"
        :level="0"
        :key-father="[]"
      />
    </div>
  </div>
</template>

<script>
import PropertyViewer from './PropertyViewer.vue';
import { diff } from 'deep-object-diff';

export default {
  name: 'ObjectComparer',
  components: {
    PropertyViewer,
  },
  props: {
    objectOld: {
      type: Object,
      required: true,
    },
    objectNew: {
      type: Object,
      required: true,
    },
  },
  // data() {
  //   return {
  //     changes: {}
  //   };
  // },
  computed: {
    changes() {
      return this.compareObjects(this.objectOld, this.objectNew);
    },
  },
  // mounted() {
  //   this.changes = this.compareObjects(this.objectOld, this.objectNew);
  // },
  methods: {
    compareObjects(oldObj, newObj) {
      return diff(oldObj, newObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-content {
  @apply border-2 px-4 py-2 bg-white;
}
.comparer {
  display: flex;
  justify-content: space-between;
}
.object-column {
  width: 45%;
}
</style>
