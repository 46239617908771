/* global axios */
import ApiClient from './ApiClient';

class NotificationsAPI extends ApiClient {
  constructor() {
    super('notifications', { accountScoped: true });
  }

  get(page) {
    return axios.get(`${this.url}?page=${page}`);
  }

  getNotifications(contactId) {
    return axios.get(`${this.url}/${contactId}/notifications`);
  }

  getUnreadCount() {
    return axios.get(`${this.url}/unread_count`);
  }

  getUnreadMentions() {
    return axios.get(`${this.url}/unread_mentions`);
  }

  getUnreadMentionsCount() {
    return axios.get(`${this.url}/unread_mentions_count`);
  }

  read(primaryActorType, primaryActorId) {
    return axios.post(`${this.url}/read_all`, {
      primary_actor_type: primaryActorType,
      primary_actor_id: primaryActorId,
    });
  }

  readNotification(notification) {
    return axios.post(`${this.url}/read_notification`, {
      notification_id: notification,
    });
  }

  readMention(primaryActorType, primaryActorId) {
    return axios.post(`${this.url}/read_mention`, {
      primary_actor_type: primaryActorType,
      primary_actor_id: primaryActorId,
    });
  }

  readAll() {
    return axios.post(`${this.url}/read_all`);
  }
}

export default new NotificationsAPI();
