<template>
  <div class="w-[25%] bg-white dark:bg-slate-900 contact--panel">
    <campaign-msg-info
      :show-close-button="showCloseButton"
      :campaign-msg="campaignMsg"
      close-icon-name="dismiss"
      @panel-close="onClose"
      @toggle-panel="onClose"
    />
  </div>
</template>

<script>
import CampaignMsgInfo from './CampaignMsgInfo.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  components: {
    CampaignMsgInfo,
  },
  mixins: [uiSettingsMixin],
  props: {
    campaignMsg: {
      type: Object,
      default: () => ({}),
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dragEnabled: true,
      contactSidebarItems: [],
      dragging: false,
    };
  },
  computed: {
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
  },
  mounted() {
    this.contactSidebarItems = this.contactSidebarItemsOrder;
  },
  methods: {
    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        contact_sidebar_items_order: this.contactSidebarItems,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .contact--profile {
    @apply pb-3 mb-4;
  }
}
.contact--panel {
  @apply border-r border-slate-50 dark:border-slate-800/50 h-full text-sm overflow-y-auto relative;
}

.list-group {
  .list-group-item {
    @apply bg-white dark:bg-slate-900;
  }
}

.conversation--details {
  @apply py-0 px-4;
}
</style>
