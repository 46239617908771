import { frontendURL } from '../../../../helper/URLHelper';
import HistoricView from './index.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/historic/:contactId'),
      name: 'historic_home',
      roles: ['administrator', 'agent'],
      component: HistoricView,
      props: route => ({
        contactId: route.params.contactId,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/historic/:contactId/all'),
      name: 'historic_home_all',
      roles: ['administrator', 'agent'],
      component: HistoricView,
      props: route => ({
        contactId: route.params.contactId,
      }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/historic/:contactId/status/:status'
      ),
      name: 'status_schedule_historic',
      roles: ['administrator', 'agent'],
      component: HistoricView,
      props: route => ({
        contactId: route.params.contactId,
        status: route.params.status,
      }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/historic/:contactId/visibility/:visibility'
      ),
      name: 'visibility_historic',
      roles: ['administrator', 'agent'],
      component: HistoricView,
      props: route => ({
        contactId: route.params.contactId,
        visibility: route.params.visibility,
      }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/historic/:contactId/status/:status/visibility/:visibility'
      ),
      name: 'status_schedule_visibility_historic',
      roles: ['administrator', 'agent'],
      component: HistoricView,
      props: route => ({
        contactId: route.params.contactId,
        status: route.params.status,
        visibility: route.params.visibility,
      }),
    },
  ],
};
