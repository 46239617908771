<template>
  <div class="h-auto overflow-auto flex flex-col w-full">
    <woot-modal-header :header-title="title" />
    <div class="w-full">
      <form
        class="flex flex-col w-full pt-1"
        style="padding-bottom: 1rem; padding-top: 0.5rem"
        @submit.prevent="sendSchedule"
      >
        <div class="div-form">
          <div
            class="mt-1 multiselect-wrap--medium w-full"
            :class="{ error: $v.professional.$error }"
          >
            <label class="multiselect__label">
              {{ $t('SCHEDULE.ADD.FORM.PROFESSIONAL.LABEL') }}
            </label>
            <multiselect
              v-model="professional"
              track-by="id"
              label="name"
              class="select-min"
              :placeholder="$t('SCHEDULE.ADD.FORM.PROFESSIONAL.PLACEHOLDER')"
              selected-label
              :select-label="
                $t('SCHEDULE.ADD.FORM.PROFESSIONAL.SELECT_PLACEHOLDER')
              "
              :deselect-label="$t('SCHEDULE.ADD.FORM.PROFESSIONAL.REMOVE')"
              :max-height="160"
              :options="professionalList"
              :allow-empty="true"
              :class="{ error: $v.professional.$error }"
            >
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
          </div>
        </div>
        <div class="div-form">
          <woot-input
            v-model.trim="startDate"
            class="w-full"
            type="date"
            :class="{ error: $v.startDate.$error }"
            :label="$t('SCHEDULE.ADD.FORM.START_DATE.LABEL')"
            :placeholder="$t('SCHEDULE.ADD.FORM.START_DATE.PLACEHOLDER')"
            :error="
              $v.startDate.$error
                ? $t('SCHEDULE.ADD.FORM.START_DATE.ERROR')
                : ''
            "
          />
          <woot-input
            v-model.trim="endDate"
            class="w-full"
            type="date"
            :class="{ error: $v.endDate.$error }"
            :label="$t('SCHEDULE.ADD.FORM.END_DATE.LABEL')"
            :placeholder="$t('SCHEDULE.ADD.FORM.END_DATE.PLACEHOLDER')"
            :error="
              $v.endDate.$error ? $t('SCHEDULE.ADD.FORM.END_DATE.ERROR') : ''
            "
          />
        </div>
        <div class="div-form">
          <ui-form-time
            :label="$t('SCHEDULE.ADD.FORM.START_TIME.LABEL')"
            :placeholder="$t('SCHEDULE.ADD.FORM.START_TIME.PLACEHOLDER')"
            :value="startTime"
            :error-message="$t('SCHEDULE.ADD.FORM.START_TIME.ERROR')"
            :error-form="$v.startTime.$error"
            @change="onChangeTimeInit"
          />
          <ui-form-time
            :label="$t('SCHEDULE.ADD.FORM.END_TIME.LABEL')"
            :placeholder="$t('SCHEDULE.ADD.FORM.END_TIME.PLACEHOLDER')"
            :value="endTime"
            :error-message="$t('SCHEDULE.ADD.FORM.END_TIME.ERROR')"
            :error-form="$v.endTime.$error"
            @change="onChangeTimeEnd"
          />
        </div>
        <div>
          <woot-input
            v-model.trim="computedDuration"
            class="w-full"
            type="number"
            :class="{ error: $v.duration.$error || showError }"
            :label="$t('SCHEDULE.ADD.FORM.DURATION.LABEL')"
            :placeholder="$t('SCHEDULE.ADD.FORM.DURATION.PLACEHOLDER')"
            :error="
              $v.duration.$error || showError
                ? $t('SCHEDULE.ADD.FORM.DURATION.ERROR')
                : ''
            "
          />
        </div>
        <div class="div-form w-full">
          <div class="mt-1 w-full">
            <label
              class="attribute-name"
              :class="{ error: $v.daysSelect.$error }"
            >
              {{ $t('SCHEDULE.ADD.FORM.DAYS.LABEL') }}
            </label>
            <multiselect
              id="select-days-open-schedule"
              v-model="daysSelect"
              track-by="id"
              label="name"
              :placeholder="$t('SCHEDULE.ADD.FORM.DAYS.PLACEHOLDER')"
              selected-label
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="true"
              :max-height="320"
              :select-label="$t('SCHEDULE.ADD.FORM.DAYS.SELECT_PLACEHOLDER')"
              :deselect-label="$t('SCHEDULE.ADD.FORM.DAYS.REMOVE')"
              :options="daysList"
              :allow-empty="true"
            >
              <template slot="noResult">
                {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
              </template>
              <template slot="noOptions">
                {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
              </template>
            </multiselect>
            <span v-if="$v.daysSelect.$error" class="editor-warning__message">
              {{ $t('SCHEDULE.ADD.FORM.DAYS.ERROR') }}
            </span>
          </div>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.markingType.$error }">
            {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.LABEL') }}
            <select v-model="markingType" class="text-sm">
              <option
                v-for="typeItem in markingTypes"
                :key="typeItem.id"
                :value="typeItem.id"
                class="text-sm"
              >
                {{ typeItem.name }}
              </option>
            </select>
            <span v-if="$v.markingType.$error" class="message">
              {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="div-form justify-end py-2 px-0 w-full">
          <woot-button variant="clear" @click.prevent="onClose">
            {{ $t('SCHEDULE.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button :disabled="showError" :is-loading="isLoading">
            {{ textBtnSend }}
          </woot-button>
          <!-- <woot-button :is-loading="uiFlags.isCreating && isLoading">
            {{ textBtnSend }}
          </woot-button> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
// import WootTimePicker from 'dashboard/components/ui/TimePicker.vue';
import alertMixin from 'shared/mixins/alertMixin';
import UiFormTime from 'dashboard/components/widgets/forms/UIFormTime.vue';
// import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';

export default {
  components: {
    // WootTimePicker,
    UiFormTime,
    // WootDateTimePicker,
  },
  mixins: [alertMixin],
  props: {
    professionalSelected: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      professional: this.professionalSelected,
      startDate: '',
      endDate: '',
      duration: '',
      showError: false, // Indica se há um erro no campo
      startTime: null,
      endTime: null,
      isLoading: false,
      daysSelect: [
        { id: 1, name: this.$t('DAYS_OF_WEEK.MONDAY') },
        { id: 2, name: this.$t('DAYS_OF_WEEK.TUESDAY') },
        { id: 3, name: this.$t('DAYS_OF_WEEK.WEDNESDAY') },
        { id: 4, name: this.$t('DAYS_OF_WEEK.THURSDAY') },
        { id: 5, name: this.$t('DAYS_OF_WEEK.FRIDAY') },
      ],
      daysList: [
        { id: 0, name: this.$t('DAYS_OF_WEEK.SUNDAY') },
        { id: 1, name: this.$t('DAYS_OF_WEEK.MONDAY') },
        { id: 2, name: this.$t('DAYS_OF_WEEK.TUESDAY') },
        { id: 3, name: this.$t('DAYS_OF_WEEK.WEDNESDAY') },
        { id: 4, name: this.$t('DAYS_OF_WEEK.THURSDAY') },
        { id: 5, name: this.$t('DAYS_OF_WEEK.FRIDAY') },
        { id: 6, name: this.$t('DAYS_OF_WEEK.SATURDAY') },
      ],
      markingType: 'PRESENTIAL',
      markingTypes: [
        { id: 'PRESENTIAL', name: this.$t('SCHEDULE.MARKING_TYPE.PRESENTIAL') },
        { id: 'VIRTUAL', name: this.$t('SCHEDULE.MARKING_TYPE.VIRTUAL') },
        { id: 'BOTH', name: this.$t('SCHEDULE.MARKING_TYPE.BOTH') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'schedules/getUIFlags',
      professionalList: 'professionals/getAllProfessionals',
    }),
    computedDuration: {
      get() {
        return this.duration;
      },
      set(value) {
        if (value < 1) {
          this.showError = true;
        } else {
          this.showError = false;
        }
        this.duration = value;
      },
    },
    textBtnSend() {
      return this.$t('SCHEDULE.ADD.GENERATE_BUTTON_TEXT');
    },
    isEdit() {
      return !!(this.event && this.event.id);
    },
    title() {
      if (this.isEdit) return this.$t('CALENDAR.EDIT.TITLE');
      return this.$t('SCHEDULE.ADD.TITLE');
    },
    selectedMarkingType() {
      return this.markingTypes.find(
        item => item.name.toUpperCase() === this.event.markingType
      ).id;
    },
  },
  validations() {
    return {
      professional: {
        required,
      },
      startDate: {
        required,
      },
      endDate: {
        required,
      },
      duration: {
        required,
      },
      startTime: {
        required,
      },
      endTime: {
        required,
      },
      daysSelect: {
        required,
      },
      markingType: {},
    };
  },
  mounted() {
    // this.$store.dispatch('professionals/get');
    this.setDataEvent();
  },
  methods: {
    setDataEvent() {
      if (this.isEdit) {
        const {
          // professional_id,
          start_date,
          end_date,
          start_time,
          end_time,
          duration,
          days_of_week,
        } = this.event.params;

        this.professional = this.event.professional;
        this.startDate = start_date;
        this.endDate = end_date;
        this.duration = duration;
        this.startTime = new Date(`${start_date}T${start_time}`);
        this.endTime = new Date(`${end_date}T${end_time}`);
        this.daysSelect = this.daysList.filter(day =>
          days_of_week.includes(day.id)
        );
        this.markingType = this.selectedMarkingType;
      }
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    onChangeTimeInit(value) {
      // console.log('olaaa onChangeTimeInit', value);
      this.startTime = value;
    },
    onChangeTimeEnd(value) {
      // console.log('olaaa onChangeTimeEnd', value);
      this.endTime = value;
    },
    onClose(professional) {
      this.$emit('on-close', professional);
    },
    async sendSchedule() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let startTime = this.startTime.toLocaleTimeString();
      let endTime = this.endTime.toLocaleTimeString();

      let dataSend = {
        professional_id: this.professional.id,
        start_date: this.startDate,
        end_date: this.endDate,
        start_time: this.startTime.toLocaleTimeString(),
        end_time: this.endTime.toLocaleTimeString(),
        schedule_date_time: new Date(
          `${this.startDate}T${startTime}`
        ).toISOString(),
        schedule_date_time_end: new Date(
          `${this.endDate}T${endTime}`
        ).toISOString(),
        duration: this.duration,
        days_of_week: this.daysSelect.map(item => item.id),
        marking_type: this.markingType,
        status: 'AVAILABLE',
        observation: null,
        contac_id: null,
      };
      try {
        this.isLoading = true;
        await this.$store.dispatch('schedules/generate', dataSend);
        this.isLoading = false;
        this.showAlert(this.$t('SCHEDULE.ADD.API.SUCCESS_MESSAGE'));
        if (this.professional.id !== this.professionalSelected.id) {
          setTimeout(() => {
            this.showAlert(
              this.$t('SCHEDULE.ADD.API.CHANGE_PROFESSIONAL_MESSAGE')
            );
            this.onClose(this.professional);
          }, 400);
        } else {
          this.onClose();
        }
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style>
#listbox-select-days-open-schedule li .multiselect__option {
  line-height: 19px;
  padding: 6px 8px;
}
</style>
