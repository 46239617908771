<template>
  <div>
    <form
      class="flex flex-col w-full pt-1"
      style="padding-bottom: 1rem; padding-top: 0.5rem"
    >
      <div class="div-form">
        <div
          class="mt-1 multiselect-wrap--medium w-30"
          :class="{ error: $v.selectType.$error }"
        >
          <label class="multiselect__label">
            {{ $t('HISTORIC.FORM.TYPE.LABEL') }}
          </label>
          <multiselect
            v-model="selectType"
            track-by="id"
            label="title"
            class="select-min"
            :placeholder="$t('HISTORIC.FORM.TYPE.PLACEHOLDER')"
            selected-label
            :select-label="$t('HISTORIC.FORM.TYPE.SELECT_PLACEHOLDER')"
            :deselect-label="$t('HISTORIC.FORM.TYPE.REMOVE')"
            :max-height="160"
            :options="listTypes"
            :allow-empty="true"
            :class="{ error: $v.selectType.$error }"
          >
            <template slot="noResult">
              {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
            </template>
            <template slot="noOptions">
              {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
            </template>
            <template slot="beforeList">
              <li role="option" class="multiselect__element">
                <span
                  id="load-more-historic-types"
                  class="multiselect__option multiselect__option--highlight add-new-contact-btn"
                  @click="handleAddHistoricType"
                >
                  {{ $t('HISTORIC.FORM.BUTTON_ADD_TYPE') }}
                </span>
              </li>
            </template>
          </multiselect>
        </div>
        <div class="dropdown-wrap position-form">
          <div
            :class="{ 'dropdown-pane--open': showFormHistoricTypes }"
            class="dropdown-pane"
            style="width: 300px"
          >
            <div class="mb-2">
              <div class="font-medium text-base pt-2">
                {{ $t('TYPES.HISTORIC.NEW') }}
              </div>
              <woot-button
                color-scheme="secondary"
                icon="dismiss"
                variant="clear"
                class="absolute ltr:right-2 rtl:left-2 top-2 z-10"
                @click="closeFormHistoricType"
              />
            </div>
            <form-historic-types @close="closeFormHistoricType" />
          </div>
        </div>
        <div
          class="mt-1 multiselect-wrap--medium w-30"
          :class="{ error: $v.selectVisibility.$error }"
        >
          <label class="multiselect__label">
            {{ $t('HISTORIC.FORM.VISIBILITY.LABEL') }}
          </label>
          <multiselect
            v-model="selectVisibility"
            track-by="value"
            label="name"
            class="select-min"
            :placeholder="$t('HISTORIC.FORM.VISIBILITY.PLACEHOLDER')"
            selected-label
            :select-label="$t('HISTORIC.FORM.VISIBILITY.SELECT_PLACEHOLDER')"
            :deselect-label="$t('HISTORIC.FORM.VISIBILITY.REMOVE')"
            :max-height="160"
            :options="listVisibility"
            :allow-empty="true"
            :class="{ error: $v.selectVisibility.$error }"
          >
            <template slot="noResult">
              {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
            </template>
            <template slot="noOptions">
              {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
            </template>
          </multiselect>
        </div>
        <div
          class="mt-1 multiselect-wrap--medium w-2/5"
          :class="{ error: $v.selectSchedule.$error }"
        >
          <label class="multiselect__label">
            {{ $t('HISTORIC.FORM.SCHEDULE.LABEL') }}
          </label>
          <multiselect
            v-model="selectSchedule"
            track-by="id"
            label="status"
            class="select-min"
            :placeholder="$t('HISTORIC.FORM.SCHEDULE.PLACEHOLDER')"
            selected-label
            :select-label="$t('HISTORIC.FORM.SCHEDULE.SELECT_PLACEHOLDER')"
            :deselect-label="$t('HISTORIC.FORM.SCHEDULE.REMOVE')"
            :max-height="160"
            :options="listSchedules"
            :custom-label="formatScheduleLabel"
            :allow-empty="true"
            :preserve-search="true"
            :class="{ error: $v.selectSchedule.$error }"
          >
            <template slot="option" slot-scope="{ option }">
              <div>
                {{ formatScheduleLabel(option) }}
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <!-- {{
              option && option.name !== undefined
                ? formatScheduleLabel(option)
                : ''
            }} -->
              {{ formatScheduleLabel(option) }}
            </template>
            <template slot="noResult">
              {{ $t('FORMS.MULTISELECT.NO_RESULT') }}
            </template>
            <template slot="noOptions">
              {{ $t('FORMS.MULTISELECT.NO_OPTIONS') }}
            </template>
          </multiselect>
        </div>
        <div v-if="showSendFile" class="selectFile">
          <file-upload
            ref="upload"
            v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            input-id="conversationAttachment"
            :size="4096 * 4096"
            :accept="documentAccept"
            :multiple="true"
            :drop="true"
            :drop-directory="false"
            :data="{
              direct_upload_url: '/rails/active_storage/direct_uploads',
              direct_upload: true,
            }"
            @input-file="onFileUpload"
          >
            <woot-button
              class-names="button--upload mt-3"
              :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
              icon="attach"
              emoji="📎"
              color-scheme="primary"
              variant="smooth"
              size="small"
            />
          </file-upload>
        </div>
      </div>
      <div
        v-if="files && files.length > 0"
        class="div-form"
        style="flex-wrap: wrap"
      >
        <div class="text-sm text-base">
          {{ $t('HISTORIC.FORM.ATTACHMENTS') }}
        </div>
        <woot-label
          v-for="file in files"
          :key="file.id"
          :title="file.name"
          :show-close="true"
          variant="smooth"
          class="width-label-att"
          @click="removeItemFile"
        />
      </div>
      <!-- <div v-if="showFormHistoricTypes">
        <form-historic-types />
      </div> -->

      <editor-text @add="onAddDescription">
        <template slot="button">
          <woot-button
            color-scheme="primary"
            :is-disabled="isDisabled"
            @click="sendData"
          >
            {{ $t('HISTORIC.FORM.SUBMIT') }}
          </woot-button>
        </template>
      </editor-text>
    </form>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import EditorText from 'dashboard/components/widgets/forms/EditorText.vue';
import dateMixin from 'dashboard/mixins/dateMixin';
import FormHistoricTypes from './FormHistoricTypes.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    FileUpload,
    EditorText,
    FormHistoricTypes,
  },
  mixins: [dateMixin, alertMixin],
  props: {
    contactId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      description: '',
      selectVisibility: {
        name: this.$t('HISTORIC.FORM.VISIBILITY.OPTIONS.ONLY_USER'),
        value: 'ONLY_USER',
      },
      listVisibility: [
        {
          name: this.$t('HISTORIC.FORM.VISIBILITY.OPTIONS.ONLY_USER'),
          value: 'ONLY_USER',
        },
        {
          name: this.$t('HISTORIC.FORM.VISIBILITY.OPTIONS.ADMIN'),
          value: 'ADMIN',
        },
        {
          name: this.$t('HISTORIC.FORM.VISIBILITY.OPTIONS.ALL_USERS'),
          value: 'ALL_USERS',
        },
        {
          name: this.$t(
            'HISTORIC.FORM.VISIBILITY.OPTIONS.COMPANY_PROFESSIONALS'
          ),
          value: 'COMPANY_PROFESSIONALS',
        },
      ],
      selectSchedule: null,
      selectType: null,
      showFormHistoricTypes: false,
      showSendFile: true,
      files: null,
    };
  },
  validations() {
    return {
      selectVisibility: {
        required,
      },
      selectSchedule: {},
      selectType: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'histories/getUIFlags',
      listSchedules: 'schedules/getAllSchedules',
      accountId: 'getCurrentAccountId',
      currentUserId: 'getCurrentUserID',
      listTypes: 'historicTypes/get',
    }),
    documentAccept() {
      return ALLOWED_FILE_TYPES;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    isDisabled() {
      if (this.description.length < 3) return true;
      if (!this.selectVisibility) return true;
      if (this.uiFlags.isCreating) return true;
      // if (!this.selectSchedule) return true;
      return false;
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSchedule();
    }, 1000);
    this.$store.dispatch('historicTypes/get');
  },
  methods: {
    removeItemFile(file) {
      const index = this.files.findIndex(item => item.id === file.id);
      this.files.splice(index, 1);
    },
    closeFormHistoricType(e) {
      if (e) e.preventDefault();
      this.showFormHistoricTypes = false;
    },
    handleAddHistoricType(e) {
      e.preventDefault();
      this.showFormHistoricTypes = !this.showFormHistoricTypes;
    },
    formatScheduleLabel(schedule) {
      const day = this.getDateDay(
        schedule.schedule_date_time,
        'EEE, dd/MM/yyyy'
      );
      const intervalTime = this.getIntervalTime(
        schedule.schedule_date_time,
        schedule.schedule_date_time_end
      );
      const status = this.$t(`SCHEDULE.STATUS.${schedule.status}`);
      return `${day} - ${intervalTime} - ${status}`;
    },
    onAddDescription(value) {
      // this.$emit('add', value);
      // console.log('onAddNote value ', value);
      this.description = value;
    },
    onFileUpload(file) {
      if (this.files && this.files.length > 0) {
        this.files.push(file);
      } else {
        this.files = [file];
      }
    },
    async sendData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const dataSend = {
        account_id: this.accountId,
        user_id: this.currentUserId,
        // historic_type_id: this.selectType.id,
        ...(this.selectType &&
          this.selectType.id && { historic_type_id: this.selectType.id }),
        registry_date: new Date().toISOString(), // '2019-08-24T14:15:22Z',
        visibility: this.selectVisibility.value,
        content: this.description,
        contact_id: this.contactId,
        // schedule_id: this.selectSchedule.id,
        ...(this.selectSchedule &&
          this.selectSchedule.id && { schedule_id: this.selectSchedule.id }),
      };
      try {
        await this.$store.dispatch('histories/create', {
          data: dataSend,
          files: this.files,
        });
        this.showAlert(this.$t('HISTORIC.API.SUCCESS_MESSAGE'));
        // if (this.files && this.files.length > 0 )
        this.$emit('close');
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('HISTORIC.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    getSchedule() {
      if (this.contact && this.contact.id) {
        this.$store.dispatch('schedules/getFilter', {
          contact_id: this.contact.id,
        });
      }
    },
  },
};
</script>
<style>
.selectFile {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.w-30 {
  width: 30%;
}
.position-form {
  left: -320px;
  position: relative;
  top: 70px;
}
.width-label-att > :nth-child(2) {
  line-height: 1.5;
  max-width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
