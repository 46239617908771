<template>
  <div class="h-auto overflow-auto flex flex-col w-full">
    <woot-modal-header :header-title="$t('SCHEDULE.NEW.TITLE')" />
    <div class="w-full">
      <form
        class="flex flex-col w-full pt-1"
        style="padding-bottom: 1rem; padding-top: 0.5rem"
        @submit.prevent="sendSchedule"
      >
        <!-- <div class="div-form gap-4">
          <text-label-form
            :title="$t('CALENDAR.INFORMATION.DEFINITION.DAY')"
            :value="parseData(date)"
          />
          <text-label-form
            :title="$t('CALENDAR.SCHEDULE.SCHEDULE')"
            :value="
              getIntervalTime(
                event.schedule_date_time,
                event.schedule_date_time_end
              )
            "
          />
        </div> -->
        <!-- <div class="flex gap-4">
          <info-row :value="parseData(date)" icon="calendar-clock" emoji="📅" />
          <div class="w-[50%]">
            <h5>
              {{ $t('CALENDAR.INFORMATION.PROFESSIONAL.TITLE') }}
            </h5>
            <contact-info-row
              :value="professional.name"
              icon="person"
              emoji="🪪"
              :title="$t('CALENDAR.INFORMATION.PROFESSIONAL.NAME')"
              show-copy
            />
          </div>
        </div> -->
        <div class="flex">
          <div class="w-[50%]">
            <label class="font-medium text-black-800 dark:text-slate-50">
              {{ $t('CALENDAR.INFORMATION.DEFINITION.DAY') }}
            </label>
            <contact-info-row
              :value="parseData(date)"
              icon="calendar-clock"
              emoji="📅"
              :title="$t('CALENDAR.INFORMATION.DEFINITION.DAY')"
              emphasis
              show-copy
            />
          </div>
          <div class="w-[50%]">
            <!-- <h5>
              {{ $t('CALENDAR.INFORMATION.PROFESSIONAL.TITLE') }}
            </h5> -->
            <label class="font-medium text-black-800 dark:text-slate-50">
              {{ $t('CALENDAR.INFORMATION.PROFESSIONAL.TITLE') }}
            </label>
            <contact-info-row
              :value="professional.name"
              icon="person"
              emoji="🪪"
              :title="$t('CALENDAR.INFORMATION.PROFESSIONAL.NAME')"
              emphasis
              show-copy
            />
          </div>
        </div>
        <div class="div-form">
          <label class="w-full">
            {{ $t('SCHEDULE.ADD.FORM.START_TIME.LABEL') }}
            <WootTimePicker
              :value="startTime"
              :confirm-text="$t('SCHEDULE.ADD.FORM.START_TIME.CONFIRM')"
              :placeholder="$t('SCHEDULE.ADD.FORM.START_TIME.PLACEHOLDER')"
              @change="onChangeTimeInit"
            />
            <span v-if="$v.startTime.$error" class="editor-warning__message">
              {{ $t('SCHEDULE.ADD.FORM.START_TIME.ERROR') }}
            </span>
          </label>
          <label class="w-full">
            {{ $t('SCHEDULE.ADD.FORM.END_TIME.LABEL') }}
            <WootTimePicker
              :value="endTime"
              :confirm-text="$t('SCHEDULE.ADD.FORM.END_TIME.CONFIRM')"
              :placeholder="$t('SCHEDULE.ADD.FORM.END_TIME.PLACEHOLDER')"
              @change="onChangeTimeEnd"
            />
            <span v-if="$v.endTime.$error" class="editor-warning__message">
              {{ $t('SCHEDULE.ADD.FORM.END_TIME.ERROR') }}
            </span>
          </label>
        </div>
        <div class="w-full">
          <label :class="{ error: $v.markingType.$error }">
            {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.LABEL') }}
            <select v-model="markingType" class="text-sm">
              <option
                v-for="typeItem in markingTypes"
                :key="typeItem.id"
                :value="typeItem.id"
              >
                {{ typeItem.name }}
              </option>
            </select>
            <span v-if="$v.markingType.$error" class="message">
              {{ $t('SCHEDULE.ADD.FORM.MARKING_TYPE.ERROR') }}
            </span>
          </label>
        </div>
        <div class="div-form justify-end py-2 px-0 w-full">
          <woot-button variant="clear" @click.prevent="onClose">
            {{ $t('SCHEDULE.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button :is-loading="uiFlags.isCreating">
            {{ $t('SCHEDULE.NEW.CREATE_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactInfoRow from 'dashboard/routes/dashboard/conversation/contact/ContactInfoRow.vue';
import dateMixin from 'dashboard/mixins/dateMixin';
import WootTimePicker from 'dashboard/components/ui/TimePicker.vue';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    ContactInfoRow,
    WootTimePicker,
  },
  mixins: [alertMixin, dateMixin],
  props: {
    date: {
      type: Date,
      default: () => new Date(),
    },
    dataString: {
      type: String,
      default: '',
    },
    professional: {
      type: Object,
      default: () => ({}),
    },
    // dataEvent: {
    //   type: Object,
    //   default: () => ({}),
    // },
  },
  data() {
    return {
      startTime: null,
      endTime: null,
      markingType: 'PRESENTIAL',
      markingTypes: [
        { id: 'PRESENTIAL', name: this.$t('SCHEDULE.MARKING_TYPE.PRESENTIAL') },
        { id: 'VIRTUAL', name: this.$t('SCHEDULE.MARKING_TYPE.VIRTUAL') },
        { id: 'BOTH', name: this.$t('SCHEDULE.MARKING_TYPE.BOTH') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      uiFlags: 'schedules/getUIFlags',
    }),
    title() {
      return this.professional.name;
    },
    textBtnSend() {
      return this.$t('SCHEDULE.ADD.GENERATE_BUTTON_TEXT');
    },
    selectedMarkingType() {
      return this.markingTypes.find(
        item => item.name.toUpperCase() === this.event.markingType
      ).id;
    },
  },
  validations() {
    return {
      // professional: {
      //   required,
      // },
      startTime: {
        required,
      },
      endTime: {
        required,
      },
      markingType: {},
    };
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    async sendSchedule() {
      let partesData = this.dataString.split('-');
      let date_year = parseInt(partesData[0], 10);
      let date_week = parseInt(partesData[1], 10) - 1;
      let date_day = parseInt(partesData[2], 10);

      let selectDay = new Date(date_year, date_week, date_day);

      let startHour = this.startTime.getHours();
      let startMinute = this.startTime.getMinutes();
      let endHour = this.endTime.getHours();
      let endMinute = this.endTime.getMinutes();

      let scheduleDateTime = new Date(
        selectDay.getFullYear(),
        selectDay.getMonth(),
        selectDay.getDate(),
        startHour,
        startMinute
      );
      let scheduleDateTimeEnd = new Date(
        selectDay.getFullYear(),
        selectDay.getMonth(),
        selectDay.getDate(),
        endHour,
        endMinute
      );

      let sendData = {
        professional_id: this.professional.id,
        user_id: this.currentUser.id,
        user: this.currentUser,
        schedule_date_time: scheduleDateTime,
        schedule_date_time_end: scheduleDateTimeEnd,
        status: 'AVAILABLE',
        marking_type: this.markingType,
        observation: null,
        contact_id: null,
      };
      try {
        await this.$store.dispatch('schedules/create', sendData);
        this.showAlert(this.$t('SCHEDULE.NEW.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('SCHEDULE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    parseData(date) {
      if (!date) return '';
      return this.getDateDay(date, "EEE' 'dd'/'MM'/'yyyy");
    },
    onChangeTimeInit(value) {
      this.startTime = value;
    },
    onChangeTimeEnd(value) {
      this.endTime = value;
    },
  },
};
</script>
