export const EVO_WOOT_CONFIG = {
  enabled: false,
  url: '',
  account_id: '',
  token: '',
  sign_msg: false,
  name_inbox: '',
  webhook_url: '',
  reopen_conversation: false,
  conversation_pending: false,
  sign_delimiter: null,
  import_contacts: false,
  import_messages: false,
  merge_brazil_contacts: false,
  days_limit_import_messages: 0,
  auto_create: false,
};
