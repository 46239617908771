<template>
  <section
    class="campaign-table-wrap bg-white dark:bg-slate-900 flex-1 h-full overflow-hidden"
  >
    <ve-table
      :fixed-header="true"
      max-height="calc(100vh - 7.125rem)"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />
  </section>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { VeTable } from 'vue-easytable';
// import timeMixin from 'dashboard/mixins/time';
import formats from 'dashboard/mixins/formats';
import rtlMixin from 'shared/mixins/rtlMixin';
export default {
  components: {
    VeTable,
  },
  mixins: [clickaway, formats, rtlMixin],
  props: {
    dataContacts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sortConfig: {},
      sortOption: {
        sortAlways: true,
        sortChange: params => this.$emit('on-sort-change', params),
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          field: 'whatsapp',
          key: 'whatsapp',
          title: this.$t('CAMPAIGN_MSG_DETAILS.TABLE_HEADER.WHATSAPP'),
          align: 'center',
        },
        {
          field: 'statusSend',
          key: 'statusSend',
          title: this.$t('CAMPAIGN_MSG_DETAILS.TABLE_HEADER.STATUS_SEND'),
          align: 'statusSend',
          renderBodyCell: ({ row }) => {
            if (row.statusSend)
              return (
                <div id="span-status">
                  <span {...this.colorStatus(row.statusSend)}>
                    {this.$t('CAMPAIGN_MSG_DETAILS.STATUS.' + row.statusSend)}
                  </span>
                </div>
              );
            return '---';
          },
        },
        {
          field: 'observation',
          key: 'observation',
          title: this.$t('CAMPAIGN_MSG_DETAILS.TABLE_HEADER.OBSERVATION'),
          align: 'left',
        },
        {
          field: 'updatedAt',
          key: 'updatedAt',
          title: this.$t('CAMPAIGN_MSG_DETAILS.TABLE_HEADER.UPDATE_DATE'),
        },
      ];
    },
    tableData() {
      let data = this.dataContacts;
      return data.map(item => {
        const {
          whatsapp,
          status_send: statusSend,
          updated_at: updatedAt,
          observation,
        } = item;
        return {
          ...item,
          whatsapp,
          observation: observation || '---',
          statusSend,
          // updatedAt: this.dateFormat(updatedAt),
          updatedAt: this.getDate(updatedAt),
        };
      });
    },
  },
  watch: {
    sortOrder() {
      this.setSortConfig();
    },
    sortParam() {
      this.setSortConfig();
    },
  },
  mounted() {
    this.setSortConfig();
  },
  methods: {
    colorStatus(status) {
      const className = 'span-status p-1 text-slate-800 ';
      const statusNames = {
        OPEN_CHAT: 'bg-yellow-600',
        WAITING: 'bg-yellow-600',
        MESSAGE_LIMIT_REACHED: 'bg-red-300',
        WITHOUT_WHATSAPP: 'bg-yellow-600',
        ERROR: 'bg-red-300',
        COMPLETED: 'bg-woot-100',
      };
      return { class: className + statusNames[status] };
    },
    getDate(date) {
      const dateTemp = new Date(date);
      // const optionsDate = {
      //   year: 'numeric',
      //   month: 'short',
      //   day: 'numeric',
      // };
      return dateTemp.toLocaleDateString() + ' ' + this.getTime(date);
    },
    getTime(date) {
      // console.log('date getime ', date);
      const dateTemp = new Date(date);
      const optionsTime = {
        hour: 'numeric',
        minute: 'numeric',
      };
      // console.log(
      //   'date getime format',
      //   dateTemp.toLocaleTimeString(undefined, optionsTime)
      // );
      return dateTemp.toLocaleTimeString(undefined, optionsTime);
    },
    setSortConfig() {
      this.sortConfig = { [this.sortParam]: this.sortOrder };
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-table-wrap::v-deep {
  .ve-table {
    @apply pb-8;
  }
  .row--user-block {
    @apply items-center flex text-left;

    .user-block {
      @apply items-start flex flex-col my-0 mx-2;
    }

    .user-name {
      @apply text-sm font-medium m-0 capitalize;
    }

    .view-details--button {
      @apply text-slate-600 dark:text-slate-200;
    }

    .user-email {
      @apply m-0;
    }
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    @apply -top-1;
  }
  #span-status > .span-status {
    padding: 0.25rem;
    border-radius: 4px;
  }
}

.cell--social-profiles {
  a {
    @apply text-slate-300 dark:text-slate-400 text-lg min-w-[2rem] text-center;
  }
}
</style>
