<template>
  <div
    class="image-zoom-container"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
    @wheel="handleWheel"
  >
    <img
      ref="image"
      :src="src"
      :alt="alt"
      :style="imageStyle"
      :class="'zoom-image max-h-[75vh] ' + classes"
      @click="clickZoom"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageZoom',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isZoomed: false,
      isClickZoom: false,
      zoomFactor: 1,
      maxZoom: 5,
      minZoom: 1,
      imageStyle: {
        transform: 'scale(1)',
        transformOrigin: 'center center',
        cursor: 'zoom-in',
      },
    };
  },
  methods: {
    handleMouseMove(event) {
      const image = this.$refs.image;
      const { top, left, width, height } = image.getBoundingClientRect();
      const x = event.clientX - left;
      const y = event.clientY - top;

      if (x > 0 && y > 0 && x < width && y < height) {
        this.isZoomed = true;
        const percentX = (x / width) * 100;
        const percentY = (y / height) * 100;

        this.imageStyle = {
          transform: `scale(${this.zoomFactor})`,
          transformOrigin: `${percentX}% ${percentY}%`,
          cursor: this.zoomFactor > 1 ? 'zoom-out' : 'zoom-in',
        };
      } else {
        this.isZoomed = false;
        this.imageStyle = {
          transform: 'scale(1)',
          transformOrigin: 'center center',
          cursor: 'zoom-in',
        };
      }
    },
    handleMouseLeave() {
      this.isZoomed = false;
      this.imageStyle = {
        transform: `scale(${this.zoomFactor})`,
        transformOrigin: 'center center',
        cursor: this.zoomFactor > 1 ? 'zoom-out' : 'zoom-in',
      };
    },
    clickZoom(event) {
      event.preventDefault();
      this.zoomFactor = this.isClickZoom ? 1 : 3;
      this.isClickZoom = !this.isClickZoom;
      this.handleMouseMove(event);
    },
    handleWheel(event) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);
      this.zoomFactor = Math.min(
        this.maxZoom,
        Math.max(this.minZoom, this.zoomFactor - delta * 0.1)
      );

      if (this.isZoomed) {
        this.handleMouseMove(event);
      } else {
        this.imageStyle = {
          transform: `scale(${this.zoomFactor})`,
          transformOrigin: 'center center',
          cursor: this.zoomFactor > 1 ? 'zoom-out' : 'zoom-in',
        };
      }
    },
  },
};
</script>

<style>
.image-zoom-container {
  width: 100vw;
  height: calc(100vh - 130px);
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.zoom-image {
  max-width: 100%;
  height: auto;
  transition: transform 0.2s ease-in-out;
}
</style>
